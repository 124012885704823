import React, { useState, useEffect } from 'react';
import { Alert, Modal, ModalHeader, ModalBody, Label, FormGroup, TabContent, TabPane, Breadcrumb, BreadcrumbItem, UncontrolledTooltip, Table, Spinner } from 'reactstrap';
import Loading from './loading';
import ParseQueries from './../actions/parseQueries';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IoMdClipboard } from 'react-icons/io';
import Notifications from './../actions/notifications';
import Stripe, { calculatePrice } from './../actions/stripe';
import { Link } from 'react-router-dom';
import Error from './error';
import PropTypes from 'prop-types';

export default function DetailsButton({stripeSub, roomSecurities, className, text, onFinish, dCard, startOpen}) {
    const [isOpen, updateModal] = useState(!!startOpen);

    function toggleDetailsModal() {
        updateModal(state => (!state));
    }

    useEffect(() => {
        updateModal(startOpen);
    }, [startOpen, updateModal]);

    function onDone() {
        toggleDetailsModal();
        if(typeof onFinish === 'function') onFinish();
    }

    function chooseComponent(subscription, roomSecurities) {
        if(!!subscription && (!Array.isArray(roomSecurities) || roomSecurities.length < 1)) {
            return <FullDetails stripeSub={stripeSub} roomSecurities={roomSecurities} dCard={dCard} onFinish={onDone} />
        } else if(Array.isArray(roomSecurities) && roomSecurities.length > 0) {
            return <TeamSuiteDetails roomSecurityArr={roomSecurities} />
        } else {
            return <Error color='danger' message='Missing necessary information to render data' />
        }
    }
    let overdue = (!!stripeSub && (stripeSub.status === 'incomplete' || stripeSub.status === 'past_due'));
    let btnText = (overdue)? 'Pay Invoice' : 'Details';
    return <>
        <button className={className || `bttn ${overdue? 'danger' : 'primary'} block`} onClick={toggleDetailsModal}>{text || btnText}</button>
        <Modal isOpen={isOpen} toggle={toggleDetailsModal}>
            <ModalHeader toggle={toggleDetailsModal}>Subscription Details</ModalHeader>
            <ModalBody>
                {chooseComponent(stripeSub, roomSecurities)}
            </ModalBody>
        </Modal>
    </>
}

DetailsButton.propTypes = {
    stripeSub: PropTypes.object,
    roomSecurities: PropTypes.arrayOf(PropTypes.object),
    text: PropTypes.string,
    className: PropTypes.string,
    onDone: PropTypes.func,
    dCard: PropTypes.object,
    startOpen: PropTypes.bool,
}

export function FullDetails({stripeSub, roomSecurities, dCard, onFinish}) {
    let [tab, setTab] = useState('1');
    if(!stripeSub) {
        return <Error message={'Missing Stripe Subscription Information'}/>
    } else if(stripeSub.status === 'incomplete' || stripeSub.status === 'past_due') {
        return  <InvoicePaymentForm stripeSub={stripeSub} dCard={dCard} onFinish={onFinish}/>
    } else if(stripeSub.status === 'active' || stripeSub.status === 'trialing') {
        let plan = stripeSub.price || stripeSub.plan;
        if(plan.product.metadata.type === 'Private Campus') return <StripeSubDetails stripeSub={stripeSub}/>
        return <>
            <Breadcrumb>
                <BreadcrumbItem active={tab !== '0'} tag='a' href='#' onClick={(e) => { e.preventDefault(); setTab('0'); }}>Payment Info</BreadcrumbItem>
                <BreadcrumbItem active={tab !== '1'} tag='a' href='#' onClick={(e) => { e.preventDefault(); setTab('1'); }}>Room Info</BreadcrumbItem>
            </Breadcrumb>
            <TabContent activeTab={tab}>
                <TabPane tabId='0'>
                    <StripeSubDetails stripeSub={stripeSub}/>
                </TabPane>
                <TabPane tabId='1'>
                    <TeamSuiteDetails stripeSub={stripeSub} roomSecurityArr={roomSecurities}/>
                </TabPane>
            </TabContent>
        </>
    } else {
        return <Error message={'Your subscription has a status: ' + stripeSub.status} />
    }
}

FullDetails.propTypes = {
    stripeSub: PropTypes.object.isRequired,
    roomSecurities: PropTypes.arrayOf(PropTypes.object),
    dCard: PropTypes.object,
    onFinish: PropTypes.func,
}

export function InvoicePaymentForm({stripeSub, dCard, onFinish}) {
    const [{isLoading, isPaying, error}, setState] = useState({isLoading: true, isPaying: false, error: ''});
    const [invoice, setInvoice] = useState(null);
    const [addons, setAddons] = useState([]);

    useEffect(() => {
        async function setUp() {
            try {
                let stripeInvoice = await Stripe.getSubInvoice(stripeSub.latest_invoice);
                let addonArr = [];
                stripeInvoice.lines.data.forEach(lineItem => {
                    if(lineItem.price.type === 'one_time') addonArr.push(lineItem);
                });
                setInvoice(stripeInvoice);
                setAddons(addonArr);
                setState(state => ({...state, isLoading: false}));
            } catch(error) {
                setState(state => ({...state, isLoading: false, error: error.message}));
            }
        }
        setUp();
    }, [stripeSub, setState]);

    async function payInvoice() {
        try {
            setState(state => ({...state, isPaying: true}));
            await Stripe.paySubInvoice(stripeSub.latest_invoice, dCard.id);
            setState(state => ({...state, isPaying: false}));
            if(typeof onFinish === 'function') onFinish();
        } catch(error) {
            setState(state => ({...state, isPaying: false}));
        }
    }

    if(stripeSub.status === 'incomplete_expired') {
        return <Error color='warning' message='This subscription has expired. Please purchase another subscription.' />
    } else if(isLoading) {
        return <Loading />
    } else if(!!error) {
        return <Error message={error}/>
    }

    let plan = stripeSub.price || stripeSub.plan;
    let pricing = calculatePrice(plan, null, stripeSub.quantity);
    let { interval: interval_, interval_count } = plan.recurring || plan;
    let interval = (stripeSub.cancel_at_period_end)? '' : `${(interval_count < 2)? '' : interval_count}${interval_}(s)`;
    let dueDate = (stripeSub.status === 'incomplete')? new Date((parseInt(stripeSub.current_period_start)*1000) + 82800000).toLocaleString('en-US').split(',')[0] : new Date((parseInt(stripeSub.current_period_start)*1000) + 950400000).toLocaleString('en-US').split(',')[0];
    let coupon = !!invoice && !!invoice.discount && invoice.discount.coupon;
    return <>
        <div className='center'>
            {!!dCard? (
            <Alert color='info' className='center'>
                Your last payment attempt was unsuccessful. {(!!invoice && !!invoice.charge && !!invoice.charge.failure_message)? invoice.charge.failure_message : ''} Try again using your default card,
                <p style={{marginBottom: 0}}><b>{dCard.brand}</b> ending in <b>{dCard.last4}</b></p>
                Payment due by: <b>{dueDate}</b>
                <hr/>
                <p>Manage your cards on the <Link to='/profile/paymentMethods'>payment methods</Link> page.</p>
            <button className='bttn block primary' disabled={isPaying} onClick={payInvoice}>{(isPaying)? <Spinner color='light' /> : 'Pay Invoice'}</button>
            </Alert>) : (
                <Error color='danger'>Please add a card on the <Link to='/profile/paymentMethods'>payment methods</Link> page to continue.</Error>
            )}
        </div>
        <Table striped>
            <thead className='center'>
                <tr>
                    <th className='left' style={{width: '100%'}}>Description</th>
                    <th>QTY</th>
                    <th>Price</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className='left' style={{width: '100%'}}>{plan.nickname}</td>
                    <td className='center'>{stripeSub.quantity || 1}</td>
                    <td>{`$${pricing.toFixed(2).toLocaleString()}/${interval}`}</td>
                </tr>
                {addons.map(addon => {
                    return <tr key={addon.id}>
                        <td className='left' style={{width: '100%'}}>{addon.price.nickname}</td>
                        <td className='center'></td>
                        <td>${(addon.price.unit_amount/100).toFixed(2).toLocaleString()}</td>
                    </tr>
                })}
                {(!!invoice.discount && !!invoice.discount.coupon) && (
                    <tr>
                        <td className='left' style={{width: '100%'}}>Coupon</td>
                        <td className='center'></td>
                        <td>-{(!!coupon.amount_off)? `$${(coupon.amount_off/100).toFixed(2).toLocaleString()}` : `${coupon.percent_off}%`}</td>
                    </tr>
                )}
            </tbody>
        </Table>
        <div className='right' style={{paddingBottom: '10px'}}>
            <b>Total:</b> {'$' + (invoice.total/100).toFixed(2).toLocaleString()}
        </div>
    </>
}

InvoicePaymentForm.propTypes = {
    stripeSub: PropTypes.object.isRequired,
    dCard: PropTypes.object,
    onFinish: PropTypes.func,
}

export function StripeSubDetails({stripeSub}) {
    let plan = stripeSub.price || stripeSub.plan;
    let price = calculatePrice(plan, !!stripeSub.discount && stripeSub.discount.coupon, stripeSub.quantity);
    let { interval_count } = plan.recurring || plan;
    let interval = (!plan || (interval_count < 2))? '' : `${interval_count}`;
    let isSingleUse = stripeSub.cancel_at_period_end;
    return <>
        <div>
            {(!!plan && !!plan.product && !!plan.product.metadata && (plan.product.metadata.type === 'Private Campus')) && 
                <Alert color='info' className='center'>To cancel please email <a href="mailto:onboarding@virbela.com">onboarding@virbela.com</a></Alert>
            }
            <FormGroup>
                <Label control='true' sm={4} className='right'><b>ID:</b></Label>
                {stripeSub.id}
            </FormGroup>
            <FormGroup>
                <Label control='true' sm={4} className='right'><b>Price:</b></Label>
                {'$' + price.toLocaleString() + ((isSingleUse)? '' : (`/${interval}${stripeSub.plan.interval}(s)`))}
            </FormGroup>
            {(!!plan && !!plan.product && !!plan.product.metadata && (plan.product.metadata.type === 'Private Campus')) && 
                <FormGroup><Label control='true' sm={4} className='right'><b>Users:</b></Label>
                {stripeSub.quantity}</FormGroup>
            }
            <FormGroup>
                <Label control='true' sm={4} className='right'><b>Purchased:</b></Label>
                {(new Date(stripeSub.created *1000)).toLocaleDateString('en-US')}
            </FormGroup>
            {(!isSingleUse) && <FormGroup>
                <Label control='true' sm={4} className='right'><b>Last Payment:</b></Label>
                {(new Date(stripeSub.current_period_start *1000)).toLocaleDateString('en-US')}
            </FormGroup>}
            <FormGroup>
                <Label control='true' sm={4} className='right'><b>{(isSingleUse)? 'Expires:' : 'Next payment:'}</b></Label>
                {(new Date(stripeSub.current_period_end *1000)).toLocaleDateString('en-US')}
            </FormGroup>
        </div>
    </>
}

StripeSubDetails.propTypes = {
    stripeSub: PropTypes.object.isRequired,
}

export function TeamSuiteDetails({stripeSub, roomSecurityArr}) {
    const [{isLoading, error}, setState] = useState({isLoading: false, error: ''});
    const [roomSecurities, setRs] = useState(roomSecurityArr);

    useEffect(() => {
        async function setUp() {
            try {
                if(!stripeSub && !roomSecurities) {
                    setState(state => ({...state, isLoading: false, error: 'Missing data to get get credentials'}));
                    return;
                }
                if(Array.isArray(roomSecurities) && roomSecurities.length > 0) {
                    return;
                }
                if(!!stripeSub) {
                    setState(state => ({...state, isLoading: true}));
                    setRs(await ParseQueries.getRoomSecuritiesWithStripeId(stripeSub.id));
                    setState(state => ({...state, isLoading: false}));
                }
            } catch(error) {
                setState(state => ({...state, isLoading: false, error: error.message}));
            }
        }
        setUp();
    }, [stripeSub, setState]);

    if(isLoading) {
        return <Loading />
    } else if(!!error) {
        return <Error message={error} />
    } else if(!roomSecurities || roomSecurities.length < 1) {
        return <Error message='Unable to find Team Suite credentials.' />
    } else {
        let url = '';
        switch(roomSecurities[0].get('subscription').get('account').id) {
            case 'tOKUGcsdJ2':
            case 'QfmSO4ZeAN':
                url = 'https://youtu.be/plgEp1FFj6s';
                break;
            default:
                url = 'https://support.virbela.com/s/article/How-to-Access-Your-Team-Suite';
        }
        return <>
            <div>
                {roomSecurities.map((roomSecurity, idx) => {
                    let label1 = (roomSecurities.length === 1)? 'Passcode 1:' : roomSecurity.get('name') + ' Passcode 1:';
                    let label2 = (roomSecurities.length === 1)? 'Passcode 2:' : roomSecurity.get('name') + ' Passcode 2:';
                    return (
                        <div key={'passcodes_' + idx}>
                        <FormGroup key={'passcode1_' + idx}>
                            <Label control='true' sm={4} className='right'><b>{label1}</b></Label>
                            <React.Fragment>
                                {(!!roomSecurity.get('passcodes'))? roomSecurity.get('passcodes')[0] : 'not found'}
                                <CopyToClipboard id='clipboard' text={(!!roomSecurity.get('passcodes'))? roomSecurity.get('passcodes')[0] : 'not found'} onCopy={() => {Notifications.addNotification('Success', 'Successfully copied!', 'success', {timer: 1500});}}>
                                    <span className='icon-inv'>
                                        <IoMdClipboard size='1.25em' />
                                    </span>
                                </CopyToClipboard>
                                <UncontrolledTooltip placement='right' target='clipboard'>Click to copy</UncontrolledTooltip>
                            </React.Fragment>
                        </FormGroup>
                        <FormGroup key={'passcode2_' + idx}>
                            <Label control='true' sm={4} className='right'><b>{label2}</b></Label>
                            <React.Fragment>
                                {(!!roomSecurity.get('passcodes'))? roomSecurity.get('passcodes')[1] : 'not found'}
                                <CopyToClipboard id='clipboard' text={(!!roomSecurity.get('passcodes'))? roomSecurity.get('passcodes')[1] : 'not found'} onCopy={() => {Notifications.addNotification('Success', 'Successfully copied!', 'success', {timer: 1500});}}>
                                    <span className='icon-inv'>
                                        <IoMdClipboard size='1.25em' />
                                    </span>
                                </CopyToClipboard>
                                <UncontrolledTooltip placement='right' target='clipboard'>Click to copy</UncontrolledTooltip>
                            </React.Fragment>
                        </FormGroup>
                        </div>
                    );
                })}
                <FormGroup>
                    <Label control='true' sm={4} className='right'><b>Help:</b></Label>
                    <a target='_blank' rel='noopener noreferrer' href={url}>Access Instructions</a>
                </FormGroup>
                <FormGroup>
                    <Label control='true' sm={4} className='right'/>
                    <a target='_blank' rel='noopener noreferrer' href='https://assets.virbela.com/legal/VirBELA_TOS_TS.pdf'>Terms of Service</a>
                </FormGroup>
            </div>
        </>
    }
}

TeamSuiteDetails.propTypes = {
    stripeSub: PropTypes.object,
    roomSecurityArr: PropTypes.arrayOf(PropTypes.object),
}
