import React from 'react';
import { Container, Row, Col, Input, InputGroup, InputGroupAddon, Form, FormGroup, Button, Label, CustomInput, Alert, Spinner } from 'reactstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import qs from 'query-string';
import auth from './../actions/auth';
import regions from '../constants/regions';
import Countries from '../constants/Countries';
import { FORM_REQUIREMENT_REGEX, PASSWORD_SPECIAL_CHARS } from '../constants/FormRequirements';
import './register.css';
import { ValidationError } from 'yup';

const yup = require('yup');
const schema = yup.object().shape({
    size: yup.string().trim().required('Please select an organization size'),
    survey: yup.string().trim().required('Please answer the question above'),
    country: yup.string().trim().required('Please select a country'),
    region: yup.string().trim().when('country', {
        is: country => country === 'Canada' || country === 'United States',
        then: yup.string().required('Please select a region or select "Other"'),
        otherwise: yup.string().notRequired()
    }),
    organization: yup.string().trim().required('Organization name or N/A is required'),
    password: yup.string().trim().required('Password is required')
        .min(8, 'Password needs to be a minimum of 8 characters')
        .matches(FORM_REQUIREMENT_REGEX.ONE_LOWERCASE_LETTER, 'Password must contain at least one lowercase letter')
        .matches(FORM_REQUIREMENT_REGEX.ONE_UPPERCASE_LETTER, 'Password must contain at least one uppercase letter')
        .matches(FORM_REQUIREMENT_REGEX.ONE_NUMBER, 'Password must contain at least one number')
        .matches(FORM_REQUIREMENT_REGEX.ONE_SPECIAL_CHARACTER, `Password must contain at least one special character in ${PASSWORD_SPECIAL_CHARS}`)
        .matches(FORM_REQUIREMENT_REGEX.NO_SPACES, 'Password must not contain spaces'),
    email: yup.string().email('Email must be in x@y.z format').trim().required('Email required'),
    lastname: yup.string().trim().required('Last name required'),
    firstname: yup.string().trim().required('First name required')
});

function FieldGroup({ id, label, showLabel, error, ...props }) {
    return (
        <FormGroup>
            {(showLabel) && <Label for={id}>{label}</Label>}
            <Input id={id} className={!!error ? 'input-invalid' : ''} {...props} />
            {(!!error) && <div className='field-invalid-message'>{error.message}</div>}
        </FormGroup>
    );
}

export default class Register extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            validationErrors: {},
            isLoading: false,
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            isPasswordVisible: false,
            organization: '',
            survey: '',
            size:'',
            country: '',
            region: '',
            LA: false
        }
        this.recaptchaRef = React.createRef();
        this.htscRef = React.createRef();
        this.utmSourceRef = React.createRef();
        this.utmMediumRef = React.createRef();
        this.utmCampaignRef = React.createRef();
        this.utmContentRef = React.createRef();
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    validateForm = async () => {
        if(this.state.isLoading) return;

        this.setState({ validationErrors: {}, LAerror: '' });
        const validationOptions = { abortEarly: false };
        try {
            await schema.validate(
                {
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    email: this.state.email,
                    password: this.state.password,
                    organization: this.state.organization,
                    country: this.state.country,
                    region: this.state.region,
                    survey: this.state.survey,
                    size: this.state.size
                }, 
                validationOptions);
            
            if (!this._isMounted) return;
            if (this.state.LA) {
                this.recaptchaRef.current.execute();
            } else {
                this.setState({ LAerror: 'Please agree to the Terms of Use and Privacy Policy' });
            }
        } catch (error) {
            if (!this._isMounted) return;
            if (error instanceof ValidationError) {
                const validationErrors = {};
                error.inner.forEach(ve => {
                    validationErrors[ve.path] = ve;
                });
    
                this.setState({ validationErrors });
            } else {
                this.setState({error: error.message});
            }
        }
    }

    submitForm = async (e) => {
        e.preventDefault();
        await this.validateForm();
    }

    handleKeyDown = async (e) => {
        if (e.key === 'Enter') {
            await this.validateForm();
        }
    }

    registerUser = async () => {
        try {
            let {isLoading, firstname, lastname, email, password, organization, survey, size, country, region } = this.state;
            if(isLoading || !this.recaptchaRef.current.getValue()) return;
            this.setState({isLoading: true, error: ''});

            let htsc = this.htscRef.current.value;
            let utm_campaign = this.utmCampaignRef.current.value;
            let utm_medium = this.utmMediumRef.current.value;
            let utm_source = this.utmSourceRef.current.value;
            let utm_content = this.utmContentRef.current.value;

            const response = await auth.signUp({ firstname, lastname, email: email.toLowerCase(), password, company: organization }, { survey, size, country, region, htsc, utm_campaign, utm_medium, utm_source, utm_content });
            if(!this._isMounted) return;

            if (response && response.verificationRequired) {
                this.props.history.push('/verify');
            } else if (!!this.props.location.prevPage && this.props.location.prevPage.includes('/products/')) {
                this.props.history.push({
                    pathname: this.props.location.prevPage,
                    data: this.props.location.data
                });
            } else {
                let params = qs.parse(this.props.location.search);
                if(!!params.redirect) {
                    window.location.href = ('https://www.virbela.com/' + params.redirect);
                }
                this.props.history.push('/login');
            }
        } catch(error) {
            if(!this._isMounted) return;
            this.setState({error: error.message, isLoading: false, password: ''});
            this.resetCaptcha();
        }
    }

    resetCaptcha = () => {
        this.recaptchaRef.current.reset();
    }

    updateInput = (e) => {
        this.setState({[e.target.id]: e.target.value});
    }

    updateSurvey = (e) => {
        this.setState({survey: e.target.value});
    }

    updateSize = (e) => {
        this.setState({size: e.target.value});
    }

    updateCountry = (e) => {
        this.setState({country: e.target.value, region: ''});
    }

    updateRegion = (e) => {
        this.setState({region: e.target.value});
    }

    updateLA = (e) => {
        this.setState({[e.target.id]: e.target.checked});
    }
    
    togglePasswordVisibility = () => {
        this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
    }

    getRequirementStyle = (password, regex) => {
        const requirementIsMet = password.match(regex);
        return requirementIsMet ? 'password-req-valid' : 'password-req-invalid';
    }

    renderForm = () => {
        let params = qs.parse(this.props.location.search);
        let popularCountries = ['United States', 'Canada', 'France', 'United Kingdom', 'Mexico'];
        return (
            <Form className='customForm'>
                <Row className='rowFormat page-title-row'>
                    <Col className='center'>
                        <h2>Registration</h2>
                    </Col>
                </Row>
                <Row>
                    <Col className='center form-instructions'>
                        <span>All fields are required.</span>
                    </Col>
                </Row>
                {!!this.state.error && (
                    <Alert color='danger' className='center'>{this.state.error}</Alert>
                )}
                <Row>
                    <Col>
                        <input type='hidden' id='htsc' name='htsc' ref={this.htscRef} />
                        <input type='hidden' id='utm_source' name='utm_source' ref={this.utmSourceRef} />
                        <input type='hidden' id='utm_medium' name='utm_medium' ref={this.utmMediumRef} />
                        <input type='hidden' id='utm_campaign' name='utm_campaign' ref={this.utmCampaignRef} />
                        <input type='hidden' id='utm_content' name='utm_content' ref={this.utmContentRef} />
                    </Col>
                </Row>
                <Row>
                    <Col xs='12' md='6'>
                        <FieldGroup 
                            id='firstname' 
                            label='First Name:' 
                            error={this.state.validationErrors.firstname} 
                            placeholder='First name*' 
                            autoFocus 
                            value={this.state.firstname} 
                            onChange={this.updateInput} 
                            onKeyDown={this.handleKeyDown} 
                        />
                    </Col>
                    <Col xs='12' md='6'>
                        <FieldGroup 
                            id='lastname' 
                            label='Last Name:'
                            error={this.state.validationErrors.lastname}
                            placeholder='Last name*' 
                            value={this.state.lastname} 
                            onChange={this.updateInput} 
                            onKeyDown={this.handleKeyDown} 
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FieldGroup 
                            id='email' 
                            type='email' 
                            label='Email:'
                            error={this.state.validationErrors.email}
                            placeholder='Email*' 
                            value={this.state.email} 
                            onChange={this.updateInput} 
                            onKeyDown={this.handleKeyDown} 
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {/* TODO move to own component? */}
                        <FormGroup>
                            <InputGroup>
                                <Input 
                                    id='password' 
                                    type={this.state.isPasswordVisible ? 'text' : 'password'}
                                    className={!!this.state.validationErrors.password ? 'input-invalid' : ''}
                                    placeholder='Password*' 
                                    value={this.state.password} 
                                    onChange={this.updateInput} 
                                    onKeyDown={this.handleKeyDown} 
                                />
                                <InputGroupAddon addonType="append">
                                    <Button onClick={this.togglePasswordVisibility}>{this.state.isPasswordVisible ? (<FaEyeSlash />) : (<FaEye />)}</Button>
                                </InputGroupAddon>
                            </InputGroup>
                            <ul id='password-reqs-list' className={!!this.state.validationErrors.password ? 'password-invalid' : ''}>
                                <li className={this.getRequirementStyle(this.state.password, FORM_REQUIREMENT_REGEX.MIN_LENGTH_OF_8)}>8 characters minimum</li>
                                <li className={this.getRequirementStyle(this.state.password, FORM_REQUIREMENT_REGEX.ONE_UPPERCASE_LETTER)}>One uppercase letter</li>
                                <li className={this.getRequirementStyle(this.state.password, FORM_REQUIREMENT_REGEX.ONE_LOWERCASE_LETTER)}>One lowercase letter</li>
                                <li className={this.getRequirementStyle(this.state.password, FORM_REQUIREMENT_REGEX.ONE_NUMBER)}>One number</li>
                                <li className={this.getRequirementStyle(this.state.password, FORM_REQUIREMENT_REGEX.ONE_SPECIAL_CHARACTER)}>One special character in {PASSWORD_SPECIAL_CHARS}</li>
                                <li className={this.getRequirementStyle(this.state.password, FORM_REQUIREMENT_REGEX.NO_SPACES)}>No spaces</li>
                            </ul>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FieldGroup 
                            id='organization' 
                            label='Organization:' 
                            error={this.state.validationErrors.organization}
                            placeholder='Organization name*' 
                            value={this.state.organization} 
                            onChange={this.updateInput} 
                            onKeyDown={this.handleKeyDown} 
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Input type='select' name='country' id='country' className={!!this.state.validationErrors.country ? 'input-invalid' : ''} placeholder='Country*' value={this.state.country} onChange={this.updateCountry}>
                                <option value='' hidden>Country*</option>
                                {popularCountries.map(country => <option key={'popular-' + country} value={country}>{country}</option>)}
                                {Countries.map(country => <option key={country.name} value={country.name}>{country.name}</option>)}
                            </Input>
                            {(!!this.state.validationErrors.country) && <div className='field-invalid-message'>{this.state.validationErrors.country.message}</div>}
                        </FormGroup>
                    </Col>
                </Row>
                {this.state.country === 'United States' && (<Row>
                    <Col>
                        <FormGroup>
                            <Input type='select' name='region' id='region' className={!!this.state.validationErrors.region ? 'input-invalid' : ''} placeholder='Region*' value={this.state.region} onChange={this.updateRegion}>
                                <option value='' hidden>Region*</option>
                                {regions.us.map(region => <option key={region} value={region}>{region}</option>)}
                                <option value='Other'>Other</option>
                            </Input>
                            {(!!this.state.validationErrors.region) && <div className='field-invalid-message'>{this.state.validationErrors.region.message}</div>}
                        </FormGroup>
                    </Col>
                </Row>)}
                {this.state.country === 'Canada' && (<Row>
                    <Col>
                        <FormGroup>
                            <Input type='select' name='region' id='region' className={!!this.state.validationErrors.region ? 'input-invalid' : ''} placeholder='Region*' value={this.state.region} onChange={this.updateRegion}>
                                <option value='' hidden>Region*</option>
                                {regions.canada.map(region => <option key={region} value={region}>{region}</option>)}
                                <option value='Other'>Other</option>
                            </Input>
                            {(!!this.state.validationErrors.region) && <div className='field-invalid-message'>{this.state.validationErrors.region.message}</div>}
                        </FormGroup>
                    </Col>
                </Row>)}
                <Row>
                    <Col>
                        <FormGroup>
                            <Input type='select' name='survey' id='survey' className={!!this.state.validationErrors.survey ? 'input-invalid' : ''} placeholder='What brought you to Virbela?*' value={this.state.survey} onChange={this.updateSurvey}>
                                <option value='' hidden>What brought you to Virbela?*</option>
                                <option value={`I'm looking to host virtual events`}>I'm looking to host virtual events</option>
                                <option value={`I'm looking for a remote work solution`}>I'm looking for a remote work solution</option>
                                <option value={`I'm looking for a virtual education solution`}>I'm looking for a virtual education solution</option>
                                <option value={`I'm just exploring`}>I'm just exploring</option>
                                <option value={`I'm here to attend an event`}>I'm here to attend an event</option>
                                <option value={`I'm here to attend a class`}>I'm here to attend a class</option>
                                <option value={`I'm here to visit a team suite`}>I'm here to visit a team suite</option>
                                <option value={`I'm here to attend a meeting`}>I'm here to attend a meeting</option>
                            </Input>
                            {(!!this.state.validationErrors.survey) && <div className='field-invalid-message'>{this.state.validationErrors.survey.message}</div>}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Input type='select' name='size' id='size' className={!!this.state.validationErrors.size ? 'input-invalid' : ''} placeholder='Organization Size*' value={this.state.size} onChange={this.updateSize}>
                                <option value='' hidden>Organization Size*</option>
                                <option value={`1-10`}>1-10</option>
                                <option value={`11-50`}>11-50</option>
                                <option value={`51-250`}>51-250</option>
                                <option value={`251-1K`}>251-1K</option>
                                <option value={`1K-5K`}>1K-5K</option>
                                <option value={`5K-10K`}>5K-10K</option>
                                <option value={`10K-50K`}>10K-50K</option>
                                <option value={`50K-100K`}>50k-100K</option>
                                <option value={`100K+`}>100K+</option>
                            </Input>
                            {(!!this.state.validationErrors.size) && <div className='field-invalid-message'>{this.state.validationErrors.size.message}</div>}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={{size: 12}} >
                        <CustomInput type='checkbox' id='LA' onChange={this.updateLA} className={`LA ${ !!this.state.LAerror ? 'input-invalid' : '' }`} color='danger'>
                            <span onClick={() => {
                                this.setState({LA: !this.state.LA}); 
                                document.getElementById('LA').checked=!this.state.LA}
                            }>
                                {"I agree to Virbela's "}
                            </span>
                            <a href='https://assets.virbela.com/legal/VirBELA_TOU_EULA.pdf' target='_blank' rel='noopener noreferrer'>Terms of Use</a> and <a href='https://assets.virbela.com/legal/VirBELA_Privacy_Policy.pdf' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>*
                        </CustomInput>
                        {(!!this.state.LAerror) && <div className='field-invalid-message'>{this.state.LAerror}</div>}
                    </Col>
                </Row>
                <Row className='rowFormat'>
                    <Col className='right'>
                        {(this.state.isLoading)? <Spinner color='primary'/> : 
                        <button className='bttn primary' disabled={this.state.isLoading} onClick={this.submitForm}>Join!</button>}
                    </Col>
                    <Col style={{ flexBasis: '100%' }}><Link to={(!!params.redirect)? `/login?redirect=${params.redirect}` : '/login'}><font size='2'>Have an account? Login here</font></Link></Col>
                </Row>
                <ReCAPTCHA
                    ref={this.recaptchaRef}
                    size="invisible"
                    sitekey="6LcYwZAUAAAAABdvZA1m6jLLireNHaSmb0OE6m_8"
                    onChange={this.registerUser}
                    onExpired={this.resetCaptcha}
                />
            </Form>
        );
    }

    render() {
        return (
            <section className='registerBackground' style={{position: 'relative'}}>
                <Container className='left minHeightNoHeader spacing flex-center' style={{ padding: '15px 0' }}>
                    <Row className='info-box'>
                        <Col xs={12} md={{size: 6 }} className='left-col'>
                            <div className='left-col-center'>
                                <div style={{margin: '10px 0px 10px 0px'}} className='center'><img src='https://s3-us-west-2.amazonaws.com/opencampus.virbela.com/images/logo.png' alt='virbela-logo' height='80' /></div>
                                <div className='center'><p> Designed to enable companies to grow and scale in the cloud. Try now for free!</p></div>
                            </div>
                        </Col>
                        <Col xs={12} md={{ size: 6, offset: 0 }}>
                            {this.renderForm()}
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}