/** @format */

const AppConfig = {
  Pagination: 10,
  ParseApplicationId: 'open-campus-staging',
  StripeKey: 'pk_test_I5GIndvKsIfDL9BWHylSd3uO',
  ClientId: 'ca_BexR6fPlYyNhBclg3GJpAES2bCa2wDBh',
  OktaIssuer: 'https://exprealty.oktapreview.com/oauth2/default',
  OktaClientId: '0oagaim8gnbAaCprz0h7',
};
if (window.location.href.match(/8080/)) {
  AppConfig.ParseServerURL = 'http://localhost:1337/parse';
  AppConfig.RedirectURI = 'http://localhost:8080/referrals/oauth/';
} else if (window.location.href.match(/opencampus.virbela.com/) && !window.location.href.match(/staging/)) {
  AppConfig.ParseApplicationId = 'open-campus';
  AppConfig.StripeKey = 'pk_live_66Tln6U7Uda1Z170hX9rGBbj';
  AppConfig.ClientId = 'ca_BexRYn8yIRCuOZpeuzD2hT18cKv0DHgs';
  AppConfig.OktaIssuer = 'https://exprealty.okta.com/oauth2/default';
  AppConfig.OktaClientId = '0oa2apzgmge2Ieb1U2p7';
  AppConfig.ParseServerURL = 'https://open-campus-billing.virbela.com/parse';
  AppConfig.RedirectURI = 'https://opencampus.virbela.com/referrals/oauth/';
} else {
  AppConfig.ParseServerURL = 'https://open-campus-billing-staging.herokuapp.com/parse';
  AppConfig.RedirectURI = 'http://staging.opencampus.virbela.com.s3-website-us-west-2.amazonaws.com/referrals/oauth/';
}

export default AppConfig;
