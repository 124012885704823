import React from 'react';
import { Alert, Container, Form, FormGroup, Label, Col, Input, CustomInput } from 'reactstrap';
import Loading from './../loading';
import stripe from './../../actions/stripe';
export default class InvoiceSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            account: null,
            paymentMethods: null,
            sub: null,
            error: null,
            selectedCard: undefined,
            defaultSource: '0',
            monthlyInvoice: !!props.subscription && props.subscription.metadata.monthlyInvoice,
            cc: !!props.subscription && formatEmails(props.subscription.metadata.monthlyInvoiceCC),
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if(!!this.props.subscription ) {
            this.fetchPaymentMethods(this.props.subscription);
        }
    }

    fetchPaymentMethods = (sub) => {
        this.setState({isLoading: true, paymentMethods: null, error: null, selectedCard: undefined, sub:sub });
        stripe.getPaymentMethods((customer, error) => {
            let defaultSource = '0';
            if(sub.default_source !== null && customer && customer.sources){
                for(let index in customer.sources.data){
                    if(!!customer.sources.data && !!customer.sources.data[index] && customer.sources.data[index].id === sub.default_source ){
                        defaultSource = String(index);
                        break;
                    }
                }
            }
            if(this._isMounted) {
                this.setState({
                    isLoading: false, 
                    paymentMethods: customer? customer.sources: null, 
                    error: error, 
                    selectedCard: defaultSource, 
                    defaultSource: defaultSource, 
                    sub: sub,
                    monthlyInvoice: sub && sub.metadata.monthlyInvoice,
                    cc: sub && formatEmails(sub.metadata.monthlyInvoiceCC),
                });
            }
        });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    updateInput = (e) => {
        this.setState({[e.target.id]: e.target.value});
    }

    submit = async (e) => {
        e.preventDefault();
        try {
            let {selectedCard, defaultSource, paymentMethods, monthlyInvoice, cc, sub} = this.state;
            let savedValues = {
                metadata: {
                    monthlyInvoice: (!!monthlyInvoice)? 'true' : '',
                    monthlyInvoiceCC: formatEmails(cc),
                }
            };
            if(!((selectedCard === defaultSource) || (selectedCard === undefined))) {
                savedValues['default_source'] = paymentMethods.data[selectedCard || 0].id;
            }
            this.setState({isLoading: true});
            let subscription = await stripe.updateSubscription(savedValues, sub.id);
            if(!this._isMounted) return;
            this.props.updateParentState({subscription});
            this.fetchPaymentMethods(subscription);
        } catch(error) {
            if(this._isMounted) {
                this.setState({isLoading: false, error: error.message});
            }
        }
    }

    selectCard = (e) =>{
        if(e){
            this.setState({selectedCard: e.target.value });
        }
        else{
            this.setState({selectedCard: undefined});
        }

    }

    toggle = (e) => {
        let value = e.target.checked;
        if(value) {
            this.setState({monthlyInvoice: 'true'});
        } else {
            this.setState({monthlyInvoice: ''});
        }
    }

    updateForm = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        this.setState((state) => ({...state, [id]: value}));
    }

    render() {
        let { isLoading, error, monthlyInvoice, cc, selectedCard, paymentMethods } = this.state;
        if(isLoading) {
            return (
                <Loading />
            );
        } else {
            return (
                <Container className='center'>
                    <Form>
                        {!!error && (
                            <FormGroup row>
                                <Col>
                                    <Alert className='center' color='danger'>{error}</Alert>
                                </Col>
                            </FormGroup>
                        )}
                        <FormGroup row>
                            <Label for='Cards' sm={3} className='left'><b>Payment Method: </b></Label>
                            <Col sm={9}>
                                <Input type='select' name='select_card' id='card' value={selectedCard} onChange={this.selectCard}>
                                <option hidden value={''}>Select card..</option>
                                {!!paymentMethods && paymentMethods.data.map((paymentMethod, index) => {
                                    let paymentSource = (paymentMethod.object === 'source') ? paymentMethod.card : paymentMethod;
                                    let isACH = (paymentMethod.type === 'ach_credit_transfer');
                                    let text = (isACH)? `ACH Credit Transfer` : `Charge to ${!!paymentSource && paymentSource.brand} ending in ${!!paymentSource && paymentSource.last4}`
                                    return (
                                        <option key={paymentMethod.id} value={index} disabled={isACH}>{text}</option>
                                    );
                                })}
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="inv-receipt-toggle" sm={3} className='left'> </Label>
                            <Col sm={9} className='left'>
                                <CustomInput type="switch" id="receipt-toggle" name="receipt-toggle" label="Receive Invoice Receipts" checked={!!monthlyInvoice} onChange={this.toggle}/>
                            </Col>
                        </FormGroup>
                        {!!monthlyInvoice && <FormGroup row>
                            <Label for="cc" sm={3} className='left'><b>CC:</b></Label>
                            <Col sm={9} className='left'>
                                <Input type="text" id="cc" name="cc" value={cc} onChange={this.updateForm} placeholder='joe@virbela.com, bob@virbela.com ...'/>
                            </Col>
                        </FormGroup>}
                        <FormGroup row>
                            <Col sm={{size: 9, offset: 3}} className='right'>
                                <button className='bttn block outline primary' disabled={isLoading} onClick={this.submit}>Update</button>
                            </Col>
                        </FormGroup>
                    </Form>
                </Container>
            );
        }
    }
}

function formatEmails(emailsStr) {
    if(!emailsStr) return '';
    let CCArr = [];
    emailsStr.split(',').forEach(e => {
        let email_ = e.trim();
        if(((/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email_)))) {
            CCArr.push(email_);
        }
    });
    return CCArr.join(', ');
}