import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import './register.css';

export default withAuth(class OktaLoginButton extends Component {

  componentDidMount() {
    this.props.auth.login('/oktaAuth');
  }

  render() {
      return (
        <section className='registerBackground minHeightNoHeader' style={{position: 'relative'}}>
            <div style={{position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0, 0.20)'}}/>
        </section>
      );
  }

});