import React from 'react';
import { Alert, Container, Form, FormGroup, Label, Col, Input } from 'reactstrap';
import Room from './../../actions/room';
import Loading from './../loading';

export default class EditAccount extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            account: null,
            orgName: '',
            desc: '',
            error: null
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if(!!this.props.roomSecurityArr && !!this.props.roomSecurityArr[0].get('account')) {
            this.setState({orgName: this.props.roomSecurityArr[0].get('account').get('organizationName'), desc: this.props.roomSecurityArr[0].get('account').get('organizationDescription')});
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    updateInput = (e) => {
        this.setState({[e.target.id]: e.target.value});
    }

    submit = async (e) => {
        e.preventDefault();
        try {
            this.setState({isLoading: true});
            if(!!this.props.roomSecurityArr && !!this.props.roomSecurityArr[0].get('account')) {
                let updatedRoomSecurityArr = await Room.updateTeamRoomAccount(this.state.orgName, this.state.desc, this.props.roomSecurityArr);
                if(this._isMounted) {
                    this.setState({isLoading: false});
                    this.props.updateParentState({roomSecurities: updatedRoomSecurityArr, selected: updatedRoomSecurityArr[this.props.selectedIdx]});
                }
            } else {
                let { roomSecurityArr } = await Room.createTeamRoomAccount(this.state.orgName, this.state.desc, this.props.roomSecurityArr);
                if(this._isMounted) {
                    this.setState({isLoading: false});
                    this.props.updateParentState({roomSecurities: roomSecurityArr, selected: roomSecurityArr[this.props.selectedIdx]});
                }
            }
        } catch(error) {
            if(this._isMounted) {
                this.setState({isLoading: false, error: error.message});
            }
        }
    }

    handleEnterKey = (e) => {
        if(e.key === 'Enter') {
            e.preventDefault();
            this.submit(e);
        }
    }

    render() {
        if(this.state.isLoading) {
            return (
                <Loading />
            );
        } else {
            return (
                <Container className='center'>
                    <Form>
                        {!!this.state.error && (
                            <FormGroup row>
                                <Col>
                                    <Alert className='center' color='danger'>{this.state.error}</Alert>
                                </Col>
                            </FormGroup>
                        )}
                        <FormGroup row>
                            <Label for='orgName' sm={3} className='left'><b>Organization:</b></Label>
                            <Col sm={9}>
                                <Input id='orgName' name='orgName' placeholder='Enter organization name...' value={this.state.orgName} onChange={this.updateInput} onKeyDown={this.handleEnterKey}/>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for='desc' sm={3} className='left'><b>Description:</b></Label>
                            <Col sm={9}>
                                <Input type='textarea' id='desc' name='desc' placeholder='Enter description...' value={this.state.desc} onChange={this.updateInput} onKeyDown={this.handleEnterKey}/>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col sm={{size: 9, offset: 3}} className='right'>
                                <button className='bttn block outline primary' onClick={this.submit}>Submit</button>
                            </Col>
                        </FormGroup>
                    </Form>
                </Container>
            );
        }
    }
}