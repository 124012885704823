import React from 'react';
import stripe from './../actions/stripe';
import parseQueries from './../actions/parseQueries';
import Loading from './../component/loading';
import { Container, Row, Col, Input, ListGroup, ListGroupItem, Breadcrumb, BreadcrumbItem, TabContent, TabPane } from 'reactstrap';
import './roomSettings.css';
import { IoIosArrowForward } from 'react-icons/io';
import { Link } from "react-router-dom";
import Header from './../component/header';
import Error from './../component/error';
import SecuritySettings from './../component/roomSettings/securitySettings';
import AccountSettings from './../component/roomSettings/accountSettings';
import PaymentSettings from './../component/roomSettings/paymentSettings';
import Invite from './../component/roomSettings/invite';
import { DeleteSubBtn } from './../component/roomSettings/deleteSubscription';
import Parse from 'parse';
import qs from 'query-string';
export default class RoomSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            error: null,
            roomSecurities: null,
            subscription: null,
            selected: null,
            tab: '1',
            idx: 0,
            owner: null,
        }
    }

    async componentDidMount() {
        this._isMounted = true;
        const subscriptionId = this.props.match.params.id;
        if(subscriptionId) {
            try {
                let subscription = null;
                if(subscriptionId.startsWith('sub_')){
                    subscription = await stripe.getSubscription(subscriptionId);
                }
                let roomSecurities = await parseQueries.getRoomSecuritiesWithStripeId(subscriptionId);
                if(!!roomSecurities && !!roomSecurities.length) {
                    let params = qs.parse(this.props.location.search);
                    this.setState({isLoading: false, roomSecurities, subscription, selected: roomSecurities[0], owner: roomSecurities[0].get('subscriber').id, tab: params.tab || '1'});
                } else {
                    this.setState({isLoading: false, subscription, error: 'Cannot find rooms'})
                }
            } catch(error) {
                this.setState({isLoading: false, error: error.message});
            }
        } else {
            this.setState({isLoading: false, error: 'invalid url'});
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    renderRooms(room) {
        if(this.state.isLoading) {
            return (
                <Loading />
            );
        } else if(!!this.state.error) {
            return (
                <>
                    <Error color='info' message={this.state.error} />
                    <hr />
                    <Error color='warning'>
                        <p>
                            If you currently have an active subscription, you will not be refunded for the time left in your subscription.
                        </p>
                        <DeleteSubBtn className='bttn danger' text='Remove Subscription'/>
                    </Error>
                </>
            );
        } else {
            let billingAccess = Parse.User.current().id === this.state.owner;
            return (
                <div>
                    <TabContent activeTab={this.state.tab}>
                        <TabPane tabId='1'>
                            {(this.state.tab === '1') && <AccountSettings subscription={this.state.subscription} roomSecurityArr={this.state.roomSecurities} selectedIdx={this.state.idx} updateParentState={(newState) => this.setState(newState)} />}
                        </TabPane>
                        
                        <TabPane tabId='2'>
                            {(this.state.tab === '2') && <SecuritySettings room={room} roomSecurities={this.state.roomSecurities} updateState={(state) => {this.setState(state)}} updateRoomSecurities={this.updateRoomSecurities}/>}
                        </TabPane>
                        <TabPane tabId='3'>
                            {(this.state.tab === '3') && <Invite roomSecurity={room} updateRoomSecurities={this.updateRoomSecurities} />}
                        </TabPane>
                        <TabPane tabId='4'>
                            {(this.state.tab === '4') && !!billingAccess && <PaymentSettings subscription={this.state.subscription} roomSecurityArr={this.state.roomSecurities} selectedIdx={this.state.idx} updateParentState={(newState) => this.setState(newState)} />}
                        </TabPane>
                    </TabContent>
                </div>
            );
        }
    }

    updateRoomSecurities = (rs) => {
        let updatedRoomSecurities = this.state.roomSecurities.map(roomSecurity => {
            if(roomSecurity.id === rs.id) {
                return rs;
            } else {
                return roomSecurity;
            }
        });
        this.setState({roomSecurities: updatedRoomSecurities});
    }

    fillRoomDropdown = (roomSecurities) => {
        if(!!roomSecurities && !!roomSecurities.length) {
            return roomSecurities.map((roomSecurity, idx) => {
                return (
                    <option value={idx} key={idx}>{roomSecurity.get('name') || 'Room Group ' + (idx + 1)}</option>
                );
            })
        } else {
            return (
                <option value={null}>...</option>
            );
        }
    }

    selectChange = (e) => {
        let roomSecurities = this.state.roomSecurities;
        this.setState({selected: roomSecurities[e.target.value], idx: e.target.value});
    }

    selectTab = (tab)=> {
        this.setState({tab})
        this.props.history.push('/settings/room/'+this.props.match.params.id+'?tab='+tab);
    }
    render() {
        let billingAccess = Parse.User.current().id === this.state.owner;
        return (
            <div>
                <Header />
                <div className='minHeightHeader' style={{paddingBottom: '20px', paddingTop: '15px'}}>
                    <Container>
                        <Row>
                            <Col xs='12' md={{ size: 4 }} lg={{ size: 4}} xl={{ size: 3}} className='right'>
                                <Breadcrumb>
                                    <BreadcrumbItem><Link to='/profile/subscriptions'>Profile</Link></BreadcrumbItem>
                                    <BreadcrumbItem active>Room</BreadcrumbItem>
                                </Breadcrumb>
                                <ListGroup>
                                    <ListGroupItem className={(this.state.tab === '1')? 'selected' : null} tag='button' action onClick={() => {this.selectTab('1')}}>
                                        <div className='left'>
                                            Account
                                        </div>
                                        <span style={{position: 'absolute', right: '5px', top: '10px'}}>
                                            <IoIosArrowForward />
                                        </span>
                                    </ListGroupItem>
                                    <ListGroupItem className={(this.state.tab === '2')? 'selected' : null} tag='button' action onClick={() => {this.selectTab('2')}}>
                                        <div className='left'>
                                            Security
                                        </div>
                                        <span style={{position: 'absolute', right: '5px', top: '10px'}}>
                                            <IoIosArrowForward />
                                        </span>
                                    </ListGroupItem>
                                    <ListGroupItem className={(this.state.tab === '3')? 'selected' : null} tag='button' action onClick={() => {this.selectTab('3')}}>
                                        <div className='left'>
                                            Invite
                                        </div>
                                        <span style={{position: 'absolute', right: '5px', top: '10px'}}>
                                            <IoIosArrowForward />
                                        </span>
                                    </ListGroupItem>
                                    {!!billingAccess &&
                                    <ListGroupItem className={(this.state.tab === '4')? 'selected' : null} tag='button' action onClick={() => {this.selectTab('4')}}>
                                        <div className='left'>
                                            Billing
                                        </div>
                                        <span style={{position: 'absolute', right: '5px', top: '10px'}}>
                                            <IoIosArrowForward />
                                        </span>
                                    </ListGroupItem>
                                    }
                                </ListGroup>
                            </Col>
                            <Col xs='12' md={{ size: 8 }} lg={{size: 8}} xl={{ size: 8 }} className='left'>
                                <Row>
                                    <Col xs={{size: 6}} md={{size: 5}}>
                                        {(this.state.tab !== '1' && !!this.state.roomSecurities && this.state.roomSecurities.length > 1) && (
                                            <Input type="select" name="select" id="exampleSelect" disabled={!this.state.roomSecurities || (!!this.state.roomSecurities && this.state.roomSecurities.length <= 1)} style={{transform: 'translate(0, 8px)'}} onChange={this.selectChange} value={this.state.idx}>
                                                {this.fillRoomDropdown(this.state.roomSecurities)}
                                            </Input>
                                        )}
                                    </Col>
                                </Row>
                                <hr />
                                {this.renderRooms(this.state.selected)}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}