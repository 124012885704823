/**
 *
 * @format
 */
/* eslint-disable */

import Parse, { Cloud } from 'parse';
import Notifications from './notifications';

class ParseUser {
  async getDownloadUrls() {
    return await Cloud.run('getDownloadUrls').catch((err) => console.error(err.message));
  }

  async verifyUser(code, failureCallBack, successCallback) {
    let error = null;
    let res = await Cloud.run('verifyUser', { code: code }).catch((err) => {
      if (err.message && !err.message.loggedInToEmailToVerify)
        Notifications.addNotification('Error', err.message.reason || 'Verification failed.', 'error');
      error = err.message;
    });
    error ? failureCallBack(error) : successCallback(res);
  }

  async resendVerificationEmail(callBack, hideNotifications) {
    let error = null;
    let res = await Cloud.run('resendVerificationEmail').catch((err) => {
      console.error(err.message);
      error = err.message;
      if (!hideNotifications) Notifications.addNotification('Error', err.message, 'error');
    });
    if (!error && !hideNotifications) {
      Notifications.addNotification('Success', res, 'success');
    }
    callBack && callBack(error);
  }

  async sendResetPasswordEmail(email, callBack) {
    let error = null;
    let res = await Cloud.run('sendResetPasswordEmail', { email: email.toLowerCase() }).catch((err) => {
      console.error(err.message);
      error = err.message;
    });
    if (!error) {
      Notifications.addNotification('Success', res, 'success');
    }
    callBack(error);
  }

  async updatePassword(email, password, URL, callBack) {
    let error = null;
    let res = await Cloud.run('updatePassword', { email: email, password: password, URL: URL }).catch((err) => {
      console.error(err.message);
      error = err.message;
      Notifications.addNotification('Error', err.message, 'error');
    });
    if (!error) {
      Notifications.addNotification('Success', res, 'success');
    }
    callBack(error);
  }

  getCurrentUser() {
    return Parse.User.current();
  }

  addAdminMod(field, user, permission, accountId) {
    return new Promise(async (resolve, reject) => {
      try {
        let u = await Cloud.run('addAdminMod', { field, user, permission, accountId });
        Notifications.addNotification('Success', 'Successfully added user', 'success');
        resolve(u);
      } catch (error) {
        console.error(error.message);
        Notifications.addNotification('Error', error.message, 'error');
        reject(error);
      }
    });
  }

  updateMembershipPermissionById(membershipId, permissionLevel) {
    const membership = Parse.Object.extend('Membership').createWithoutData(membershipId);
    return membership.save({ permission: permissionLevel });
  }

  async removeMembershipById(membershipId) {
    const membership = Parse.Object.extend('Membership').createWithoutData(membershipId);
    return await membership.destroy();
  }
}

export default new ParseUser();
