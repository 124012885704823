import React, { useState, useEffect } from 'react';
import Loading from './loading';
import { Route, Redirect } from 'react-router-dom';
import auth from './../actions/auth';
import qs from 'query-string';

export const ProtectedRoute = ({component: Component, ...rest}) => {
    const gotVerificationRequirement = auth.isVerificationRequired() !== null;
    const [isLoading, setIsLoading] = useState(!gotVerificationRequirement);
    useEffect(() => {
        auth.setVerificationRequired().then(() => {
            setIsLoading(false);
        }).catch(error => {
             setIsLoading(false);
        });
    }, []);
    if (isLoading) return <Loading extraClassName='loading-large' />;

    return (
        <Route 
            {...rest}
            render={ props => {
                if(auth.isAuthenticated()) {
                    if(auth.isVerified() || !auth.isVerificationRequired()) {
                        return (
                            <Component {...props} />
                        );
                    } else {
                        return (
                            <Redirect 
                                to='/verify'
                            />
                        )
                    }
                } else {
                    return (
                        <Redirect 
                            to={{
                                pathname: '/login',
                                prevPage: window.location.pathname
                            }}
                        />
                    );
                }
            }}
        />
    )
}

export const SemiProtectedRoute = ({component: Component, ...rest}) => {
    const gotVerificationRequirement = auth.isVerificationRequired() !== null;
    const [isLoading, setIsLoading] = useState(!gotVerificationRequirement);
    useEffect(() => {
        auth.setVerificationRequired().then(() => {
            setIsLoading(false);
        }).catch(error => {
             setIsLoading(false);
        });
    }, []);
    if (isLoading) return <Loading extraClassName='loading-large' />;

    return (
        <Route 
            {...rest}
            render={ props => {
                if(auth.isAuthenticated() && !auth.isVerified() && auth.isVerificationRequired()) {
                    return (
                        <Component {...props} />
                    );
                } else {
                    return (
                        <Redirect 
                            to='/'
                        />
                    );
                }
            }}
        />
    )
}

export const UnprotectedRoute = ({component: Component, ...rest}) => {
    return (
        <Route 
            {...rest}
            render={ props => {
                if(!auth.isAuthenticated()) {
                    return (
                        <Component {...props} />
                    );
                } else {
                    let params = qs.parse(window.location.search);
                    if(!!params.redirect) {
                        window.location.href = ('https://www.virbela.com/' + params.redirect);
                    }
                    return (
                        <Redirect 
                            to='/profile/subscriptions'
                        />
                    );
                }
            }}
        />
    )
}
