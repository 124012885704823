import Parse, { Cloud } from 'parse';
import stripe from './stripe';
import Notifications from './notifications';
import parseUser from './../actions/parseUser';

class Auth {
    constructor() {
        this.authenticated = false;
        this.verificationRequired = null;
        this.currentUser = null;
        this.error = null;
    }

    async setVerificationRequired() {
        const verificationRequired = await Parse.Cloud.run('getVerificationRequiredOC');
        this.verificationRequired = verificationRequired;
    }

    oktaLogin(access_token, oktaUser) {
        return new Promise(async (resolve, reject) => {
            let provider = {
                authenticate(options) {
                    if (options.success) {
                        options.success(this, {});
                    }
                },
              
                restoreAuthentication(authData) {},
              
                getAuthType() {
                    return 'okta';
                },
              
                deauthenticate() {}
            };
            try {
                let authData = {
                    authData: {
                        access_token,
                        id: oktaUser, // key checked by parse
                    }
                };
                let user = await Parse.User.logInWith(provider, authData);
                if (!user.get('email_verified')) await Parse.User.become(user.getSessionToken());
                this.currentUser = user;
                this.authenticated = !!user;
                await this.setVerificationRequired();
                if (this.checkSendVerificationEmail(user)) parseUser.resendVerificationEmail(null, true);

                if(!!user) {
                    if(!user.get('stripeId')) {
                        await stripe.createStripeCustomer();
                        user = await user.fetch();
                        this.currentUser = user;
                    }
                    stripe.setStripeId(user.get('stripeId'));
                }
                resolve(user);
            } catch(error) {
                console.error(error.message);
                reject(error);
            }
        });
    }

    login(username, password) {
        return new Promise( async (resolve, reject) => {
            try {
                this.authenticated = false;
                this.currentUser = null;
                this.error = null;

                if(!username || !password) throw new Error('Username and password fields required');
                let user = await Parse.User.logIn(username.toLowerCase(), password);
                if (!user.get('email_verified')) await Parse.User.become(user.getSessionToken());

                this.currentUser = user;
                this.authenticated = !!user;
                await this.setVerificationRequired();
                if (this.checkSendVerificationEmail(user)) parseUser.resendVerificationEmail(null, true);

                if(!!user) {
                    if(!user.get('stripeId')) {
                        await stripe.createStripeCustomer();
                        user = await user.fetch();
                        this.currentUser = user;
                    }
                    stripe.setStripeId(user.get('stripeId'));
                }
                resolve(user);
            } catch(error) {
                this.error = error.message;
                console.error(error.message);
                reject(error);
            }
        });
    }

    loginSession(sessionToken, params) {
        return new Promise( async (resolve, reject) => {
            try {
                if(!sessionToken) throw new Error('Session token required');
                this.authenticated = false;
                this.currentUser = null;
                let user = (!!Parse.User.current())? Parse.User.current() : await Parse.User.become(sessionToken);
                if (!user.get('email_verified')) await Parse.User.become(user.getSessionToken());
                this.currentUser = user;
                this.authenticated = !!user;
                await this.setVerificationRequired();
                if (this.checkSendVerificationEmail(user)) parseUser.resendVerificationEmail(null, true);

                setCookie('sessionLogin', JSON.stringify(params), 1);

                if(!!user) {
                    if(!user.get('stripeId')) {
                        await stripe.createStripeCustomer();
                        user = await user.fetch();
                        this.currentUser = user;
                    }
                    stripe.setStripeId(user.get('stripeId'));
                }
                resolve(user);
            } catch(error) {
                console.error(error.message);
                reject(error);
            }
        });
    }

    logout() {
        return new Promise( async (resolve, reject) => {
            try {
                await Parse.User.logOut();
                this.authenticated = false;
                this.currentUser = null;
                stripe.clearStripeData();
                resolve('Logout success');
            } catch(error) {
                Notifications.addNotification('Error', 'Error logging out user. Please try again', 'error');
                this.error = error.message;
                console.error(error.message);
                reject(error);
            }
        });
    }

    /**
     * 
     * @param {firstname, lastname, email, password, company} userParams 
     * @param {} otherParams 
     */
    signUp(userParams, otherParams) {
        return new Promise(async (resolve, reject) => {
            try {
                await this.setVerificationRequired();
                await Cloud.run('signUp', {userParams, otherParams});
                // Send verification email in login if necessary
                await this.login(userParams.email.toLowerCase(), userParams.password);
                resolve({ verificationRequired: this.verificationRequired });
            } catch(error) {
                console.error(error.message);
                reject(error);
            }
        });
    }

    checkLogin(callBack) {
        let user = Parse.User.current();
        if(!!user) {
            this.authenticated = true;
            this.currentUser = user;
            stripe.setStripeId(user.get('stripeId'));
        }
        callBack();
    }

    checkSendVerificationEmail(user) {
        if (!this.verificationRequired || !user) return false;
        if (user.get('email_verified')) return false;
        const currentVerificationData = user.get('currentVerificationData') || {};
        const lastEmailSentAt = currentVerificationData.lastEmailSentAt || 0;
        const MIN_MILLISECONDS_BETWEEN_RESENDS = 60 * 60 * 24 * 7 * 1000; // one week
        const doSendEmail = (Date.now() - lastEmailSentAt) > MIN_MILLISECONDS_BETWEEN_RESENDS;
        return doSendEmail;
    }

    isAuthenticated() {
        if(!!Parse.User.current()){
            this.authenticated = true;
            this.currentUser = Parse.User.current();
        }
        return this.authenticated;
    }

    isVerified() {
        let user = Parse.User.current();
        return user && user.get('email_verified');
    }

    isVerificationRequired() {
        return this.verificationRequired;
    }

    isReseller() {
        return new Promise( async(resolve, reject) => {
            try {
                let res = await Cloud.run('isReseller');
                if(!res) {
                    Notifications.addNotificationJS(
                        'Page Unavailable',
                        'You do not have access to this page. If you are interested in being a Virbela Sales Affiliate you can contact affiliates@virbela.com',
                        'info'
                    );
                }
                resolve(res);
            } catch(error) {
                console.error(error.message);
                reject(error);
            }
        });
    }

    getCurrentUser() {
        if(!this.currentUser) return Parse.User.current();
        return this.currentUser;
    }

    setCurrentUser(user) {
        this.currentUser = user;
    }

    isSessionLogin() {
        let sessionData = JSON.parse(getCookie('sessionLogin') || '{}');
        return sessionData.sessionLogin;
    }

    getSessionCookie() {
        let sessionData = JSON.parse(getCookie('sessionLogin') || '{}');
        return sessionData;
    }
}

function setCookie(cname, cvalue, exdays) {
    let d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

export default new Auth();