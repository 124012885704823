import React from 'react';
import { Container } from 'reactstrap';
import Stripe from './../actions/stripe';
import Auth from './../actions/auth';
import Loading from './../component/loading';
import Error from './../component/error';
import { Route, Switch } from 'react-router-dom';
import Product from './../component/product';
import Header from './../component/header';

export default class Products extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: '',
            customer: undefined,
            dCard: null
        }
    }

    async componentDidMount() {
        this._isMounted = true;

        try {
            if(Auth.isAuthenticated()) {
                let customer = await Stripe.getSubscriptions();
                if(!this._isMounted) return;
                this.setState({isLoading: false, customer, dCard: customer.sources.data[0]});
            } else {
                this.setState({isLoading: false, customer: null});
            }
        } catch(error) {
            if(!this._isMounted) return;
            this.setState({isLoading: false, error: error.message});
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    updateCard = (card) => {
        this.setState({dCard: card['card']});
    }

    renderProducts = () => {
        if(this.state.isLoading) {
            return <Loading />
        } else if(!!this.state.error) {
            return <Error message={this.state.error} />
        } else {
            return (
                <Switch>
                    <Route path='/products/:id' render={(props) => <Product key={props.match.params.id} {...props} customer={this.state.customer} dCard={this.state.dCard} addCardSuccess={this.updateCard}/>} />
                </Switch>
            );
        }
    }

    render() {
        return (
            <React.Fragment>
                <Header title=' '/>
                <Container className='minHeightHeader'>
                    {this.renderProducts()}
                </Container>
            </React.Fragment>
        );
    }
}