import React, {useState, useEffect} from 'react';
import Loading from './loading';
import Error from './error';
import Auth from './../actions/auth';
import { Row, Col } from 'reactstrap';

export default function SessionLogin(props) {
    const [state, setState] = useState({isLoading: true, error: ''});
    let sessionToken = props.match.params.sessionToken;

    useEffect(() => {
        async function login(sessTok) {
            try {
                let URLParams = new URLSearchParams(window.location.search);
                setState({...state, isLoading: true});
                await Auth.loginSession(sessTok, {client: URLParams.get('client'), sessionLogin: true});
                setState({isLoading: false, error: ''});
                props.history.push(URLParams.get('path') || '/profile/subscriptions');
            } catch(error) {
                setState({isLoading: false, error: error.message});
            }
        }
        login(sessionToken);
    }, [sessionToken]);

    function Render() {
        if(state.isLoading) {
            return <div>
                <Loading />
            </div>
        } else if(!!state.error) {
            return <div>
                <Error message={state.error} />
            </div>
        } else {
            return <div>redirecting...</div>
        }
    }

    return (
        <div className='minHeightNoHeader pageDefaultBg'>
            <div className='pageCenter'>
                <Row>
                    <Col xs={12} md={{offset: 2, size: 8}} lg={{offset: 3, size: 6}}>
                        <Render />
                    </Col>
                </Row>
            </div>
        </div>
    );
}