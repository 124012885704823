import Parse, { Cloud } from 'parse';
import auth from './auth';
import Notifications from './../actions/notifications';

class Stripe {
    constructor() {
        this.stripeId = null;
    }

    createStripeCustomer = () => {
        return new Promise(async (resolve, reject) => {
            try {
                let customer = await Cloud.run('createCustomer');
                if(!!customer) {
                    this.stripeId = customer.id;
                    this.stripeCustomer = customer;
                    let user = await auth.getCurrentUser().save({stripeId: customer.id});
                    resolve(customer);
                    if(!!window.$FPROM) {
                        window.$FPROM.trackSignup({
                            email: user.get('email'),
                            uid: customer.id},
                            function(){console.log('Successful sign up')
                        });
                    }
                } else {
                    throw new Error('Failed to create new customer');
                }
            } catch(error) {
                Notifications.addNotification('Error', error.message, 'error');
                reject(error);
            }
        });
    }

    async updateCustomer(fields, callBack, successMessage ='Successfully updated!' ) {
        let customer = await Cloud.run('updateCustomer', {customerId: this.stripeId, fields: fields}).catch( err => {
            console.error(err.message);
            Notifications.addNotification('Error', 'Failed to update. Please try again.', 'error');
        });
        if(!!customer) {
            Notifications.addNotification('Success', successMessage, 'success');
        }
        callBack(customer);
    }

    updateSubscription(fields, subId, successMessage = 'Successfully updated!') {
        return new Promise(async (resolve, reject) => {
            try {
                let subscription = await Cloud.run('updateSubscription', {subId: subId, fields: fields});
                Notifications.addNotification('Success', successMessage, 'success');
                resolve(subscription);
            } catch(error) {
                Notifications.addNotification('Error', 'Failed to update. Please try again. ' + error.message, 'error');
                reject(error)
            }
        });
    }

    async getPaymentMethods(callBack) {
        let error = null;
        let customer = await Cloud.run('getPaymentMethods', {customerId: this.stripeId}).catch( err => {
            console.error(err.message);
            error = err.message;
            if(error === 'Invalid session token') {
                window.location.href = '/logout';
            }
        });
        if(!!customer && customer.deleted) {
            console.error('Customer has been deleted.');
            error = 'Customer has been deleted. Please contact support for assistance.';
        }
        callBack(customer, error);
    }

    addPaymentMethod(tokenId) {
        return new Promise(async (resolve, reject) => {
            try {
                let card = await Cloud.run('addPaymentMethod', {customerId: this.stripeId, source: tokenId});
                resolve(card);
            } catch(error) {
                Notifications.addNotification('Error', error.message, 'error');
                reject(error);
            }
        });
    }

    getUpcomingPayments() {
        return new Promise( async (resolve, reject) => {
            try {
                let upcomingInvoices = await Cloud.run('getUpcomingPayments', {customerId: this.stripeId});
                upcomingInvoices = upcomingInvoices.sort(function(invoiceA, invoiceB) {
                    return invoiceA.created - invoiceB.created;    
                });
                resolve(upcomingInvoices);
            } catch(error) {
                console.error(error.message);
                reject(new Error(error.message));
            }
        });
    }
    getPaymentHistory( last=undefined, first = undefined) {
        return new Promise( async (resolve, reject) => {
            try {
                let pastInvoices = await Cloud.run('getPaymentHistory', {customerId: this.stripeId,starting_after:last, ending_before: first})
                resolve(pastInvoices);
            } catch(error) {
                console.error(error.message);
                reject(new Error(error.message));
            }
        });
    }

    getSubscriptions() {
        return new Promise( async (resolve, reject) => {
            try {
                let customer = await Cloud.run('getCustomerSubscriptions', {customerId: this.stripeId});
                resolve(customer);
            } catch(error) {
                console.error(error.message);
                if(error.message && error.message.toLowerCase().includes('session token')) {
                    window.location.href = '/logout';
                }
                reject(new Error('Customer has been deleted. Please contact support for assistance.'));
            }
        });
    }

    async getSubscriptions2(callBack) { //TODO: update files that use this to use promised based getSubscriptons ^
        let error = null;
        let customer = await Cloud.run('getCustomerSubscriptions', {customerId: this.stripeId}).catch( err => {
            console.error(err.message);
            error = err.message;

            if(error && error.toLowerCase().includes('session token')) {
                window.location.href = '/logout';
            }
        });
        if(!!customer && customer.deleted) {
            console.error('Customer has been deleted.');
            error = 'Customer has been deleted. Please contact support for assistance.';
        }
        callBack(customer, error);
    }

    getProducts(options = {}) {
        return new Promise( async (resolve, reject) => {
            try {
                let products = await Cloud.run('getProducts', {options});
                resolve(products);
            } catch(error) {
                console.error(error.message);
                reject(error);
            }
        });
    }

    getClientProducts(client, options = {}) {
        return new Promise( async (resolve, reject) => {
            try {
                let products = await Cloud.run('getClientProducts', {client, options});
                resolve(products);
            } catch(error) {
                reject(error);
            }
        });
    }

    getSubscription(subscriptionId) {
        return new Promise( async (resolve, reject) => {
            try {
                let subscription = await Cloud.run('getSubscription', { subscriptionId });
                if (!subscription) throw new Error('No subscription found');
                resolve(subscription);
            } catch(error) {
                console.error(error.message);
                reject(error);
            }
        });
    }

    getPlans(options = {}) {
        return new Promise( async (resolve, reject) => {
            try {
                let products = await Cloud.run('getPlans', {options});
                resolve(products);
            } catch(error) {
                console.error(error.message);
                reject(error);
            }
        });
    }

    purchasePlan(plan, accountId, coupon = null, resellerCoupon = null, card = null, quantity = 1) {
        return new Promise( async (resolve, reject) => {
            try {
                let purchase = await Cloud.run('purchasePlan', {customerId: this.stripeId, plan: plan.id, accountId, coupon, resellerCoupon, card, productId: plan.product, quantity, addons: plan.metadata.addons});
                resolve(purchase);
            } catch(error) {
                console.error(error.message);
                Notifications.addNotification('Error', error.message, 'error');
                reject(error);
            }
        });
    }

    createAndPurchaseInvoice({price, coupon = null, card = null, quantity = 1, data}) {
        return new Promise( async (resolve, reject) => {
            try {
                if(!price || !card) throw new Error('Missing parameters');
                let purchase = await Cloud.run('createAndPurchaseInvoice', {customerId: this.stripeId, priceId: price.id, coupon, cardId: card, quantity, data});
                resolve(purchase);
            } catch(error) {
                console.error(error.message);
                Notifications.addNotification('Error', error.message, 'error');
                reject(error);
            }
        });
    }

    async updatePlan(subscriptionId, newPlanId, prorationDate) {
        let error = null;
        let subscription = {};
        try {
            subscription = await Cloud.run('updatePlan', { subscriptionId, newPlanId, prorationDate });
            Notifications.addNotification('Success', 'Successfully updated subscription plan. It may take up to one day to upgrade.', 'success');
        } catch(err) {
            error = err.message;
            Notifications.addNotification('Error', err.message, 'error');
        }
        return { subscription, error };
    }

    async previewProration(subscriptionId, oldPlanId, newPlanId) {
        let prorationData = await Cloud.run('previewProration', { subscriptionId, oldPlanId, newPlanId, customerId: this.stripeId }).catch( err => {
            console.error(err.message);
            Notifications.addNotification('Error', err.message, 'error');
        });
        return prorationData;
    }

    cancelSubscription = (subscriptionId) => {
        return new Promise(async (resolve, reject) => {
            try {
                let subscription = await Cloud.run('cancelSubscription', {subscriptionId: subscriptionId});
                Notifications.addNotification('Success', 'Successfully canceled subscription! Your default payment source will no longer be charged.', 'success');
                resolve(subscription);
            } catch(error) {
                console.error(error.message);
                Notifications.addNotification('Error', error.message, 'error');
                reject(error);
            }
        });
    }

    async removePaymentMethod(paymentId, callBack) {
        let card = await Cloud.run('removePaymentMethod', {customerId: this.stripeId, paymentId: paymentId}).catch( err => {
            console.error(err.message);
            Notifications.addNotification('Error', 'Failed to remove card', 'error');
        });
        if(!!card) {
            Notifications.addNotification('Success', 'Successfully removed card!', 'success');
        }
        callBack()
    }

    getSubInvoice(invoiceId) {
        return new Promise(async (resolve, reject) => {
            try {
                let invoice = await Cloud.run('getSubInvoice', {invoiceId: invoiceId});
                resolve(invoice);
            } catch(error) {
                console.error(error.message);
                reject(error);
            }
        });
    }

    paySubInvoice(invoiceId, card) {
        return new Promise(async (resolve, reject) => {
            try {
                let invoice = await Cloud.run('paySubInvoice', {invoiceId: invoiceId, cardId: card});
                Notifications.addNotification('Success', 'Invoice successfully paid! Check your subscription details for instructions to access your room(s).', 'success');
                resolve(invoice);
            } catch(error) {
                console.error(error.message);
                Notifications.addNotification('Error', error.message, 'error');
                reject(error);
            } 
        });
    }

    fetchOAuthToken(code) {
        return new Promise(async (resolve, reject) => {
            try {
                let res = await Cloud.run('fetchOAuthToken', {code});
                resolve(res);
            } catch(error) {
                console.error(error.message);
                reject(error);
            }
        });
    }

    getStripeCoupon(couponId) {
        return new Promise(async (resolve, reject) => {
            try {
                let coupon = await Cloud.run('getStripeCoupon', {couponId});
                resolve(coupon);
            } catch(error) {
                console.error(error.message);
                reject(error);
            }
        });
    }

    createStripeCoupon() {
        return new Promise(async (resolve, reject) => {
            try {
                let coupon = await Cloud.run('createStripeCoupon');
                resolve(coupon);
            } catch(error) {
                console.error(error.message);
                reject(error);
            }
        })
    }

    verifyStripeCoupon(couponId, plan) {
        return new Promise(async (resolve, reject) => {
            try {
                let {coupon, resellerCoupon} = await Cloud.run('verifyStripeCoupon', {couponId, plan});
                if(!coupon) {
                    throw new Error('Coupon not found');
                } 
                resolve({coupon, resellerCoupon});
            } catch(error) {
                console.error(error.message);
                Notifications.addNotification('Error', error.message, 'error');
                reject(error);
            }
        });
    }

    getStripeId() {
        if(this.stripeId === null) {
            return Parse.User.current().get('stripeId');
        }
        return this.stripeId;
    }

    setStripeId(id) {
        this.stripeId = id;
    }

    clearStripeData() {
        this.stripeId = null;
    }

    
    verifyAddress(address) {
        return new Promise(async (resolve, reject) => {
            try {
                let result = await Cloud.run('verifyAddress', {address});
                resolve(result);
            } catch(error) {
                console.error(error.message);
                reject(error);
            }
        });
    }
}

export default new Stripe();

export function checkReferralDiscount(plan) {
    return new Promise(async (resolve, reject) => {
        try {
            let coupon = await Cloud.run('checkReferralDiscount', {plan});
            resolve(coupon);
        } catch(error) {
            reject(error);
        }
    });
}

export function getSortedPlans(options = {}) {
    return new Promise( async (resolve, reject) => {
        try {
            let planData = await Cloud.run('getSortedPlans', {options});
            resolve(planData);
        } catch(error) {
            console.error(error.message);
            reject(error);
        }
    });
}

export function calculatePrice(plan, coupon, quantity = 1) {
    function calcVolumePrice(volArr, quantity) {
        for(const volume of volArr) {
            if(volume.up_to === null) return quantity*volume.unit_amount_decimal;
            if(quantity <= volume.up_to) {
                if(!!volume.flat_amount) return volume.flat_amount;
                else return quantity*volume.unit_amount_decimal;
            }
        }
    }
    function calcGraduatedPrice(gradArr, quantity) {
        let pricing = 0;
        let lowerbound = 0;
        for(const tier of gradArr) {
            if(lowerbound === 0) {
                pricing = pricing + tier.flat_amount;
                if(quantity <= tier.up_to) return pricing;
            } else if(!tier.up_to) {
                pricing = pricing + ((quantity - lowerbound)*tier.unit_amount);
                return pricing;
            } else if(quantity <= tier.up_to) {
                pricing = pricing + ((quantity - lowerbound)*tier.unit_amount);
                return pricing;
            } else {
                pricing = pricing + ((tier.up_to - lowerbound)*tier.unit_amount);
            }
            lowerbound = tier.up_to;
        }
    }
    let price = 0;
    switch(plan.tiers_mode) {
        case 'volume':
            price = calcVolumePrice(plan.tiers, quantity);
            break;
        case 'graduated':
            price = calcGraduatedPrice(plan.tiers, quantity);
            break;
        default:
            price = (plan.unit_amount || plan.amount || 0) * quantity;
            break;
    }
    if(!!coupon) {
        if(!!coupon.percent_off) {
            price = price * (1-(coupon.percent_off/100));
        } else if(!!coupon.amount_off){
            price = (price - coupon.amount_off);
        }
    }
    return price/100;
}

export function getAddonFees(plan) {
    return new Promise(async (resolve, reject) => {
        try {
            if(!plan) throw new Error('Missing plan');
            let pricing = await Cloud.run('getAddonFees', {
                plan
            });
            resolve(pricing);
        } catch(error) {
            reject(error.message);
        }
    });
}

export async function getTaxAmount({plan, cardId, coupon, quantity = 1}) {
    try {
        if(!plan) throw new Error('Missing plan');
        const tax = await Cloud.run('getTax', {
            plan, cardId, coupon, quantity
        });
        return tax;
    } catch(error) {
        throw error.message
    }
}