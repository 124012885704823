const FORM_REQUIREMENT_REGEX = {
  MIN_LENGTH_OF_8: /.{8,}/,
  ONE_LOWERCASE_LETTER: /[a-z]/,
  ONE_UPPERCASE_LETTER: /[A-Z]/,
  ONE_NUMBER: /[0-9]/,
  ONE_SPECIAL_CHARACTER: /[#?!@$%^&*-]/,
  NO_SPACES: /^(?!.*[\s])/
};

const PASSWORD_SPECIAL_CHARS = '#?!@$%^&*-';

export { FORM_REQUIREMENT_REGEX, PASSWORD_SPECIAL_CHARS };
