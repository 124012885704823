import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import { DropdownItem } from 'reactstrap';
import Auth from './../actions/auth';

export default withAuth(class OktaLoginButton extends Component {
  constructor(props) {
    super(props);
    this.state = { authenticated: null };
    this.checkAuthentication = this.checkAuthentication.bind(this);
    this.checkAuthentication();
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  async checkAuthentication() {
    const authenticated = await this.props.auth.isAuthenticated();
    if (authenticated !== this.state.authenticated) {
      this.setState({ authenticated });
    }
  }

  componentDidUpdate() {
    this.checkAuthentication();
  }

  async login() {
    let date = new Date();
    date.setMinutes(date.getMinutes() + 5);
    document.cookie = 'redirectData=' + JSON.stringify({
      prevPage: this.props.location.prevPage,
      data: this.props.location.data
    }) + '; expires=' + date.toUTCString() + ';path=/;';
    this.props.auth.login('/oktaAuth');
  }

  async logout() {
    try {
      await Auth.logout();
      if(this.state.authenticated) {
        if(!!this.props.toggle) this.props.toggle();
        this.props.auth.logout('/');
      } else {
        window.location.href = '/';
      }
    } catch(error) {
      console.error(error.message);
    }
  }

  render() {
    if (this.state.authenticated === null) return null;
    if(this.props.dropdown) {
      return (this.state.authenticated || Auth.isAuthenticated()) ?
      <DropdownItem onClick={this.logout} style={{...this.props.style}}>Logout</DropdownItem> :
      <DropdownItem onClick={this.login} style={{...this.props.style}}>{this.props.children || 'Passport'}</DropdownItem>;
    } else {
      return (this.state.authenticated || Auth.isAuthenticated()) ?
      <button className={this.props.className || 'bttn primary'} onClick={this.logout} style={{...this.props.style}}>Logout</button> :
      <button className={this.props.className || 'bttn primary'} onClick={this.login} style={{...this.props.style}}>{this.props.children || 'Login with Passport'}</button>;
    }
  }
});