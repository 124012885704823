import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, Row, Col, Form, FormGroup, FormFeedback, Label, Input } from 'reactstrap';
import { IoIosAdd, IoIosClose } from 'react-icons/io';
import Timezones from './../constants/Timezones';
import Error from './error';
const yup = require('yup');

export default function SupportForm({onSubmit}) {
    const storedSupportTypes = sessionStorage.getItem('supportForm-supportTypes');
    const [formData, setForm] = useState({
        ...formFields, 
        company: localStorage.getItem('supportForm-company') || '',
        contact: localStorage.getItem('supportForm-contact') || '',
        eventName: sessionStorage.getItem('supportForm-eventName') || '',
        eventLocation: sessionStorage.getItem('supportForm-eventLocation') || '',
        timezone: localStorage.getItem('supportForm-timezone') || '',
        attendees: sessionStorage.getItem('supportForm-attendees') || 0,
        supportTypes: (!!storedSupportTypes && JSON.parse(storedSupportTypes)) || SupportTypes,
        moreInfo: sessionStorage.getItem('supportForm-moreInfo') || ''
    });
    const [{isLoading, error}, setLoading] = useState({isLoading: false, error: ''});

    async function submitForm(e) {
        try {
            if(isLoading) return;
            e.preventDefault();
            setLoading(state => ({...state, isLoading: true, error: ''}));
            let data = await schema.validate({
                ...formData, attendees: (formData.attendees || 0),
            });
            data.eventDates = JSON.stringify(data.eventDates.sort(sortDateTimes)).replace(/"/g, '');
            data.supportTypes = (() => {
                let types = Object.keys(data.supportTypes).filter(k => {
                    return data.supportTypes[k];
                });
                return types.join(', ');
            })();
            onSubmit(data);
            setLoading(state => ({...state, isLoading: false, error: ''}));
        } catch(error) {
            setLoading(state => ({...state, isLoading: false, error: error.message}));
        }
    }

    function textInputChange(e) {
        let { id, value } = e.target;
        setForm(state => ({...state, [id]: value}));
        (SessionStorageList.includes(id))? sessionStorage.setItem(`supportForm-${id}`, value) : localStorage.setItem(`supportForm-${id}`, value);
    }

    function datePickerUpdate(dates) {
        setForm(form => ({...form, eventDates: dates}));
    }

    function onSupportTypeChange(e) {
        let { checked, name } = e.target;
        setForm(form => {
            let newVals = {...form, supportTypes: {...form.supportTypes, [name]: checked}};
            sessionStorage.setItem('supportForm-supportTypes', JSON.stringify(newVals.supportTypes));
            return newVals;
        });
        
    }

    return <Form onSubmit={submitForm} id='support-form'>
        {(!!error) && <Error assist={false}>{error}</Error>}
        <Row>
            <Col>
                <FormGroup>
                    <Label for='company'>Company</Label>
                    <Input type='text' name='company' id='company' placeholder='Company..' onChange={textInputChange} value={formData.company}/>
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <Label for='contact'>Company Contact</Label>
                    <Input type='text' name='contact' id='contact' placeholder='Company Contact..' onChange={textInputChange} value={formData.contact}/>
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col>
                <FormGroup>
                    <Label for='eventName'>Event Name</Label>
                    <Input type='text' name='eventName' id='eventName' placeholder='Event Name..' onChange={textInputChange} value={formData.eventName}/>
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <Label for='eventLocation'>Event Location</Label>
                    <Input type='text' name='eventLocation' id='eventLocation' placeholder='Event Location..' onChange={textInputChange} value={formData.eventLocation}/>
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col>
                <FormGroup>
                    <Label for='timezone'>Timezone</Label>
                    <Input type='select' name='timezone-select' id='timezone' onChange={textInputChange} value={formData.timezone}>
                        <option hidden value={''}>Select a timezone..</option>
                        {Timezones.map((tz) => <option key={`timezone-${tz.name}`} value={tz.name}>{tz.name}</option>)}
                    </Input>
                </FormGroup>
            </Col>
        </Row>
        <DateTimePicker onUpdate={datePickerUpdate} />
        <Row>
            <Col>
                <FormGroup>
                    <Label for='attendees'>Number of Attendees</Label>
                    <Input type='number' name='attendees' id='attendees' placeholder='Number of Attendees' onChange={textInputChange} value={formData.attendees} min='0' max='5000000'/>
                </FormGroup>
            </Col>
        </Row>
        <Label>Type of Support Needed</Label>
        <Row style={{marginBottom: '10px'}}>
            <Col xs='6' md='4'>
                <FormGroup check inline>
                    <Input id='type-training' type='checkbox' onChange={onSupportTypeChange} name='training' checked={formData.supportTypes.training}/>
                    <Label for='type-training' check>
                    Training
                    </Label>
                </FormGroup>
            </Col>
            <Col xs='6' md='4'>
                <FormGroup check inline>
                    <Input id='type-support' type='checkbox' onChange={onSupportTypeChange} name='eventSupport' checked={formData.supportTypes.eventSupport}/>
                    <Label for='type-support' check>
                    Event Support
                    </Label>
                </FormGroup>
            </Col>
            <Col xs='6' md='4'>
                <FormGroup check inline>
                    <Input id='type-greeter' type='checkbox' onChange={onSupportTypeChange} name='greeter' checked={formData.supportTypes.greeter}/>
                    <Label for='type-greeter' check>
                    Greeter
                    </Label>
                </FormGroup>
            </Col>
            <Col xs='6' md='4'>
                <FormGroup check inline>
                    <Input id='type-other' type='checkbox' onChange={onSupportTypeChange} name='other' checked={formData.supportTypes.other}/>
                    <Label for='type-other' check>
                    Other
                    </Label>
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col>
                <FormGroup>
                    <Input type='textarea' name='moreInfo' id='moreInfo' placeholder='Additional Information, Special Requests, Event Website or Agenda' onChange={textInputChange} value={formData.moreInfo} maxLength='450'/>
                </FormGroup>
            </Col>
        </Row>
    </Form>
}

export function SupportFormModalBtn({onSubmit, closeModalOnSuccess = true, className, data}) {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    function _onSubmit(data) {
        if(closeModalOnSuccess) toggle();
        onSubmit(data);
    }

    return <>
        <button className={className} onClick={toggle}>Get Started</button>
        <Modal isOpen={modal} toggle={toggle} scrollable>
            <ModalBody style={{maxHeight: '800px'}}>
                <SupportForm onSubmit={_onSubmit} data={data}/>
            </ModalBody>
            <ModalFooter>
                <button className='bttn' onClick={toggle}>Cancel</button>
                <button className='bttn primary' type='submit' form='support-form'>Next</button>
            </ModalFooter>
        </Modal>
    </>
    
}

function DateTimePicker({onUpdate = () => []}) {
    const storedDates = sessionStorage.getItem('supportForm-dates');
    const [dates, setDates] = useState((!!storedDates && JSON.parse(storedDates)) || [{date: getFutureDate(7), sTime: '12:00', eTime: '13:00'}]);
    useEffect(() =>  {
        onUpdate(dates);
    },[dates]);

    function addDateTime(e) {
        e.preventDefault();
        if(dates.length >= 10) return;
        setDates(currDates => {
            let vals = [...currDates].sort(sortDateTimes);
            vals.push({...vals[vals.length-1]});
            sessionStorage.setItem('supportForm-dates', JSON.stringify(vals));
            return vals;
        });
    }

    function remove(e, index) {
        setDates(currDates => {
            if(currDates.length  <= 1) return currDates;
            let datesArr = [...currDates];
            datesArr.splice(index, 1);
            sessionStorage.setItem('supportForm-dates', JSON.stringify(datesArr));
            return datesArr;
        });
    }

    function DateTime(dateTime, index) {
        function onChange(e) {
            let { id, value } = e.target;
            setDates(currDates => {
                let currVals = [...currDates];
                let key = ['date', 'sTime', 'eTime'].find(e => id.includes(e));
                currVals[index][key] = value;
                sessionStorage.setItem('supportForm-dates', JSON.stringify(currVals));
                return currVals
            });
            
        }
        let { date, sTime, eTime } = dateTime;
        let nameIdx = (!!index)? index+1 : '';
        let isInvalid = (new Date(date) < getFutureDate(6, 'date'));
        return <div key={`dateTimePicker-${index}`} style={{position: 'relative'}}>
            <div className='hover' id={`dateTime-Remove-${index}`} idx={index} style={{position: 'absolute', top: '5px', right: '5px', zIndex: '1000000000'}} onClick={(e) => remove(e,index)}><IoIosClose size='1.5em'/></div>
            <Row>
                <Col>
                    <FormGroup>
                        <Label for={`date-${index}`}>Date {nameIdx}</Label>
                        <Input
                        type='date'
                        name={`date-${index}`}
                        id={`date-${index}`}
                        placeholder='date placeholder'
                        onChange={onChange}
                        value={date}
                        valid={!isInvalid}
                        invalid={isInvalid}
                        onKeyDown={(e) => e.preventDefault()}
                        />
                        <FormFeedback>Date must be at least 7 days from today.</FormFeedback>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label for={`sTime-${index}`}>Start Time {nameIdx}</Label>
                        <Input
                        type='time'
                        name={`sTime-${index}`}
                        id={`sTime-${index}`}
                        placeholder='time placeholder'
                        onChange={onChange}
                        value={sTime}
                        invalid={!sTime}
                        onKeyDown={(e) => e.preventDefault()}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for={`eTime-${index}`}>End Time {nameIdx}</Label>
                        <Input
                        type='time'
                        name={`eTime-${index}`}
                        id={`eTime-${index}`}
                        placeholder='time placeholder'
                        onChange={onChange}
                        value={eTime}
                        invalid={!eTime}
                        onKeyDown={(e) => e.preventDefault()}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </div>
    }

    return <div>
        {dates.map((date, index) => DateTime(date, index))}
        <div className='hoverText' style={{textAlign: 'right'}}><span onClick={addDateTime}><IoIosAdd size='1.75em' style={{transform: 'translateY(-2px)'}}/><font size={3}><b>Add More</b></font></span></div>
    </div>
}

function sortDateTimes(a, b) {
    let dateTimeA = {sTime: new Date(`${a.date} ${a.sTime}`), eTime: new Date(`${a.date} ${a.eTime}`)};
    let dateTimeB = {sTime: new Date(`${b.date} ${b.sTime}`), eTime: new Date(`${b.date} ${b.eTime}`)};
    if(dateTimeA.sTime < dateTimeB.sTime) {
        return -1;
    }
    if(dateTimeA.sTime > dateTimeB.sTime) {
        return 1;
    }
    if(dateTimeA.eTime < dateTimeB.eTime) {
        return -1;
    }
    if(dateTimeA.eTime > dateTimeB.eTime) {
        return 1;
    }
    return 0;
}

function getFutureDate(addDays, returnType = 'string'){
    let today = new Date();
  	let nextDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()+addDays);
    if(returnType === 'date') {
        return nextDate;
    }
    let month = nextDate.getMonth()+1;
    let day = nextDate.getDate();
    return `${nextDate.getFullYear()}-${(month < 10)? ('0' + month) : month}-${(day < 10)? ('0' + day) : day}`;
}

const SupportTypes = {
    training: false,
    eventSupport: false,
    greeter: false,
    other: false,
};
const formFields = {
    company: '',
    contact: '',
    eventName: '',
    eventLocation: '',
    eventDates: [],
    timezone: '',
    attendees: 0,
    supportTypes: SupportTypes,
    moreInfo: '',
}
const SessionStorageList = ['eventName', 'eventLocation', 'attendees', 'moreInfo'];

const schema = yup.object().shape({
    moreInfo: yup.string().trim(),
    supportTypes: yup.object().test('valid support type', 'Must select at least one support type', (v) => (v.training || v.eventSupport || v.greeter || v.other)),
    attendees: yup.number('aa').moreThan(0, 'Please provide an estimate of the number of attendees').required('Please provide an estimate of the number of attendees'),
    timezone: yup.string().trim().required('Please select a timezone'),
    eventDates: yup.array().ensure().min(1, 'Please provide an event date').of(yup.object().shape({
        date: yup.string().required('Missing date').test('valid date', 'Date must be at least 7 days from today', (value) => (new Date(value) >= getFutureDate(6, 'date'))),
        sTime: yup.string().required('Missing start time'),
        eTime: yup.string().required('Missing end time'),
    })),
    eventLocation: yup.string().trim().required('Please provide the event location'),
    eventName: yup.string().trim().required('Please provide your event name'),
    contact: yup.string().trim().required('Please provide a contact name and email'),
    company: yup.string().trim().required('Please provide your company name'),
});
