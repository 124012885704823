/**
 *
 * @format
 */
/* eslint-disable */

import Parse from 'parse';
import * as AppConfig from './../constants/AppConfig';
class ParseQueries {
  getRoomSecuritiesWithStripeId(subscriptionId) {
    return new Promise(async (resolve, reject) => {
      try {
        const Subscription = Parse.Object.extend('Subscription');
        const RoomSecurity = Parse.Object.extend('RoomSecurity');

        let subscriptionQuery = new Parse.Query(Subscription);
        subscriptionQuery.equalTo('subscriptionId', subscriptionId);

        let rsQuery = new Parse.Query(RoomSecurity);
        rsQuery.include('account');
        rsQuery.include('subscription');
        rsQuery.ascending('name');
        rsQuery.matchesQuery('subscription', subscriptionQuery);
        let roomSecurities = await rsQuery.find();

        if (!!Array.isArray(roomSecurities) && roomSecurities.length > 0) {
          resolve(roomSecurities);
        } else {
          throw new Error(
            'Cannot find subscription details. If you just successfully purchased a subscription, your room(s) may still be generating. Please try again in a bit.'
          );
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  getMembers({ accountId, activePage, sort, filter }) {
    return Parse.Cloud.run('getTeamSuiteUserList', {
      accountId,
      options: {
        activePage,
        limit: AppConfig.default.Pagination,
        sort,
        filter,
      },
    });
  }

  getTotalMembersCount(account, roleConstraints = null) {
    return new Promise(async (resolve, reject) => {
      try {
        if (!account) {
          throw new Error('Cannot get members count without account object');
        }
        const Membership = Parse.Object.extend('Membership');
        let membershipQuery = new Parse.Query(Membership);
        membershipQuery.equalTo('account', account);
        if (!!roleConstraints) {
          membershipQuery.containedIn('permission', roleConstraints);
        }
        let membershipCount = await membershipQuery.count();
        resolve(membershipCount);
      } catch (error) {
        console.error(error.message);
        reject(error);
      }
    });
  }

  getStripeData() {
    return new Promise(async (resolve, reject) => {
      try {
        let user = Parse.User.current();
        if (!user) {
          throw new Error('No user. Please login');
        }

        const StripeRefs = Parse.Object.extend('StripeRefs');
        let stripeRefsQuery = new Parse.Query(StripeRefs);
        stripeRefsQuery.equalTo('user', user);
        let stripeRefs = await stripeRefsQuery.first();
        resolve(stripeRefs);
      } catch (error) {
        console.error(error.message);
        reject(error);
      }
    });
  }

  getAdminRoomSecurities() {
    return new Promise(async (resolve, reject) => {
      try {
        const RoomSecurity = Parse.Object.extend('RoomSecurity');
        let rsQuery = new Parse.Query(RoomSecurity);
        rsQuery.notEqualTo('subscriber', Parse.User.current());
        rsQuery.include('subscription');
        rsQuery.include('account');
        rsQuery.limit(100);
        let roomSecurities = await rsQuery.find();
        let validRs = roomSecurities.filter((rs) => {
          return rs.get('subscription').get('isValid');
        });
        resolve(validRs);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new ParseQueries();
