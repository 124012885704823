import React from 'react';
import { Alert, Container } from 'reactstrap';

export default class NotFound extends React.Component {

    render() {
        return (
            <Container className='minHeightNoHeader'>
                <br />
                <Alert color='danger'>
                    <Container>
                        <br />
                        <h1>Oops !</h1>
                        404 - Page not found
                        <hr />
                        <button className='bttn primary' onClick={() => {this.props.history.push('/')}}>Return home</button>
                        <br />
                        <p />
                    </Container>
                </Alert>
            </Container>
        );
    }
}