import React from 'react';
import EditAccount from './editAccount';

export default class AccountSettings extends React.Component {

    render() {
        return(
            <div>
                <div className='containerBg'>
                    <div style={{margin: '10px'}}>
                        <h3>Subscription Information</h3>
                    </div>
                    <EditAccount {...this.props} />
                </div>
                <hr />
            </div>
        );
    }
}