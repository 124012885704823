import React, {useState, useEffect, useRef} from 'react';
import { Container, Table, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, UncontrolledPopover, PopoverBody, Form, FormGroup, CustomInput, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {fetchInvitations, getInvitationCount, updateInviteOnly, resendInvitation, removeInvitation} from './../../actions/invitations';
import Loading from './../loading';
import Error from './../error';
import { IoMdCheckmark, IoMdClose, IoIosRefresh, IoIosArrowUp, IoIosArrowDown, IoMdOptions } from 'react-icons/io';
import { MdInfoOutline } from "react-icons/md";
import Pagination from 'rc-pagination';
import AppConfig from './../../constants/AppConfig';
import './../../styles/inviteList.css';

const EN = {
    // Options.jsx
    items_per_page: '/ page',
    jump_to: 'Go to ',
    page: '',

    // Pagination.jsx
    prev_page: 'Previous Page',
    next_page: 'Next Page',
    prev_5: 'Previous 5 Pages',
    next_5: 'Next 5 Pages',
    prev_3: 'Previous 3 Pages',
    next_3: 'Next 3 Pages',
}

export default class InviteList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            error: '',
            page: 0,
            sortField: 'updatedAt',
            descending: true,
            invitations: [],
            invCount: 0,
        }
    }

    async componentDidMount() {
        try {
            let { invitations, invCount } = await this.getInvitationAndCount(this.props.roomSecurity, 0);
            this.setState({isLoading: false, invitations, invCount});
        } catch(error) {
            this.setState({isLoading: false, error: error.message});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state !== prevState) {
            if(this.state.sortField !== prevState.sortField || this.state.descending !== prevState.descending) {
                this.getInvitationAndCount(this.props.roomSecurity);
            }
        }
        if(this.props !== prevProps) {
            if(this.props.roomSecurity !== prevProps.roomSecurity) {
                this.getInvitationAndCount(this.props.roomSecurity);
            }
        }
    }

    getInvitationAndCount = (roomSecurity, page = 0) => {
        return new Promise( async (resolve, reject) => {
            try {
                this.setState({isLoading: true, page});
                let [invitations, invCount] = await Promise.all([fetchInvitations(roomSecurity, page, {field: this.state.sortField, descending: this.state.descending}), getInvitationCount(roomSecurity)]);
                this.setState({isLoading: false, invitations, invCount});
                resolve({invitations, invCount});
            } catch(error) {
                this.setState({isLoading: false, error: error.message});
                reject(error);
            }
        })
    }

    refresh = async (e) => {
        if(this.state.isLoading) return;
        this.paginationChange(1);
    }

    refreshCurrentPage = () => {
        if(this.state.isLoading) return;
        this.paginationChange(this.state.page+1 || 1);
    }

    updateSorting = (e) => {
        e.preventDefault();
        if(e.target.id === this.state.sortField) {
            this.setState({descending: !this.state.descending});
        } else {
            this.setState({sortField: e.target.id});
        }
    }

    tableHeaderText(fieldName) {
        let caret = null;
        let dict = {'Email': 'email', 'User': 'existingUser', 'Date Sent': 'updatedAt', 'Status': 'status'}
        if(dict[fieldName] === this.state.sortField) {
            if(this.state.descending) {
                caret = (
                    <IoIosArrowDown />
                )
            } else {
                caret = (
                    <IoIosArrowUp />
                );
            }
        }
        return (
            <React.Fragment>
                {fieldName}{caret}
            </React.Fragment>
        );
    }

    paginationChange = async (pageNumber) => {
        await this.getInvitationAndCount(this.props.roomSecurity, pageNumber - 1);
    }

    renderInvitations = (invitations) => {
        if(this.state.isLoading) {
            return <tr><td colSpan={5}><Loading /></td></tr>
        } else if(!!this.state.error) {
            return <tr><td><Error message={this.state.error} /></td></tr>
        } else if(invitations.length <= 0){
            return <tr><td colSpan={5}><Error color='warning' message={'No invitations found'}/></td></tr>
        } else {
            return invitations.map((invitation, idx) => {
                return (
                    <tr key={'invRow_' + idx}>
                        <td className='left' width={'40px'}>{invitation.get('email')}</td>
                        <td className='center' >{(invitation.get('existingUser') === 'true')? <IoMdCheckmark color='green'/> : <IoMdClose color='red'/>}</td>
                        <td className='center'>{invitation.get('updatedAt').toLocaleDateString('en-US')}</td>
                        <td className='center'>
                            <span title={invitation.get('message')}>{invitation.get('message') === 'Failed to save invitation object' ? 'sent' : invitation.get('status')}</span>
                        </td>
                        <td className='center hover'>
                            <OptionsMenu roomSecurity={this.props.roomSecurity} invitation={invitation} reloadList={this.refreshCurrentPage}/>
                        </td>
                    </tr>
                );
            });
        }
    }

    render() {
        let { roomSecurity } = this.props;
        return (
            <Container fluid className='containerBg'>
                <Row>
                    <Col><h3><MdInfoOutline id='SentInvInfo' size='1em' className='shift-icon' /> Sent Invitations</h3></Col>
                    <Col className='right' style={{marginRight: '15px'}}>

                        <span className='hoverText' onClick={this.refresh}><IoIosRefresh size='1.75em'/></span>
                    </Col>
                </Row>
                <div><InviteOnlyToggle roomSecurity={roomSecurity} updateRoomSecurities={this.props.updateRoomSecurities}/></div>
                <div className='invite-list'>
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th className='left hover'><span id='email' onClick={this.updateSorting}>{this.tableHeaderText('Email')}</span></th>
                                <th className='center hover'><span id='existingUser' onClick={this.updateSorting}>{this.tableHeaderText('Existing User')}</span></th>
                                <th className='center hover'><span id='updatedAt' onClick={this.updateSorting}>{this.tableHeaderText('Date Sent')}</span></th>
                                <th className='center hover'><span id='status' onClick={this.updateSorting}>{this.tableHeaderText('Status')}</span></th>
                                <th className='center'><span id='actionsHeader'></span></th>
                            </tr>
                        </thead>
                        <tbody className='center'>
                            {this.renderInvitations(this.state.invitations)}
                        </tbody>
                    </Table>
                </div>
                <div className='center' style={{marginTop: '25px', padding: 0}}>
                    <Pagination simple locale={EN} showQuickJumper={{ goButton: <button>Go</button> }} defaultCurrent={this.state.page+1} current={this.state.page+1} defaultPageSize={AppConfig.Pagination} total={this.state.invCount} onChange={this.paginationChange}/>
                </div>
                <UncontrolledPopover trigger='hover click' placement='right' target='SentInvInfo' className='left'><PopoverBody><div>List of previously sent invitations.</div><div><b>Email:</b> Recipient's email address</div><div><b>Existing User:</b> Shows if recipient had a Virbela account at time of invite</div><div><b>Date Sent:</b> Date that the invite was last sent</div><div><b>Status:</b> Inivtation status, hover over for more detail</div><div><b>Resend:</b> Will resend the invite with updated information if Team Suite has been updated</div></PopoverBody></UncontrolledPopover>
            </Container>
        );
    }
}

function InviteOnlyToggle({roomSecurity, updateRoomSecurities}) {
    const [inviteOnly, setInviteOnly] = useState(!!roomSecurity.get('inviteOnly'));
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setInviteOnly(!!roomSecurity.get('inviteOnly'));
    }, [roomSecurity.id]);

    const didMount = useRef(false);
    useEffect(() => {
        async function updateRs(value) {
            if(didMount.current) {
                setLoading(true);
                let rs = await updateInviteOnly(value, roomSecurity.id);
                setLoading(false);
                updateRoomSecurities(rs);
            } else {
                didMount.current = true;
            }
        }
        updateRs(inviteOnly);
    }, [inviteOnly, setLoading, updateRoomSecurities, updateInviteOnly, roomSecurity.id]);
    
    function toggle(e) {
        setInviteOnly(e.target.checked);
    }

    return <CustomInput className='invite-only-toggle' type='switch' id='inviteOnlySwitch' name='inviteOnlySwitch' checked={!!inviteOnly} label='Limit Team Suite entry to emails below' onChange={toggle} disabled={loading}/>
}

function OptionsMenu({roomSecurity, invitation, reloadList}) {
    const [{showResendModal, passcodeIdx, isSending}, setResendModal] = useState({showResendModal: false, passcodeIdx: 0, isSending: false});

    function toggleResendModal(e) {
        setResendModal(state => ({...state, showResendModal: !state.showResendModal}));
    }
    function selectPasscode(e) {
        let value = parseInt(e.target.value);
        setResendModal(state => ({...state, passcodeIdx: value}));
    }
    async function _resendInvitation(e) {
        try {
            setResendModal(state => ({...state, isSending: true}));
            await resendInvitation(invitation.id, invitation.get('email'), roomSecurity.get('subscription').id, passcodeIdx);
            setResendModal(state => ({...state, isSending: false, showResendModal: false, passcodeIdx: 0}));
        } catch(error) {
            setResendModal(state => ({...state, isSending: false}));
        }
    }

    const [{showDeleteModal, isDeleting}, setDeleteModal] = useState({showDeleteModal: false, isDeleting: false});

    function toggleDeleteModal(e) {
        setDeleteModal(state => ({...state, showDeleteModal: !state.showDeleteModal}));
    }
    async function _removeInvitation(e) {
        try {
            setDeleteModal(state => ({...state, isDeleting: true}));
            await removeInvitation(invitation.id);
            setDeleteModal(state => ({...state, isDeleting: false}));
            reloadList();
        } catch(error) {
            setDeleteModal(state => ({...state, isDeleting: false}));
        }
    }

    let passcode1 = (Array.isArray(roomSecurity.get('passcodes')))? roomSecurity.get('passcodes')[0] : 'Cannot find passcode';
    let passcode2 = (Array.isArray(roomSecurity.get('passcodes')))? roomSecurity.get('passcodes')[1] : 'Cannot find passcode';
    return <div>
        <UncontrolledDropdown direction='left'>
            <DropdownToggle tag='a'><IoMdOptions size='1.25em'/></DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={toggleResendModal}>Resend</DropdownItem>
                <DropdownItem onClick={toggleDeleteModal}>Remove</DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
        <Modal isOpen={showResendModal} toggle={toggleResendModal}>
            <ModalHeader toggle={toggleResendModal}>Resend Confirmation</ModalHeader>
            <ModalBody>
                <div>Are you sure you want to resend an invitation to {(invitation)? invitation.get('email') : 'this person'}?</div>
                <br />
                <Form>
                    <FormGroup>
                        <div>
                            <p><font size={2}>Please select which passcode to share:</font></p>
                            <div>
                                <CustomInput type="radio" id="resendRadio1" name="resendRadio" value={0} label={passcode1} inline checked={passcodeIdx === 0} onChange={selectPasscode}/>
                                <CustomInput type="radio" id="resendRadio2" name="resendRadio" value={1} label={passcode2} inline checked={passcodeIdx === 1} onChange={selectPasscode} />
                            </div>
                        </div>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <button className='bttn' onClick={toggleResendModal}>Cancel</button>{' '}
                {(isSending)? <Spinner color='primary' /> : <button className='bttn primary' onClick={_resendInvitation}>Resend</button>}
            </ModalFooter>
        </Modal>
        <Modal isOpen={showDeleteModal} toggle={toggleDeleteModal}>
            <ModalHeader toggle={toggleDeleteModal}>Delete Invitation Confirmation</ModalHeader>
            <ModalBody>
                <div>Are you sure you want to delete this invitation to <b>{invitation.get('email')}</b>? The recipient will still have received the email. Deleting the invitation removes them from this list and only has an effect if the "Only allow invitees into Team Suite" toggle is active.</div>
            </ModalBody>
            <ModalFooter>
                <button className='bttn' onClick={toggleDeleteModal}>Cancel</button>{' '}
                {(isDeleting)? <Spinner color='primary' /> : <button className='bttn danger' onClick={_removeInvitation}>Remove</button>}
            </ModalFooter>
        </Modal>
    </div>
}