import React from 'react';
import stripe from './../actions/stripe';
import { Row, Col } from 'reactstrap';
import Loading from './loading';
import './subscriptions.css';
import Error from './error';
import Subscription from './subscription';
import { Link } from 'react-router-dom';
import Auth from './../actions/auth';
import AdminSubscriptions from './adminSubscriptions';

export default class Subscriptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subsLoading: true,
            subscriptions: null,
            subsError: null,
            dCard: null,
        }
    }

    componentDidMount() {
        this._isMounted = true;

        stripe.getSubscriptions2((customer, error) => {
            if(this._isMounted) {
                if(!!error) {
                    this.setState({subsError: error, subsLoading: false});
                } else {
                    this.setState({subscriptions: customer.subscriptions, subsError: error, subsLoading: false, dCard: customer.sources.data[0]});
                }
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getSubscriptions() {
        this.setState({subsLoading: true, error: null, subscriptions: null});
        stripe.getSubscriptions2((customer, error) => {
            if(this._isMounted) {
                if(!!error) {
                    this.setState({subsError: error, subsLoading: false});
                } else {
                    this.setState({subscriptions: customer.subscriptions, subsError: error, subsLoading: false, dCard: customer.sources.data[0]});
                }  
            }
        });
    }

    renderSubscriptions = () => {
        if(this.state.subsLoading) {
            return (
                <Col className='center' lg={{size: 8, offset: 2}}>
                    <Loading />
                </Col>
            );
        } else if(!!this.state.subsError) {
            return (
                <Col className='subAlert' lg={{size: 8, offset: 2}}>
                    <Error color='danger' message={this.state.subsError} />
                </Col>
            );
        } else if(!this.state.subscriptions.data.length) {
            return (
                <Col className='center subAlert' lg={{size: 8, offset: 2}}>
                    <Error color='warning'>You currently have no active subscriptions. <br /> Check out some {this.getClientText()} Team Suites!</Error>
                </Col>
            );
        } else {
            let subscriptions = this.state.subscriptions.data;
            return subscriptions.map( (subscription, index) => {
                if(!subscription.plan || (!!subscription.plan && (subscription.plan.metadata.ignore === 'true'))) return null;
                return (
                    <Col key={index} xs='12' md='6' lg='4'>
                        <Subscription history={this.props.history} subscription={subscription} dCard={this.state.dCard} getSubscriptions={() => this.getSubscriptions()} openDetails={(!!this.props.location.state && (subscription.id === this.props.location.state.subId))? true : false}/>
                    </Col>
                );
            });
        }
    }

    updateCardState = (card) => {
        this.setState({dCard: card});
    }

    render() {
        return (
            <div className='left'>
                <div className='containerBg'>
                    <Row>
                        <Col>
                            <h1>Active Subscriptions</h1>
                        </Col>
                    </Row>
                    <Row>
                        {this.renderSubscriptions()}
                    </Row>
                </div>
                <br />
                <hr />
                <br />
                <div className='containerBg'>
                    <Row>
                        <Col>
                            <h1>Admin On</h1>
                        </Col>
                    </Row>
                    <AdminSubscriptions />
                </div>
            </div>
        );
    }

    getClientText() {
        let sessionData = Auth.getSessionCookie();
        switch(sessionData.client) {
            case 'oc':
                return <Link to='/teamsuites'>Open Campus</Link>
            case 'exp':
                return <Link to='/expteamsuites'>eXp World</Link>
            default:
                return <span><Link to='/teamsuites'>Open Campus</Link> and <Link to='/expteamsuites'>eXp World</Link></span>
        }
    }

}