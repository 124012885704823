import React from 'react';
import InviteModule from './inviteModule';
import InviteList from './inviteList';
import Error from './../error';

export default class Invite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            error: ''
        }
    }

    render() {
        let roomSecurity = this.props.roomSecurity;
        if(!roomSecurity) {
            return <Error message='Missing Room Security Object' />
        } else if(!roomSecurity.get('account')) {
            return <Error color='warning' message='To use this feature please complete the form in the Account tab' />
        } else {
            return (
                <div>
                    <InviteModule roomSecurity={this.props.roomSecurity} />
                    <hr />
                    <InviteList roomSecurity={this.props.roomSecurity} updateRoomSecurities={this.props.updateRoomSecurities}/>
                </div>
            );
        }
    }
}