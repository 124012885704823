import React from 'react';
import stripe from './../actions/stripe';
import Subscriptions from './../component/subscriptions';
import PaymentMethods from './../component/paymentMethods';
import PaymentHistory from './../component/paymentHistory';
import { Row, Col, Container, Nav, NavItem, NavLink } from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import './profile.css';
import NotFound from './notFound';
import Header from './../component/header';

export default class Profile extends React.Component {

    constructor() {
        super();
        this.state = {
            fetchingCustomer: true,
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        try {
            if(!stripe.getStripeId()) { //create customer
                await stripe.createStripeCustomer();
            }
            if(!this._isMounted) return;
            this.setState({fetchingCustomer: false});
            if(this.props.location.pathname === '/profile' || this.props.location.pathname === '/profile/') {
                this.props.history.push('/profile/subscriptions');
            }
        } catch(error) {
            this.props.history.push('/');
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleTabs = (e) => {
        e.preventDefault(); 
        if(e.target.id === 'subscriptions') {
            this.props.history.push({pathname: '/profile/subscriptions', state: {noScroll: true}});
        } else if(e.target.id === 'payments') {
            this.props.history.push({pathname: '/profile/paymentMethods', state: {noScroll: true}})
        } else {
            this.props.history.push({pathname: '/profile/paymentHistory', state: {noScroll: true}})
        }
    }

    render() {
        if(this.state.fetchingCustomer) {
            return(
                <div className='minHeightNoHeader'>
                    <br />
                    Retrieving account data...
                    <br />
                </div>
            );
        } else {
            return (
                <div>
                    <Header />
                    <Container>
                        <Row>
                            <Col sm='12'>
                                <Row style={{borderBottom: 'solid', borderColor: 'rgba(166, 166, 166, 0.53)', borderWidth: '1px', marginTop: '15px'}}>
                                    <Col>
                                        <Nav >
                                            <span className={window.location.pathname.includes('profile/subscriptions')? 'selectedTab' : null}>
                                            <NavItem>
                                                <NavLink active={window.location.pathname.includes('profile/subscriptions')} id='subscriptions' href='#' onClick={this.handleTabs} style={{color: '#1d1d1d'}}>Subscriptions</NavLink>
                                            </NavItem>
                                            </span>
                                            <span className={window.location.pathname.includes('profile/paymentMethods')? 'selectedTab' : null}>
                                            <NavItem>
                                                <NavLink active={window.location.pathname.includes('profile/paymentMethods')} id='payments' href='#' onClick={this.handleTabs} style={{color: '#1d1d1d'}}>Payment Methods</NavLink>
                                            </NavItem>
                                            </span>
                                            <span className={window.location.pathname.includes('profile/paymentHistory')? 'selectedTab' : null}>
                                            <NavItem>
                                                <NavLink active={window.location.pathname.includes('profile/paymentHistory')} id='paymentHistory' href='#' onClick={this.handleTabs} style={{color: '#1d1d1d'}}>Payment History</NavLink>
                                            </NavItem>
                                            </span>
                                        </Nav>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='profileBody'>
                                        <Switch>
                                            <Route exact path='/profile/paymentMethods' component={PaymentMethods} />
                                            <Route exact path='/profile/paymentHistory' component={PaymentHistory} />
                                            <Route exact path='/profile/subscriptions' component={Subscriptions} />
                                            <Route path='/profile/*' component={NotFound} />
                                        </Switch>
                                    </Col>
                                </Row>
                                <br />
                            </Col>
                        </Row>
                    </Container>
                    <br />
                </div>
            );
        }
    }
}