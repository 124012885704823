import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, Alert, Spinner, Row, Col } from 'reactstrap';
import Stripe from './../../actions/stripe';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function DeleteSubscription(props) {
    let subscription = props.subscription;
    let product = subscription.plan.product;
    return (
        <>
            <Alert color='warning' className='center'>
                {(!!props.children)? props.children 
                : (!subscription.cancel_at_period_end) ?
                (<div>
                    <p>The cost for the remaining subscription time will not be refunded.</p>
                    <hr />
                    <p>Another option is to remove your <Link to='/profile/paymentMethods'>payment methods</Link>. The subscription will automatically be cancelled at the end of the period if it is not paid.</p>
                </div>)
                :
                (<div>
                    <p>Your subscription is set to cancel at the end of the current billing period. You can cancel immediately instead or undo cancellation. </p>
                </div>)}
                <Row style={{padding: '15px'}}> 
	                <Col><DeleteSubBtn className='bttn danger'/></Col>
                    {['prod_Gyz9da7ZsMOUsO', 'prod_GwPqYlXIBqeY1I', 'prod_GsbMjoRYRo7fJv'].indexOf(product) <0 &&
	                <Col>
                        {!!subscription.cancel_at_period_end ? <UndoDeleteBtn {...props} text='Undo Cancellation' className='bttn secondary'/> : 
                        <DeleteSubBtn updateParentState={props.updateParentState} text='Cancel at Period End' className='bttn danger'/>}
	                </Col>}
                </Row>
            </Alert>
        </>
    );
}

export const UndoDeleteBtn = function(props) {
    let {text, className} = props;
    const [isLoading, setLoading] = useState(false);
    async function cancelStripeSub() {
        setLoading(true);
        let subscription = await Stripe.updateSubscription( {cancel_at_period_end: false}, props.subscription.id, 'Successfully undid cancellation at the end of the current period.');
        props.updateParentState({subscription});
        setLoading(false);
    }

    return (
        <>
            <button className={className || 'bttn primary block'} disabled={isLoading} onClick={cancelStripeSub}>{text || 'Undo Cancellation'}</button>
        </>
    );
};

UndoDeleteBtn.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
}

export const DeleteSubBtn = withRouter(function({history, match, className, text, updateParentState}) {
    const [isOpen, updateModal] = useState(false);

    function toggleCancelModal() {
        updateModal(state => (!state));
    }

    function cancelStripeSub(now) {
        return new Promise(async (resolve,reject) => {
            if(!!now){
                try {
                    let subscription = await Stripe.cancelSubscription(match.params.id);
                    resolve(subscription);
                    history.push('/profile/subscriptions');
                } catch(error) {
                    reject(error);
                }
            }
            else{
                try {
                    let subscription = await Stripe.updateSubscription( {cancel_at_period_end: true}, match.params.id, 'Successfully set subscription to cancel at the end of the current billing period.');
                    updateParentState({subscription});
                    resolve(subscription);
                } catch(error) {
                    reject(error);
                }
            }
        });
    }

    return (
        <>
            <button className={className || 'bttn primary block'} onClick={toggleCancelModal}>{text || 'Cancel Immediately'}</button>
            <CancelSubModal isOpen={isOpen} updateModal={updateModal} cancelStripeSub={cancelStripeSub} updateParentState={updateParentState} now={!text}/>
        </>
    );
});

DeleteSubBtn.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
}

function CancelSubModal({isOpen, updateModal, cancelStripeSub, now}) {
    const [cancelling, updateCancel] = useState(false);
    function toggleCancelModal() {
        updateModal(state => (!state));
    }

    async function cancelSub() {
        try {
            updateCancel(true);
            await cancelStripeSub(now);
        } catch(error) {
            updateCancel(false);
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggleCancelModal}>
            <ModalBody style={{ paddingBottom: '0px' }}>
                <Alert color='danger' className='center'>
                    <h4>Confirm Removal</h4>
                    Are you sure you want to cancel your subscription?
                    <hr />
                    You will no longer be able to access your room(s){!now && ' after the current billing period ends'}. 
                </Alert>
            </ModalBody>
            <ModalFooter>
                <button className='bttn' onClick={toggleCancelModal}>Cancel</button>
                {(cancelling)? (<Spinner color='primary' />) : (
                    <button className='bttn danger' disabled={cancelling} onClick={cancelSub}>Remove</button>
                )}
            </ModalFooter>
        </Modal>
    );
}

CancelSubModal.propTypes = {
    isOpen: PropTypes.bool,
    updateModal: PropTypes.func,
    cancelStripeSub: PropTypes.func,
}