import React from 'react';
import { Alert, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Row, Col, Input } from 'reactstrap';
import ParseUser from './../../actions/parseUser';
import Loading from './../loading';

export default class AddPermissionModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: null,
            user: '',
            permission: 'admin'
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    validateForm = () => {
        let permission = this.state.permission;
        let user = this.state.user;
        this.setState({isLoading: true, error: null});
        if(!permission) {
            this.setState({isLoading: false, error: 'Please select a role'});
            return;
        }
        if(!user) {
            this.setState({isLoading: false, error: 'User field is required'});
            return;
        }
        this.addUser(user, permission);
    }

    addUser = async (user, permission) => {
        try {
            let re = /\S+@\S+\.\S+/;
            if(re.test(user)) {
                await ParseUser.addAdminMod('email', user.toLowerCase(), permission, this.props.roomSecurity.get('account').id);
                if(this._isMounted) {
                    this.setState({isLoading: false, error: null, user: ''});
                    this.props.update();
                }
            } else {
                await ParseUser.addAdminMod('displayname', user, permission, this.props.roomSecurity.get('account').id);
                if(this._isMounted) {
                    this.setState({isLoading: false, error: null, user: ''});
                    this.props.update();
                }
            }
        } catch(error) {
            if(this._isMounted) {
                this.setState({isLoading: false, error: error.message});
            }
        }

    }

    updateInput = (e) => {
        this.setState({[e.target.id]: e.target.value});
    }

    submit = (e) => {
        if(e.key === 'Enter') {
            e.preventDefault();
            if(!this.state.isLoading) {
                this.validateForm();
            }
        }
    }

    render() {
        if(this.state.isLoading) {
            return (
                <Modal isOpen={true}>
                    <ModalHeader> Add Admin/Moderator</ModalHeader>
                    <ModalBody>
                        <Loading />
                    </ModalBody>
                    <ModalFooter>
                        <button className='bttn' disabled={true} onClick={this.props.toggle}>Close</button>
                        <button className='bttn primary' disabled={true} onClick={this.validateForm}>Add</button>
                    </ModalFooter>
                </Modal>
            );
        } else {
            //TODO: Add custom TS spawning back in
            return(
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
                    <ModalHeader toggle={this.toggle}> Add Permissions</ModalHeader>
                    <ModalBody>
                        <Form>
                            {!!this.state.error && <Alert color='danger' className='center'>{this.state.error}</Alert>}
                            <FormGroup row>
                                <Label for='permission' sm={2}><b>Role:</b></Label>
                                <Col sm={5} className='right'>
                                    <Input type='select' name='permission' id='permission' onChange={this.updateInput}>
                                        <option value='admin'>Admin</option>
                                        <option value='moderator'>Moderator</option>
                                        {/* <option value='member'>Member</option> */}
                                        <option value='suspended'>Suspend</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for='user' sm={2}><b>User:</b></Label>
                                <Col sm={10}>
                                    <Input id='user' placeholder='Enter email or displayname..' value={this.state.user} onChange={this.updateInput} onKeyDown={this.submit} autoFocus/>
                                </Col>
                            </FormGroup>
                        </Form>
                        <Row style={{position: 'absolute', width: '100%', bottom: '5px', left: '15px'}}>
                            <Col sm={{size:10, offset: 2}}>
                                <font size={2}>* displayname is case sensitive</font>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <button className='bttn' onClick={this.props.toggle}>Close</button>
                        <button className='bttn primary' disabled={this.state.isLoading} onClick={this.validateForm}>Add</button>
                    </ModalFooter>
                </Modal>
            );
        }
    }
}