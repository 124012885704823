import React, {useState, useEffect} from 'react';
import ParseQuery from './../actions/parseQueries';
import Loading from './loading';
import Error from './error';
import { withRouter } from "react-router";
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import './subscriptions.css';
import DetailsBtn from './detailsBtn';
import { FaRegBuilding } from "react-icons/fa";

export default function AdminSubscriptions(props) {
    const [{isLoading, error}, setState] = useState({isLoading: true, error: ''});
    const [roomSecurities, setRs] = useState([]);

    useEffect(() => {
        async function getSubs() {
            try {
                setRs(await ParseQuery.getAdminRoomSecurities());
                setState(state => ({...state, isLoading: false}));
            } catch(error) {
                setState({isLoading: false, error: error.message});
            }
        }
        getSubs();
    }, [ParseQuery.getAdminRoomSecurities, setState, setRs]);

    function render() {
        if(isLoading) {
            return <Col className='center' lg={{size: 8, offset: 2}}><Loading /></Col>
        } else if(!!error) {
            return <Col className='center subAlert' lg={{size: 8, offset: 2}}><Error message={error}/></Col>
        } else if(roomSecurities.length < 1) {
            return <Col className='center subAlert' lg={{size: 8, offset: 2}}><Error color='info' message={`You are not currently an admin on anyone else's Team Suite`}/></Col>
        }else {
            return roomSecurities.map((rs, idx) => {
                return <Col xs='12' md='6' lg='4' key={'adminSub_' + idx}><AdminSub roomSecurity={rs}/></Col>
            });
        }
    }

    return <Row >{render()}</Row>
} 

export const AdminSub = withRouter(function({roomSecurity, history, location, match}) {
    let account = roomSecurity.get('account');
    return <Card className='subCard'>
        <CardBody>
            <CardTitle className='center adminTitle'><h4 className='hideOverflow' title={account.get('organizationName')}>{account.get('organizationName')}</h4><p><font><FaRegBuilding size='1.25em'/>{roomSecurity.get('name')}</font></p></CardTitle>
            <Row className='cardButtonGroup'>
                <Col className='leftButton'><button className='bttn secondary block' onClick={() => history.push('/settings/room/' + roomSecurity.get('subscription').get('subscriptionId'))}>Admin</button></Col>
                <Col className='rightButton'><DetailsBtn className='bttn primary block' roomSecurities={[roomSecurity]} /></Col>
            </Row>
        </CardBody>
    </Card>
});

AdminSub.propTypes = {
    roomSecurity: PropTypes.object.isRequired
}

