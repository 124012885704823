import React from 'react';
import { Container, Form, FormGroup, CustomInput, Row, Col, Input, Spinner, Progress, TabContent, TabPane, Button } from 'reactstrap';
import Error from './../error';
import { IoIosAdd, IoIosClose } from "react-icons/io";
import {createInvitations} from './../../actions/invitations';
import Papa from 'papaparse';
const MAXEMAILS = 10;

export default class InviteModule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: '',
            emailList: [''],
            csvEmailList: [],
            passcodeIdx: 0,
            file: null,
            csvImport: false,
        }
    }

    updateInput = (e) => {
        let value = e.target.value;
        let updatedEmail = [...this.state.emailList];
        updatedEmail[e.target.getAttribute('idx')] = value;
        this.setState({emailList: updatedEmail});
    }

    onEnter = (e) => {
        if(e.key === 'Enter') {
            this.addAnotherInv(e);
        }
    }

    validateEmail = (email) => {
        let re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    removeEmail = (e, idx) => {
        let emailList = [...this.state.emailList];
        if(emailList.length === 1) {
            emailList[idx] = '';
            this.setState({emailList});
            return;
        }
        emailList.splice(idx, 1);
        this.setState({emailList, error: ''});
    }

    renderEmailInputs = () => {
        let { emailList } = this.state;
        return emailList.map( (email, idx) => {
            let validation = (!email)? {} : (this.validateEmail(email))? {valid: true} : {invalid: true};
            return <FormGroup key={'email_' + idx}>
                <div style={{float: 'left', width: '35px', transform: 'translateY(3px)'}} className='hoverText' onClick={(e) => this.removeEmail(e, idx)}><IoIosClose size='2em'/></div>
                <div style={{marginLeft: '35px', width: 'auto'}}><Input {...validation} type='email' value={email} idx={idx} placeholder='Enter email..' onChange={this.updateInput} onKeyDown={this.onEnter} style={{marginBottom: '10px'}} autoFocus={idx === (emailList.length-1)}/></div>
            </FormGroup>
        });
    }

    clearAll = (e) => {
        e.preventDefault();
        this.setState({error: '', emailList: ['']});
    }

    addAnotherInv = (e) => {
        e.preventDefault();
        let { emailList } = this.state;
        for(const email of emailList) {
            if(!email) {
                this.setState({error: 'Please correctly fill all email fields before trying to add more'});
                return;
            } else if(this.validateEmail(email)) {
                this.setState({error: ''});
            } else {
                this.setState({error: 'Please correct invalid emails before trying to add more'});
                return;
            }
        }
        if(emailList.length >= MAXEMAILS) {
            this.setState({error: (MAXEMAILS + ' is the maximum amount of emails that can be sent at a time')});
            return;
        }
        let updatedEmailList = [...emailList, ''];
        this.setState({emailList: updatedEmailList});
    }

    sendInvites = async (e) => {
        e.preventDefault();
        try {
            let { emailList, passcodeIdx } = this.state;
            for(const email of emailList) {
                if((!email && emailList.length > 1) || this.validateEmail(email)) {
                    continue;
                } else {
                    
                    this.setState({error: 'Please fix or remove invalid email(s)'});
                    return;
                }
            }
            this.setState({isLoading: true});
            await createInvitations(emailList, this.props.roomSecurity, passcodeIdx, (progress) => {
                this.setState({progress: progress});
            });
            this.setState({isLoading: false, emailList: ['']});
        } catch(error) {
            this.setState({isLoading: false, error: error.message});
        }
    }

    renderInvite = () => {
        return (
            <React.Fragment>
                <Form onSubmit={this.addAnotherInv}>
                    {(!!this.state.error) && <Error message={this.state.error} assist={false} />}
                    {this.renderEmailInputs()}
                    <FormGroup className='hoverText'>
                        <span onClick={this.addAnotherInv}><IoIosAdd size='1.75em' style={{transform: 'translateY(-3px)'}}/><font size={3}><b>Add Another Email</b></font></span>
                    </FormGroup>
                </Form>
                <div className='center'>
                    <Row>
                        <Col><button className='bttn block outline' onClick={this.clearAll}>Clear All</button></Col>
                        <Col>{(this.state.isLoading)? <Spinner color='primary' /> : <button className='bttn block primary' onClick={this.sendInvites}>Send Invites</button>}</Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }

    renderCSVImport = () => {
        let {file, isLoading} = this.state;
        return (                
            <Form>
                <div>
                    {(!!this.state.error) && <Error message={this.state.error} assist={false} />}
                    <FormGroup>
                        <a href='https://s3.amazonaws.com/assets.virbela.com/help/Team-Suite-Invitation-Template.csv'><font size='2'>Get Sample CSV</font></a>
                        <CustomInput accept=".csv" type="file" id="FileBrowser" label={file? file.name : ''} name="customFile" onChange={this.changeFile}/>
                    </FormGroup>
                    <button disabled={isLoading} className='bttn block primary' onClick={this.sendCSVInvites}>Send Invites</button>
                </div>
            </Form>)
    }

    toggleInviteMethod = () => {
        this.setState((state) => ({csvImport: !state.csvImport, error: '', emailList: [''], csvEmailList: [], file: null}));
    }

    render() {
        let { isLoading, progress, passcodeIdx} = this.state;
        let { roomSecurity } = this.props;
        let passcode1 = (Array.isArray(roomSecurity.get('passcodes')))? roomSecurity.get('passcodes')[0] : 'Cannot find passcode';
        let passcode2 = (Array.isArray(roomSecurity.get('passcodes')))? roomSecurity.get('passcodes')[1] : 'Cannot find passcode';
        return (
            <Container fluid className='containerBg'>
                <h3>Part of a Team?</h3>
                <p><font size={2}>This invite will send instructions to your team on how to get started. Remember that you can always just share your passcode with existing users.</font></p>
                <Form>
                    <FormGroup>
                        <div>
                            <p><font size={2}>Please select which passcode to share:</font></p>
                            <div>
                                <CustomInput type="radio" id="passcodeRadio1" name="passcodeRadio" value={0} label={passcode1} inline checked={passcodeIdx === 0} onChange={this.togglePasscode}/>
                                <CustomInput type="radio" id="passcodeRadio2" name="passcodeRadio" value={1} label={passcode2} inline checked={passcodeIdx === 1} onChange={this.togglePasscode} />
                            </div>
                        </div>
                    </FormGroup>
                </Form>
                {(isLoading) && <div className='center' style={{marginBottom: '25px'}}><Progress animated value={(!!progress && !!progress.percentage)? progress.percentage : 0}>{'sending to ' + ((!!progress && !!progress.email)? progress.email : '.') + '..'}</Progress></div>}
                <div style={{position: 'relative'}}><div style={{position: 'absolute', right: '5px', top: '-30px'}} disabled={isLoading} onClick={this.toggleInviteMethod}><Button color='link'><font size='2'>{(this.state.csvImport)? 'input list?' : 'csv import?'}</font></Button></div><hr style={{marginTop: '30px', marginBottom: '30px'}} /></div>
                <TabContent activeTab={(this.state.csvImport)? '2' : '1'}>
                    <TabPane tabId='1'><div>{this.renderInvite()}</div></TabPane>
                    <TabPane tabId='2'><div>{this.renderCSVImport()}</div></TabPane>
                </TabContent>
            </Container>
        );
    }

    togglePasscode = (e) => {
        this.setState({passcodeIdx: parseInt(e.target.value)});
    }

    sendCSVInvites = async(e) =>{
        e.preventDefault();
        try {
            let {passcodeIdx, csvEmailList} = this.state;
            if(csvEmailList.length < 1) {
                this.setState({error: 'No emails founds'});
                return;
            }
            for(const email of csvEmailList) {
                if((!email && csvEmailList.length > 1) || this.validateEmail(email)) {
                    continue;
                } else {
                    this.setState({error: `Please fix or remove invalid email(s): "${email}". Please use the sample csv as a reference.`});
                    return;
                }
            }
            this.setState({isLoading: true, error: ''});
            await createInvitations(csvEmailList, this.props.roomSecurity, passcodeIdx, (progress) => {
                this.setState({progress: progress});
            });
            this.setState({isLoading: false, csvEmailList: ['']});
        } catch(error) {
            this.setState({isLoading: false, error: error.message});
        }
    }

    changeFile = async(e) =>{
        let file = e.target.files[0];
        if(file && !!file.name.match(/^.*\.csv$/)) {
            let emailList = [];
            let setState = this.setState.bind(this);
            Papa.parse(file, {skipEmptyLines: true,
                complete: function(results) {
                    let emails = results.data;
                    for(const i in emails){
                        emailList.push(emails[i][0]);
                    }
                    setState({file,csvEmailList: emailList});
                }
            });

        } else {
          this.setState({ error: 'Current file is not a CSV' });
        }
    }
}