import React from 'react';
import { Container, Form, FormText, Row, Col, Input, Label, UncontrolledTooltip, Spinner } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Notifications from './../../actions/notifications';
import Room from './../../actions/room';
import './../../pages/roomSettings.css';
import Error from './../error';


const yup = require('yup');
const schema = yup.object().shape({
    passcode: yup.string().trim()
                .min(8, 'Passcode needs to be a minimum of 8 characters')
                .matches(/^(?=.*?[0-9])/, 'Must contain at least one number').matches(/^(?=.*?[a-zA-Z])/, 'Must contain at least one letter')
                .required('Passcode is required')
});

export default class RoomPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            passcodeError: 'Passcode must be 8 characters and contain at least one letter and number.',
            passcode: '',
            passcode2: '',
            passcodeType: 0
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    currentPasscode = () => {
        let room = this.props.room;
        let { passcodeType } = this.state;
        let pass = (Array.isArray(room.get('passcodes')))? room.get('passcodes')[passcodeType] : 'unavailable';
        return (
            <React.Fragment>
                <Label>Current Passcode:</Label>
                <CopyToClipboard text={pass} onCopy={() => {Notifications.addNotification('Success', 'Successfully copied!', 'success', {timer: 750});}}>
                    <span className='passcodeDisplay'>
                        <span id='passcodeTooltip'>{' ' + pass + ' '}</span>
                        {/* <span className='hidden'>{' ********* '}</span> */}
                    </span>
                </CopyToClipboard>
                <UncontrolledTooltip placement='top' target="passcodeTooltip">
                    Click to copy
                </UncontrolledTooltip>
            </React.Fragment>
        );
    }

    validateForm = async (e) => {
        try {
            e.preventDefault();
            this.setState({isLoading: true});
            let { passcode, passcode2 } = this.state;
            await schema.validate({passcode: passcode});
            if(!this._isMounted) return;
            // eslint-disable-next-line
            if(passcode !== passcode2) throw {message: 'Passcodes do not match'}; //can't throw error because conflicts with imported Error component
            let updatedRoom = await Room.updateRoomPasscode(this.props.room, passcode, this.state.passcodeType);

            if(!this._isMounted) return;
            let updatedRoomSecurities = this.props.roomSecurities.map(roomSecurity => {
                if(roomSecurity.id === updatedRoom.id) {
                    return updatedRoom;
                } else {
                    return roomSecurity;
                }
            });
            this.setState({isLoading: false, passcode: '', passcode2: '', error: ''});
            this.props.updateState({roomSecurities: updatedRoomSecurities, selected: updatedRoom});
        } catch(error) {
            if(!this._isMounted) return;
            this.setState({isLoading: false, error: error.message, passcode: '', passcode2: ''});
        }
    }

    validatePasscode = (passcode) => {
        let re = /^(?=.*?[0-9])(?=.*?[a-zA-Z]).{8,}/;
        return re.test(passcode);
    }

    validatePasscode2 = (passcode, passcode2) => {
        return (passcode === passcode2);
    }

    changePasscode = () => {
        let { passcode, passcode2, error, isLoading } = this.state;
        let validationP = (!passcode)? {} : (this.validatePasscode(passcode))? {valid: true} : {invalid: true};
        let validationP2 = (!passcode2)? {} : (this.validatePasscode2(passcode, passcode2))? {valid: true} : {invalid: true};
        return (
            <React.Fragment>
                {(!!error) && <Error color='danger' assist={false}><font size={2.5}>{error}</font></Error>}
                <Form className='passcodeForm'>
                    <div>
                        <FormText>Minimum: 8 characters, 1 letter, 1 number</FormText>                     
                        <Input placeholder='New passcode..' id='passcode' type='password' value={passcode} {...validationP} onChange={this.updateInput} onKeyDown={this.submit}/>
                    </div>
                    <div>
                        <Input placeholder='Retype new passcode..' id='passcode2' type='password' value={passcode2} {...validationP2} onChange={this.updateInput} onKeyDown={this.submit}/>
                    </div>
                    <div>
                        {(isLoading)? <div className='center'><Spinner color='primary' /></div> : <button className='bttn block outline primary' onClick={this.validateForm}>Submit</button>}
                    </div>
                </Form>
            </React.Fragment>
        );
    }

    render() {
        let { passcodeType} = this.state;
        let style= {
            height: '50%', position: 'relative', borderStyle: 'solid', borderColor: 'rgba(0,0,0, 0.20)'
        }
        let active = {
            ...style,
            fontWeight: 'bold',
            borderWidth: '2px 0px 2px 2px',
            borderTopLeftRadius: '5%',
            borderBottomLeftRadius: '5%',
            backgroundImage: 'linear-gradient(to right, rgba(231, 231, 231, 0.356), rgba(231, 231, 231, 0))'
        }
        let inactive = {
            ...style,
            fontWeight: 'normal',
            borderWidth: '0px 2px 0px 0px'
        }
        return (
            <Container>
                <Row>
                    <Col className='hover' xs='1' style={{minHeight: '70px'}}>
                        <Row id='passTab1' style={(!passcodeType)? {...active} : {...inactive}} onClick={this.togglePasscodeType}>
                            <div id='passTab1' style={{position: 'absolute', top: '50%', right: '50%', transform: 'translate(50%,-50%)'}}>
                                1
                            </div>
                        </Row>
                        <Row id='passTab2' style={(!!passcodeType)? {...active} : {...inactive}} onClick={this.togglePasscodeType}>
                            <div id='passTab2' style={{position: 'absolute', top: '50%', right: '50%', transform: 'translate(50%,-50%)'}}>
                                2
                            </div>
                        </Row>
                    </Col>
                    <Col xs='10'>
                        <Row>
                            <Col xs='12' md={{ size: 9 }} lg={{ size: 10}} xl={{ size: 7 }}>{this.currentPasscode()}</Col>
                        </Row>
                        <Row>
                            <Col xs='12' md={{ size: 9 }} lg={{ size: 10}} xl={{ size: 7 }}>{this.changePasscode()}</Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }

    submit = (e) => {
        if(e.key === 'Enter' && !this.state.passcodeError) {
            e.preventDefault();
            this.validateForm();
        }
    }

    updateInput =(e) => {
        this.setState({[e.target.id]: e.target.value});
    }

    togglePasscodeType = (e) => {
        this.setState({'passcodeType': (e.target.id === 'passTab1')? 0 : 1});
    }
}