import React, { useEffect, useReducer } from 'react';
import { Row, Col, Table } from 'reactstrap';
import stripe from './../actions/stripe';
import Loading from './loading';
import Error from './error';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {AiOutlineDoubleLeft} from "react-icons/ai"
import './paymentHistory.css';
import qs from 'query-string';

const paymentHistory = (props) => {
  let params = qs.parse(props.location.search);
  const [state, dispatch] = useReducer(paymentHistoryReducer, {
    isLoading: true,
    pastInvoices: null,
    upcomingInvoices: null,
    page: Number(params.page) || 1,
    error: '',
  });
  const { isLoading, pastInvoices, upcomingInvoices, error, page } = state;
  function paymentHistoryReducer(state, action) {
    switch (action.type) {
      case 'gotPaymentHistory':
        return {
          ...state,
          isLoading: false,
          pastInvoices: action.payload.pastInvoices,
        };
      case 'gotInitialHistory':
        return {
          ...state,
          isLoading: false,
          upcomingInvoices: action.payload.upcomingInvoices,
          pastInvoices: action.payload.pastInvoices,
        };
      case 'changePage': 
        return {
          ...state,
          isLoading: true,
          page: action.payload.page,
        }
      case 'error':
        return {
          ...state,
          isLoading: false,
          error: action.payload.error,
        }
      default:
        return state;
    }
  }

  useEffect(() => {
    async function getInitialHistory(params){
      try{
        let upcomingInvoices = await stripe.getUpcomingPayments();
        let pastInvoices = await stripe.getPaymentHistory(params.starting_after ||undefined, params.ending_before || undefined);
        dispatch({
          type: 'gotInitialHistory',
          payload: {
            pastInvoices: pastInvoices ? pastInvoices : null,
            upcomingInvoices: upcomingInvoices ? upcomingInvoices : null,
            error,
          },
        });
      }catch(error){
        dispatch({
          type: 'error',
          payload: {
            error,
          },
        });
      }
    }
    getInitialHistory(params);
  }, []);

  function displayPaymentHistory(type = 'past') {
    return (
      <>
        <h3>{type === 'past' ? 'Past Invoices' : 'Upcoming Invoices'}</h3>
        <Table responsive striped className={`table table-${type}`}>
          <thead>
            <tr>
              <th>#</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Description</th>
              <th>Status</th>
              <th>{type==='past' ? 'URL' : 'PDF'}</th>
            </tr>
          </thead>
          <tbody>
            {
              (isLoading && (type === 'upcoming') && !!upcomingInvoices)? displayUpcomingInvoices(upcomingInvoices) : 
              (isLoading)? <tr><td colSpan={6}><Loading /></td></tr> : 
              (type === 'past')? displayPastInvoices(pastInvoices.data) : displayUpcomingInvoices(upcomingInvoices)
            }
          </tbody>
        </Table>
        {(!isLoading && type === 'past') && <div className='center'>
            {page >2 && <button className='bttn history' onClick={(e)=> goBack(e, 1)} type={'submit'}><AiOutlineDoubleLeft/></button>}
            {page > 1 && <button className='bttn history' onClick={(e)=> goBack(e, page-1)} type={'submit'}><IoIosArrowBack/></button>}
            { '   ' + page + '   ' }
            {pastInvoices.has_more && <button className='bttn history' onClick={(e)=> goForward(e, page+1)} type={'submit'}><IoIosArrowForward/></button>}
        </div>}
      </>
    );
  }
  async function goForward (e, pageNumber) {
    e.preventDefault();
    let lastInvoice = pastInvoices.data[pastInvoices.data.length-1];
    dispatch({
      type: 'changePage',
      payload: {
        isLoading: true,
        page: pageNumber,
      },
    });
    props.history.push('/profile/paymentHistory?page='+pageNumber +'&starting_after='+ (!!lastInvoice && lastInvoice.id)||'');
    let last = (pageNumber>1? !!lastInvoice && lastInvoice.id: undefined);
    let newPage= await stripe.getPaymentHistory(last, undefined);
    dispatch({
      type: 'gotPaymentHistory',
      payload: {
        pastInvoices: newPage ? newPage : null,
        error,
      },
    });
  } 

  async function goBack(e,pageNumber){
    e.preventDefault();
    let firstInvoice = pastInvoices.data[0];
    dispatch({
      type: 'changePage',
      payload: {
        isLoading: true,
        page: pageNumber,
      },
    });
    let newUrl= '/profile/paymentHistory?page='+pageNumber; 
    if(pageNumber!==1){
      newUrl = newUrl +'&ending_before='+ (!!firstInvoice && firstInvoice.id)||'';
    }
    props.history.push(newUrl);
    let newPage= await stripe.getPaymentHistory(undefined, pageNumber>1? !!firstInvoice && firstInvoice.id: undefined);
    dispatch({
      type: 'gotPaymentHistory',
      payload: {
        pastInvoices: newPage ? newPage : null,
        error,
      },
    });
  }
  function displayPastInvoices(pastInvoices) {
    if(!pastInvoices) return null;
    let map= pastInvoices.map((invoice, index) =>  displayInvoice(invoice, index, 'past'));
    return map;
  }

  function displayUpcomingInvoices(upcomingInvoices) {
    if(!upcomingInvoices) return null;
    return upcomingInvoices.map((invoice, index) => displayInvoice(invoice, index));
  }

  function displayInvoice(invoice, index, type='upcoming') {
    let { amount_due, created, lines, invoice_pdf, status } = invoice;
    if(type === 'past'){
      invoice_pdf = (!!invoice.payment_intent && !!invoice.payment_intent.charges && 
      !!invoice.payment_intent.charges.data[0] && invoice.payment_intent.charges.data[0].receipt_url) || invoice_pdf;
    }
    const createdDate = created
      ? new Date(created * 1000).toLocaleDateString()
      : '—';
    const formattedAmount = amount_due
      ? '$' + (amount_due / 100).toFixed(2).toLocaleString()
      : '—';
    const invoiceLines =
      lines && lines.data
        ? lines.data.map((line) => {
            return <div key={line.id}>{line.description}</div>;
          })
        : '—';

    return (
      <tr key={(type ==='upcoming' && !invoice.id)? 'upcoming_'+index : invoice.id}>
        <th scope='row'>{index + 1 + (type==='past' ? (page-1)*10 : 0)}</th>
        <td>{formattedAmount}</td>
        <td>{createdDate}</td>
        <td>{invoiceLines}</td>
        <td>{status ? status : '—'}</td>
        <td>{invoice_pdf ? <a href={invoice_pdf} target='_blank' rel='noopener noreferrer'>View</a> : '—'}</td>
      </tr>
    );
  }

  function renderPaymentHistory() {
    if (error) {
      return <Error color='danger' message={error} />;
    } else if (pastInvoices && pastInvoices.data && !pastInvoices.data.length) {
      return <Error color='warning' message='No payment history available' />;
    } else {
      return (
        <>
          {displayPaymentHistory('upcoming')}
          <br />
          {displayPaymentHistory('past')}
        </>
      );
    }
  }

  return (
    <div className='left'>
      <div className='containerBg'>
        <Row>
          <Col>
            <h1>Payment History</h1>
          </Col>
          <Col sm='3' className='right'></Col>
        </Row>
        <br />
        {renderPaymentHistory()}
      </div>
    </div>
  );
};

export default paymentHistory;
