import React from 'react';
import Auth from './../actions/auth';

export default class Header extends React.Component {
    
    render() {
        if(Auth.isSessionLogin()) {
            return null;
        }
        return (
            <div style={{position: 'relative', backgroundImage: 'url(https://assets.virbela.com/opencampus/avatars.png)', backgroundSize: 'cover', backgroundPositionX: 'center', minHeight: '300px'}}>
                <div style={{position: 'absolute', backgroundColor: 'rgba(0,0,0,0.35)', height: '100%', width: '100%'}} />
                <div style={{position: 'absolute', width: '100%', color: 'white', top: '45%'}}>
                    <h1>{this.props.title || 'Open Campus'}</h1>
                </div>
            </div>
        );
    }
}