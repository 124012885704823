/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import './loading.css';

function Loading({ extraClassName }) {
  const className = extraClassName ? `center loading ${extraClassName}` : 'center loading';
  return (
    <div className={className}>
      <br />
      <Spinner type="grow" color="primary" />
      <Spinner type="grow" color="primary" />
      <Spinner type="grow" color="primary" />
      <Spinner type="grow" color="primary" />
      <Spinner type="grow" color="primary" />
      <Spinner type="grow" color="primary" />
      <Spinner type="grow" color="primary" />
      <Spinner type="grow" color="primary" />
      <br />
    </div>
  );
}

Loading.propTypes = {
  extraClassName: PropTypes.string,
};

Loading.defaultProps = {
  extraClassName: '',
};

export default Loading;
