/** @format */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { Link } from 'react-router-dom';
import qs from 'query-string';
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  Input,
  FormGroup,
  Fade,
  Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import auth from '../actions/auth';
import parseUser from '../actions/parseUser';
import './loginModal.css';
import OktaLoginButton from './oktaLoginButton';

function FieldGroup({ id, label, ...props }) {
  return (
    <FormGroup>
      <Label for={id}>{label}</Label>
      <Input id={id} {...props} />
    </FormGroup>
  );
}

FieldGroup.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    const { error } = this.props;
    this.focusRef = React.createRef();
    this.state = {
      error: error || null,
      fadeIn: true,
      fadeInBtn: false,
      isLoading: false,
      loginEmail: '',
      loginPassword: '',
    };
  }

  componentDidMount() {
    const { email } = this.props;
    this._isMounted = true;
    this.setState({ loginEmail: email || '' });
    this.focusRef.current.focus();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggle = () => {
    const { toggleLogin } = this.props;
    toggleLogin();
  };

  userLogin = async () => {
    try {
      const { location } = this.props;
      this.setState({ isLoading: true });
      const { loginEmail, loginPassword } = this.state;
      await auth.login(loginEmail, loginPassword);
      const params = qs.parse(location.search);
      if (params.redirect) {
        window.location.href = `https://www.virbela.com/${params.redirect}`;
      }
      if (!this._isMounted) return;
      this.setState({ error: '', isLoading: false });
      this.toggle();
    } catch (error) {
      this.setState({ error: error.message, isLoading: false });
    }
  };

  submit = (e) => {
    const { isLoading, fadeInBtn } = this.state;
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!isLoading) {
        if (fadeInBtn) {
          this.submitForgotPassword();
        } else {
          this.userLogin();
        }
      }
    }
  };

  toggleForgotPassword = () => {
    const { fadeIn } = this.state;
    this.setState({ fadeIn: !fadeIn });
  };

  toggleForgotPasswordBtn = () => {
    const { fadeInBtn } = this.state;
    this.setState({ fadeInBtn: !fadeInBtn });
  };

  submitForgotPassword = () => {
    const { loginEmail } = this.state;
    const { hideToggle } = this.props;
    this.setState({ isLoading: true });
    parseUser.sendResetPasswordEmail(loginEmail, (error) => {
      if (this._isMounted) {
        if (error) {
          this.setState({ error, isLoading: false });
        } else {
          this.setState({ error: null, fadeInBtn: false, isLoading: false });
          if (!hideToggle) this.toggle();
        }
      }
    });
  };

  updateInput = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  render() {
    const { error, fadeIn, fadeInBtn, isLoading, loginEmail, loginPassword } = this.state;
    const { backDrop, className, hideOkta, hideRegister, hideToggle, location, toggleCancel } = this.props;
    const title = fadeIn ? 'Login' : 'Forgot Password';
    return (
      <div>
        <Modal isOpen toggle={this.toggle} backdrop={backDrop || true} className={className}>
          <ModalHeader toggle={hideToggle ? null : this.toggle}>
            {title === 'Login' ? <>Virbela Login</> : title}
          </ModalHeader>
          {error && (
            <Alert color="danger" style={{ margin: '1rem' }}>
              {error}
            </Alert>
          )}
          <ModalBody style={{ position: 'relative' }}>
            <Form>
              <FieldGroup
                id="loginEmail"
                type="email"
                label="Email:"
                placeholder="Enter email.."
                innerRef={this.focusRef}
                value={loginEmail}
                onChange={this.updateInput}
                onKeyDown={this.submit}
              />
              <Fade in={fadeIn} unmountOnExit>
                <FieldGroup
                  id="loginPassword"
                  type="password"
                  label="Password:"
                  placeholder="Enter password.."
                  value={loginPassword}
                  onChange={this.updateInput}
                  onKeyDown={this.submit}
                />
              </Fade>
            </Form>
            {!fadeInBtn && (
              <div className="forgotPassword">
                <font
                  size={2}
                  color="#007bff"
                  onClick={this.toggleForgotPassword}
                  role="button"
                  tabIndex={0}
                  onKeyDown={this.toggleForgotPassword}
                >
                  Forgot password?
                </font>
              </div>
            )}
          </ModalBody>
          <ModalFooter style={{ position: 'relative', flexWrap: 'wrap' }}>
            <Fade in={fadeIn} unmountOnExit onExited={this.toggleForgotPasswordBtn}>
              <button
                className="bttn"
                onClick={toggleCancel || this.toggle}
                type="submit"
                style={{ marginRight: '3px' }}
              >
                Cancel
              </button>
              {isLoading ? (
                <Spinner color="primary" style={{ marginLeft: '15px', marginRight: '15px' }} />
              ) : (
                <button
                  className="bttn primary"
                  type="button"
                  onClick={() => {
                    this.userLogin();
                  }}
                  disabled={isLoading}
                >
                  Login
                </button>
              )}
            </Fade>
            <Fade in={fadeInBtn} unmountOnExit onExit={this.toggleForgotPassword}>
              <button
                className="bttn"
                type="button"
                onClick={this.toggleForgotPasswordBtn}
                style={{ marginRight: '3px' }}
              >
                Back
              </button>
              <button className="bttn primary" type="submit" onClick={this.submitForgotPassword} disabled={isLoading}>
                Submit
              </button>
            </Fade>
            {hideRegister ? null : (
              <div style={{ flexBasis: '100%', marginTop: '20px' }}>
                <Link
                  to={{
                    pathname: 'register',
                    prevPage: location.prevPage,
                    data: location.data,
                  }}
                >
                  <font size={2}>Need an account? Register here</font>
                </Link>
              </div>
            )}
          </ModalFooter>
          {hideOkta ? null : (
            <Fade in={fadeIn} unmountOnExit>
              <div className="center otherLogins">
                <hr className="divider" />
                <div>
                  <OktaLoginButton className="bttn primary block outline" {...this.props}>
                    Passport
                  </OktaLoginButton>
                </div>
              </div>
            </Fade>
          )}
        </Modal>
      </div>
    );
  }
}

LoginModal.propTypes = {
  backDrop: PropTypes.bool,
  className: PropTypes.string,
  email: PropTypes.string,
  error: PropTypes.string,
  hideOkta: PropTypes.bool,
  hideRegister: PropTypes.bool,
  hideToggle: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
    prevPage: PropTypes.string,
    data: PropTypes.object,
  }),
  toggleCancel: PropTypes.func,
  toggleLogin: PropTypes.func,
};

LoginModal.defaultProps = {
  backDrop: true,
  className: '',
  email: '',
  error: '',
  hideOkta: false,
  hideRegister: false,
  hideToggle: false,
  location: {},
  toggleCancel: () => {},
  toggleLogin: () => {},
};
