import swal from 'sweetalert';
import './../component/notifications.css';

class Notifications {
    
    addNotification(title, text, icon = null, options={}) {
        if(!!icon) {
            swal({title, text, icon, ...options});
        } else {
            swal({title, text, ...options});
        }
    }

    addNotificationJS(title, text, icon, button, buttonName, onSuccess) {
        swal(title, 
            text, 
            icon, 
            {buttons: button}
        ).then(value => {
            if(value === buttonName) {
                onSuccess();
            }
        });
    }
    
}

export default new Notifications();