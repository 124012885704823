import React from 'react';
import { Card, CardBody, CardImg, CardTitle, CardText, Row, Col } from 'reactstrap';
import Loading from './loading';
import Error from './error';
import './subscriptions.css';
import DetailsBtn from './detailsBtn';

export default class Subscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            error: '',
            subscription: null,
            openDetailsModal: false,
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if(!!this.props.subscription) {
            this.setState({subscription: this.props.subscription, isLoading: false});
        } else {
            this.setState({isLoading: false, error: 'Missing subscription'});
        }

        if(this.props.openDetails) {
            this.setState({openDetailsModal: true});
            this.props.history.push({state: ''});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.openDetails !== this.props.openDetails) {
            this.setState({openDetailsModal: true});
            this.props.history.push({state: ''});
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    adminBtnRedirect = (e) => {
        let { subscription } = this.state;
        if(!subscription) return;
        if(subscription.plan.product.metadata.type === 'Private Campus') {
            window.location.href = 'https://app.virbela.com/login';
        } else {
            this.props.history.push(`/settings/room/${subscription.id}`);
        }
    }

    render() {
        if(this.state.isLoading) {
            return (
                <Loading />
            );
        } else if(!!this.state.error) {
            return (
                <Error color='danger' message={this.state.error} />
            );
        } else {
            let subscription = this.state.subscription;
            let borderWarning = {};
            if(subscription.status !== 'active') {
                borderWarning = {
                    color: 'danger',
                    outline: true
                }
            }
            let img = subscription.plan.metadata.image || 'https://placeholdit.imgix.net/~text?txtsize=33&txt=318%C3%97180&w=318&h=180';
            let subText = (subscription.cancel_at_period_end)? 'Expires: ' : 'Next payment: '; 
            return (
                <React.Fragment>
                    <Card className='subCard' {...borderWarning}>
                        <CardImg top width='100%' src={img} alt='Card image cap' />
                        <CardBody>
                            <CardTitle style={{marginBottom: '0px'}}><b>{subscription.plan.nickname}</b></CardTitle>
                            {(subscription.status === 'active')? (
                                <CardText>{subText + (new Date(subscription.current_period_end *1000)).toLocaleString('en-US').split(',')[0]}</CardText>
                            ) : (
                                <CardText>{'Status: ' + subscription.status.replace(/_/gi, ' ')}</CardText>
                            )}
                            <Row className='cardButtonGroup'>
                                <Col className='leftButton'><button className='bttn secondary block' onClick={this.adminBtnRedirect}>Admin</button></Col>
                                <Col className='rightButton'><DetailsBtn stripeSub={this.state.subscription} onFinish={this.props.getSubscriptions} dCard={this.props.dCard} startOpen={this.state.openDetailsModal} /></Col>
                            </Row>
                        </CardBody>
                    </Card>
                </React.Fragment>
            );
        }
    }
}