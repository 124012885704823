import React from 'react';
import { ImplicitCallback } from '@okta/okta-react';
import './../pages/register.css';

export default class OktaImplicitCallback extends React.Component {
    render() {
        return (
            <section className='registerBackground minHeightNoHeader' style={{position: 'relative'}}>
                <div style={{position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0, 0.20)'}}/>
                <ImplicitCallback {...this.props}/>
            </section>
        )
    }
}