import React from 'react';
import Parse from 'parse';
import { Container, Row, Col } from 'reactstrap';
import auth from './../actions/auth';
import parseUser from './../actions/parseUser';
import Loading from './../component/loading';
import Notifications from './../actions/notifications';
import successShield from '../images/success-shield.svg';
import verifyLetter from '../images/verify-letter-red.svg';
import './register.css';

export default class Verification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isResending: false,
            loggedInToEmailToVerify: false,
            alreadyVerified: false,
            verifyStatus: 'verifying',
            message: '',
            downloadUrls: {
                macDownloadUrl: 'https://assets.virbela.com/installer/Virbela.dmg',
                windowsDownloadUrl: 'https://assets.virbela.com/installer/VirbelaSetup.exe'
            }
        }
    }

    async componentDidMount() {
        this._isMounted = true;
        try {
            const code = this.props.match.params.value;
            this.verifyUser(code);
        } catch(error) {
            let message = error.message || 'Verification failed. Log in to send another verification email.'
            Notifications.addNotification('Error', message, 'error');
            await auth.logout();
            this.props.history.push('/');
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    resendEmail = () => {
        if (this.state.isResending) return;
        this.setState({isResending: true});
        parseUser.resendVerificationEmail((error) => {
            if(this._isMounted) {
                this.setState({isResending: false});
            }
        });
    }

    verifyUser = async (code) => {
        this.setState({ verifyStatus: 'verifying' });
        await parseUser.verifyUser(code, async (error) => {
            if (this._isMounted) {
                if (error.loggedInToEmailToVerify) {
                    return this.setState({
                        verifyStatus: 'error',
                        alreadyVerified: error.alreadyVerified,
                        message: error.reason
                    });
                } else {
                    await auth.logout();
                    this.props.history.push('/');
                }
            }
        }, async (res) => {
            const { email, downloadUrls, loggedInToEmailToVerify } = res;
            const { macDownloadUrl, windowsDownloadUrl } = downloadUrls || {};

            if (!loggedInToEmailToVerify) {
                await auth.logout();
                this.props.history.push('/');
                Notifications.addNotification('Success', `${email || 'Email'} has been verified and registration completed! You can now log in.`, 'success');
                return;
            }

            // get updated User.email_verified value after verifying email
            if (Parse.User.current()) await Parse.User.become(Parse.User.current().getSessionToken());
            this.setState({
                verifyStatus: 'success',
                message: `${email || 'Your email'} has been verified and registration completed!`,
                downloadUrls: {
                    macDownloadUrl: macDownloadUrl || 'https://assets.virbela.com/installer/Virbela.dmg',
                    windowsDownloadUrl: windowsDownloadUrl || 'https://assets.virbela.com/installer/VirbelaSetup.exe'
                }
            });
        });
    }

    renderStatus = () => {
        const { isResending, message, verifyStatus, alreadyVerified, downloadUrls = {} } = this.state;
        const macDownloadUrl = downloadUrls.macDownloadUrl || 'https://assets.virbela.com/installer/Virbela.dmg';
        const windowsDownloadUrl = downloadUrls.windowsDownloadUrl || 'https://assets.virbela.com/installer/VirbelaSetup.exe';
        switch(verifyStatus) {
            case 'verifying':
                return (
                    <React.Fragment>
                        <h3>Verifying Email</h3>
                        <p>Please wait while your email is being verified...</p>
                        <Loading />
                    </React.Fragment>
                );
            case 'success':
                return (
                    <div className='verify-success'>
                        <header className='verify-success-header'>
                            <div className='verify-success-title'>
                                <img id='success-shield' alt='Success shield' src={successShield} />
                                <h6>Congratulations!</h6>
                            </div>
                            <p className='verify-success-subtitle'>{message}</p>
                        </header>
                        <section className='verify-success-section'>
                            <hr />
                            <h6>Please open Virbela application</h6>
                            <p className='verify-success-subtitle'>If you haven't already, download and install using the links below.</p>
                            <div className='download-buttons'>
                                <button id='mac-download' className='bttn danger' onClick={() => window.open(macDownloadUrl, '_blank')}>Download Mac</button>
                                <button id='pc-download' className='bttn danger' onClick={() => window.open(windowsDownloadUrl, '_blank')}>Download PC</button>
                            </div>
                            <hr />
                        </section>
                        <section>
                            <p>If you are a Team Suite or Campus Owner, access your admin tools <span className='link' onClick={() => this.props.history.push('/profile/subscriptions')}>here</span>.</p>
                        </section>
                    </div>
                );
            case 'error':
                return (
                    <div className='verify-failure'>
                        <header className='verify-failure-header'>
                            <div className='verify-failure-title'>
                                <img id='verify-letter' className='filter-red' alt='Verify Letter' src={verifyLetter} height={20} />
                                <h6>Verification Failed</h6>
                            </div>
                            <p className='verify-failure-subtitle'>{message}</p>
                            {!alreadyVerified && <div className='link' onClick={this.resendEmail}>{isResending ? 'Sending verification email...' : 'Resend verification email?'}</div>}
                        </header>
                        <section className='verify-failure-section'>
                            <hr />
                            <h6>Please open Virbela application</h6>
                            <p className='verify-failure-subtitle'>If you haven't already, download and install using the links below.</p>
                            <div className='download-buttons'>
                                <button id='mac-download' className='bttn danger' onClick={() => window.open(macDownloadUrl, '_blank')}>Download Mac</button>
                                <button id='pc-download' className='bttn danger' onClick={() => window.open(windowsDownloadUrl, '_blank')}>Download PC</button>
                            </div>
                            <hr />
                        </section>
                        {alreadyVerified && (
                            <section>
                                <p>If you are a Team Suite or Campus Owner, access your admin tools <span className='link' onClick={() => this.props.history.push('/profile/subscriptions')}>here</span>.</p>
                            </section>
                        )}
                </div>
                );
            default:
                return (
                    <React.Fragment>
                        <h3>Error</h3>
                        <p>Something went wrong.</p>
                    </React.Fragment>
                );
        }
    }

    render() {
        return (
            <section className='registerBackground' style={{position: 'relative'}}>
                <Container className='left minHeightNoHeader spacing flex-center' style={{ padding: '15px 0' }}>
                    <Row className='info-box'>
                        <Col xs={12} md={{size: 6 }} className='left-col'>
                            <div className='left-col-center'>
                                <div style={{margin: '10px 0'}} className='center'><img src='https://s3-us-west-2.amazonaws.com/opencampus.virbela.com/images/logo.png' alt='virbela-logo' height='80' /></div>
                                <div className='center'><p> Designed to enable companies to grow and scale in the cloud. Try now for free!</p></div>
                            </div>
                        </Col>
                        <Col xs={12} md={{ size: 6, offset: 0 }}>
                            <section className='customForm verifyForm center'>
                                {this.renderStatus()}
                            </section>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}