import React from 'react';
import { OauthReceiver } from 'react-oauth-flow';
import * as AppConfig from '../constants/AppConfig';
import Stripe from './../actions/stripe';
import Loading from './../component/loading';
import Error from './../component/error';
import { Container } from 'reactstrap';

export default class StripeOAuth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            error: null
        }
    }

    componentDidMount() {
        this._isMounted = true;
        window.setTimeout(() => {
            if(this._isMounted) {
                if(!this.state.error)
                    this.props.history.push('/referrals');
            }
        }, 3000);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSuccess = async (accessToken, { response, state }) => {
        if(this._isMounted) {
            console.log('Successfully authorized');
            this.props.history.push(state.from);
        }
      };
     
      handleError = error => {
        if(this._isMounted) {
            console.error('An error occured');
            this.setState({isLoading: false, error: error.message || 'error'});
        }
      };

      fetchOAuthToken = () => {
        return new Promise( async (resolve, reject) => {
            const urlParams = new URLSearchParams(window.location.search);
            let code = urlParams.get('code');
            try {
                let res = await Stripe.fetchOAuthToken(code);
                if(this._isMounted) {
                    console.log(res);
                    resolve(res);
                }
            } catch(error) {
                if(this._isMounted) {
                    reject(error);
                }
            }
        })

      }

    renderData = () => {
        if(this.state.isLoading) {
            return (
                <Loading />
            );
        } else if(this.state.error) {
            return (
                <Error color='danger' message={this.state.error} />
            );
        }
    }

    render() {
        return (
            <Container className='minHeightNoHeader'>
                <div style={{marginTop: '20%'}}>
                    <OauthReceiver
                        tokenUrl=''
                        clientId={AppConfig.default.ClientId}
                        clientSecret={''}
                        redirectUri={AppConfig.default.RedirectURI}
                        onAuthSuccess={this.handleSuccess}
                        onAuthError={this.handleError}
                        tokenFn={this.fetchOAuthToken}
                    />
                    {this.renderData()}
                </div>
            </Container>
        );
    }
}