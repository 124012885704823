import React from 'react';
import RoomPassword from './roomPassword';
import { Container, Row, Col, UncontrolledPopover, PopoverBody } from 'reactstrap';
import PermissionList from './permissionList';
import WebBoardPermissions from './webBoardPerms';
import { MdInfoOutline } from "react-icons/md";

export default class SecuritySettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addModalOpen: false,
        }
    }

    toggleAddModal = () => {
        this.setState({addModalOpen: !this.state.addModalOpen})
    }

    //TODO: Add custom TS spawning back in
    render(){
        return (
            <div>
                <Row>
                    <Col xs='12'>
                        <h3>Credentials</h3>
                    </Col>
                </Row>
                <Container>
                    <RoomPassword {...this.props} />
                </Container>
                <hr />
                <div className='containerBg'>
                    <WebBoardPermissions roomSecurity={this.props.room} updateRoomSecurities={this.props.updateRoomSecurities}/>
                </div>
                <hr />
                <div className='containerBg'>
                    <Row>
                        <Col xs='7'>
                            <h3><span id='permission-info'><MdInfoOutline id='SentInvInfo' size='1em' className='shift-icon'/></span> Access</h3>
                        </Col>
                        <Col className='right' style={{marginBottom: '10px'}}>
                            <button className='bttn outline primary' onClick={this.toggleAddModal}>Add</button>
                        </Col>
                    </Row>
                    <Container style={{minHeight: '30vh'}}>
                        <PermissionList roomSecurity={this.props.room} ownerId={this.props.room.get('subscriber').id} isOpen={this.state.addModalOpen} toggleModal={this.toggleAddModal} updateRoomSecurities={this.props.updateRoomSecurities}/>
                    </Container>
                </div>
                <UncontrolledPopover trigger='click hover' placement='top' target='permission-info'>
                    <PopoverBody>
                        <div>
                            <div><b>Suspended</b>: No access to Team Suite</div>
                            {/* <div><b>Member</b>: Team Suite in to GOTO menu.</div> */}
                            <div><b>Moderator</b>: Permissions such as changing image boards, room labels, etc.</div>
                            <div><b>Admin</b>: Moderator + Access dashboard. They cannot delete the subscription.</div>
                            <div><b>Owner</b>: Same permissions as admin</div>
                            {/* <div><b>Start Here</b>: Users will start in the Team Suite upon login.</div> */}
                            {/* <div><b>Disable Menu</b>: Users will not have access to their GOTO Menu</div> */}
                        </div>
                    </PopoverBody>
                </UncontrolledPopover>
            </div>
        );
    }
}