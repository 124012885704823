import React from 'react';
import { Alert, Form, FormGroup, Input, Col, Button, Spinner } from 'reactstrap';
import Stripe from './../actions/stripe';
import { IoIosCheckmarkCircleOutline, IoIosClose } from "react-icons/io";

export default class ApplyCoupon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: '',
            couponCode: sessionStorage.getItem('Coupon_'+this.props.plan.product)|| '',
            coupon: null,
            resellerCoupon: null
        }
    }

    componentDidMount() {
        this._isMounted = true;
        // this.setState({isLoading: true, couponCode: 'Searching for code...'});
        // let refCoupon = await checkReferralDiscount(this.props.plan).catch(error => console.log(error.message));
        // this.setState({isLoading: false, coupon: refCoupon, couponCode: (!!refCoupon)? 'Referral Promotion Code' : (sessionStorage.getItem('Coupon_'+this.props.plan.product) || '')});
        // if(!!refCoupon) this.props.updateState({coupon: refCoupon, resellerCoupon: null});
        if(this.state.couponCode){
           this.verifyCoupon();
        }   
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    updateInput = (e) => {
        this.setState({[e.target.id]: e.target.value});
    }

    verifyCoupon = async (e) => {
        if(e){
            e.preventDefault();
        }
        if(!!this.state.coupon) return;
        this.setState({isLoading: true});
        try {
            let {coupon, resellerCoupon} = await Stripe.verifyStripeCoupon(this.state.couponCode, this.props.plan);
            if(this._isMounted) {
                this.setState({isLoading: false, error: null, coupon, resellerCoupon});
                this.props.updateState({coupon: coupon, resellerCoupon});
            }
        } catch(error) {
            if(this._isMounted) {
                this.setState({isLoading: false, error: error.message});
            }
        }
    }

    renderButton = () => {
        if(this.state.isLoading) {
            return (
                <Spinner color='primary' />
            );
        } else if(!!this.state.coupon && !this.state.error) {
            return (
                <IoIosCheckmarkCircleOutline color='green' size='1.5em' style={{transform: 'translateY(5px)'}}/>
            );
        } else {
            return (
                <Button color='primary' outline onClick={this.verifyCoupon}>Apply</Button>
            );
        }
    }

    cancelCoupon = (e) => {
        if(this.state.isLoading) return;
        this.setState({isLoading: false, error: '', couponCode: '', coupon: null, resellerCoupon: null});
        this.props.updateState({coupon: null, resellerCoupon: null});
    }

    render() {
        let coupon = this.state.coupon;
        let discount = (!!coupon && !!coupon.percent_off)? (coupon.percent_off + '%') : (!!coupon && !!coupon.amount_off)? ('$' + coupon.amount_off/100) : null;
        let duration = (!!coupon && (coupon.duration === 'once'))? 'first payment' : (!!coupon && (coupon.duration === 'repeating'))? ((coupon.duration_in_months > 1)? `next ${coupon.duration_in_months} payments` : 'first payment') : null; 
        return (
            <div>
                <Alert color={!this.state.error? 'info' : 'danger'} className='center'>
                    <div className='hover' style={{position: 'absolute', top: '5px', right: '10px'}} onClick={this.cancelCoupon}><IoIosClose size='1.25em' /></div>
                    {(this.props.hideText && (!coupon || coupon.metadata.nonprofit !== 'true'))? '' : (!this.state.coupon)? <p>Do you have a coupon?</p> : <div style={{padding: '5px'}}><p>{discount} off {duration}{(coupon.metadata.nonprofit === 'true')? <span>. Send proof of non-profit status to <a href='mailto:accountsreceivable@virbela.com'>accountsreceivable@virbela.com</a></span> : ''}</p></div>}
                    <Form onSubmit={this.verifyCoupon} style={{paddingTop: '10px'}}>
                        <FormGroup row>
                            <Col xs={{size: 8}} sm={{size: 8, offset: 1}}><Input id='couponCode' name='couponCode' placeholder='Enter coupon code...' value={this.state.couponCode} onChange={this.updateInput} readOnly={!!this.state.coupon}></Input></Col>
                            <Col xs={{size: 4}} sm={{size: 2}}>{this.renderButton()}</Col>
                        </FormGroup>
                    </Form>
                </Alert>
            </div>
        );
    }
}