import React, {useEffect} from 'react';
import Auth from './../actions/auth';

export default function Logout(props) {
    useEffect(() => {
        Auth.logout();
        window.history.back();
    }, [Auth]);

    return <section className='pageDefaultBg'>
        <div className='minHeightNoHeader spacing' />
    </section>
}