import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import Auth from './../actions/auth';
import { Alert, Container } from 'reactstrap';
import './register.css';
import Error from './../component/error';

export default withAuth(class OktaAuth extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      authenticated: null,
      error: '',
      status: 'Authenticating Okta account...'
    };
    this.checkAuthentication = this.checkAuthentication.bind(this);
    this.checkAuthentication();
  }

  componentDidMount() {
    this._isMounted = true;
    if(Auth.isAuthenticated()) {
      this.props.history.push('/profile/subscriptions');
    } else {
      this.checkAuthentication();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    try {
      if(prevState.authenticated !== this.state.authenticated) {
        if(prevState.authenticated === null) {
          let [access_token, oktaUser] = await Promise.all([this.props.auth.getAccessToken(), this.props.auth.getUser()]);
          if(!this._isMounted) return;
          this.setState({status: 'Fetching user data...'});
          if(!!access_token && !!oktaUser && !!oktaUser.sub) {
            await Auth.oktaLogin(access_token, oktaUser.sub);
            if(!this._isMounted) return;
            // eslint-disable-next-line
            let redirectData = JSON.parse(document.cookie.replace(/(?:(?:^|.*;\s*)redirectData\s*\=\s*([^;]*).*$)|^.*$/, "$1") || '{}');
            document.cookie = 'redirectData=""; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;';
            this.props.history.push({
              pathname: redirectData.prevPage || '/profile/subscriptions',
              data: redirectData.data
            });
          } else {
            this.setState({error: 'Error fetching user data'});
          }
        }
      }
      this.checkAuthentication();
    } catch(error) {
      if(!this._isMounted) return;
      this.setState({error: error.message});
      if(this.state.authenticated) {
        this.props.auth.logout('/oktaAuth');
      }
    }
  }

  async checkAuthentication() {
    const authenticated = await this.props.auth.isAuthenticated();
    if (authenticated !== this.state.authenticated) {
      this.setState({ authenticated });
    }
  }

  renderOktaStatus = () => {
    if(this.state.error) {
      let error = (this.state.error === 'Email/displayname is required')? 'Passport account not linked to a Virbela account.' : null;
      return (
        <Error>
          {(error)? (
            <React.Fragment>{error} Manage your passport account at <a rel='noopener noreferrer' href='https://exp.virbela.com/login'>exp.virbela.com</a></React.Fragment>
          ) : (
            this.state.error
          )}
        </Error>
      );
    } else {
      return (
        <Alert color='info' className='center'>{this.state.status}</Alert>
      );
    }
  }

  render() {
    return (
      <section className='registerBackground' style={{position: 'relative'}}>
        <div style={{position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0, 0.20)'}}/>
        <Container className='minHeightNoHeader spacing'>
          <div style={{transform: 'translateY(100%)'}}>
            {this.renderOktaStatus()}
          </div>
        </Container>
      </section>
    );
  }
  
});