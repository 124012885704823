import Parse, { Cloud } from 'parse';
import AppConfig from './../constants/AppConfig';

const Invitation = Parse.Object.extend('Invitation');
const Account = Parse.Object.extend('Account');
const RoomSecurity = Parse.Object.extend('RoomSecurity');

export function createInvitations(emailList, roomSecurity, passcodeIdx, callBack = (progress) => { return; }) {
    return new Promise( async (resolve, reject) => {
        try {
            if(!Array.isArray(emailList)) throw new Error('Email list must be an Array');
            if(emailList.length < 1) throw new Error('Email list must contain emails');
            if(!passcodeIdx && passcodeIdx !== 0) throw new Error('Must choose a passcode for invitation');

            let i = 0;
            callBack({percentage: (i/emailList.length)*100, email: emailList[i]});
            for(const email of emailList) {
                i++;
                if(!email || ((typeof email) !== 'string')) {
                    callBack({percentage: (i/emailList.length)*100, email: emailList[i]});
                    continue;
                }
                let invitation = new Invitation();
                let inv = await invitation.save({
                    'email': email.trim(),
                    account: Account.createWithoutData(roomSecurity.get('account').id),
                    invitedBy: Parse.User.current(),
                    roomSecurity: roomSecurity
                });
                Cloud.run('sendInvitation', {
                    invitationId: inv.id,
                    email: invitation.get('email'),
                    subId: roomSecurity.get('subscription').id,
                    passcodeIdx
                });
                callBack({percentage: (i/emailList.length)*100, email: emailList[i]});
            }
            resolve('Successfully sent email(s)');
        } catch(error) {
            reject(error);
        }
    });
}

export function fetchInvitations(roomSecurity, page = 0, sort = {field: 'updatedAt', descening: true}) {
    return new Promise(async (resolve, reject) => {
        try {
            let invQuery = new Parse.Query(Invitation);
            invQuery.equalTo('account', Account.createWithoutData(roomSecurity.get('account').id));
            (sort.descending !== false)? invQuery.descending(sort.field || 'updatedAt') : invQuery.ascending(sort.field || 'updatedAt');
            invQuery.limit(AppConfig.Pagination);
            invQuery.skip(AppConfig.Pagination*page);
            let invitations = await invQuery.find();
            resolve(invitations);
        } catch(error) {
            reject(error);
        }
    });
}

export function getInvitationCount(roomSecurity) {
    return new Promise(async (resolve, reject) => {
        try {
            let invQuery = new Parse.Query(Invitation);
            invQuery.equalTo('account', Account.createWithoutData(roomSecurity.get('account').id));
            let invCount = await invQuery.count();
            resolve(invCount);
        } catch(error) {
            reject(error);
        }
    });
}

export function updateInviteOnly(value, roomSecurityId) {
    return new Promise(async (resolve, reject) => {
        try {
            let rs = RoomSecurity.createWithoutData(roomSecurityId);
            let updatedRs = await rs.save({inviteOnly: value});
            resolve(updatedRs);
        } catch(error) {
            reject(error);
        }
    });
}

export function resendInvitation(invitationId, email, subId, passcodeIdx) {
    return new Promise(async (resolve, reject) => {
        try {
            let inv = Invitation.createWithoutData(invitationId);
            await inv.save({invitedBy: Parse.User.current()});
            await Cloud.run('sendInvitation', { invitationId, email, subId, passcodeIdx });
            resolve('Invitation successfully resent!');
        } catch(error) {
            reject(error);
        }
    });
}

export function removeInvitation(invitationId) {
    return new Promise(async (resolve, reject) => {
        try {
            let inv = Invitation.createWithoutData(invitationId);
            let deletedInv = await inv.destroy();
            resolve(deletedInv);
        } catch(error) {
            reject(error);
        }
    });
}