import React from 'react';
import Loading from './../loading';
import Error from './../error';
import { Row, Col, Media } from 'reactstrap';
import './product.css';

export default class Features extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            error: '',
            product: null
        }
    }

    componentDidMount() {
        this.setState({isLoading: false, product: this.props.product});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.product !== this.props.product) {
            this.setState({product: this.props.product});
        }
    }

    renderFeature = (product) => {
        function featuress(name) {
            let [title, desc] = product.metadata[name].split(';');
            let points = desc.split('~');
            return (
                <Col className='center highlights' key={name} xs={12} md={6} lg={4}>
                    {(!!product.metadata[(name + '_image')]) && (
                        <div className='hover' onClick={() => {window.open(product.metadata[(name + '_image')], '_blank')}}>
                            <Media className='image' object src={product.metadata[(name + '_image')]} width='85%' alt={title}/>
                        </div>
                    )}
                    <div>
                        <h5>{title}</h5>
                        {(points.length <= 1)? <div><font size='2'>{desc}</font></div> :
                            <div><ul>{points.map((point, idx) => <li className='details' key={`${title}_detail_${idx}`}><font size='2'>{point}</font></li>)}</ul></div>
                        }
                    </div>
                </Col>
            );
        }

        let idx = 1;
        let features = []
        while(true) {
            let feature = product.metadata[('feature' + idx)];
            if(!feature) break;
            features.push(featuress('feature' + idx));
            idx++;
        }
        return features;
    }

    renderFeatures = () => {
        if(this.state.isLoading) {
            return <Loading />
        } else if(!!this.state.error) {
            return <Error message={this.state.error} />
        } else if(!!this.state.product.metadata.feature1){
            let product = this.state.product;
            return (
                <div className='left'>
                    <Row className='highlightTitle'>
                        <Col>
                            <h3>What's the Experience Like?</h3>
                        </Col>
                    </Row>
                    <Row>
                        {this.renderFeature(product)}
                    </Row>
                    <hr />
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <div>{this.renderFeatures()}</div>
        );
    }
}