/** @format */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardDeck,
  UncontrolledPopover,
  PopoverBody,
  Row,
  Col,
} from 'reactstrap';
import LoginModal from '../component/loginModal';
import auth from '../actions/auth';
import './homepage.css';

export default class Homepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openLoginModal: false,
      email: '',
      selectedPlan: '',
    };

    this.planRef = React.createRef();
  }

  componentDidMount() {
    const { history, location } = this.props;
    if (auth.isAuthenticated()) {
      history.push('/profile/subscriptions');
    }

    if (!!location.state && !!location.state.email) {
      this.setState({ email: location.state.email });
      this.toggleLogin();
    } else if (location.pathname === '/login') {
      this.toggleLogin();
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.key !== prevProps.location.key) {
      this.toggleLogin();
    }
  }

  toggleLogin = () => {
    const { history, location } = this.props;
    if (auth.isAuthenticated()) {
      const { selectedPlan } = this.state;
      history.push({
        pathname: location.prevPage || '/profile/subscriptions',
        state: { planId: selectedPlan },
        data: location.data,
      });
    } else {
      const { openLoginModal } = this.state;
      this.setState({ openLoginModal: !openLoginModal });
    }
  };

  selectPlan = async (e) => {
    e.preventDefault();
    const { history } = this.props;
    history.push('/teamsuites');
  };

  scrollToPlans = () => {
    window.scrollBy({
      top: this.planRef.current.offsetTop - window.scrollY - 20,
      left: 0,
      behavior: 'smooth',
    });
  };

  redirect = (e) => {
    const { redirectUrl } = e.currentTarget.dataset;
    if (!redirectUrl) return;
    window.location.href = redirectUrl;
  };

  render() {
    const { openLoginModal, email } = this.state;
    return (
      <section className="background">
        <div style={{ position: 'relative', height: '95vh' }}>
          <div className="header" style={{ position: 'absolute', width: '100%' }} />
          <div style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0, 0.6)' }} />
          <div style={{ position: 'absolute', width: '96%', top: '25%', color: 'white', paddingLeft: '2%' }}>
            <div>
              <Row>
                <Col>
                  <h1 className="display-4">Choose Your Experience</h1>
                </Col>
              </Row>
              <Row>
                <Col xs={{ size: 10, offset: 1 }} md={{ size: 6, offset: 3 }}>
                  <p className="lead">
                    From cloud-based co-working to custom virtual environments, we have the tools you need to start,
                    scale, and operate your business from anywhere in the world
                  </p>
                  <p className="lead">
                    <button type="button" className="bttn primary" onClick={this.scrollToPlans}>
                      Choose Now !
                    </button>
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div ref={this.planRef}>
          <Container>
            <CardDeck style={{ marginTop: '20px', paddingBottom: '40px' }}>
              <Card className="cards">
                <CardTitle className="title">
                  <h3>Open Campus</h3>
                </CardTitle>
                <CardBody>
                  <CardSubtitle className="subtitle">Always Free</CardSubtitle>
                  <CardSubtitle className="subText">
                    Organize events, meet with your team, and grow your business in our Open Campus.
                  </CardSubtitle>
                  <br />
                  <hr />
                  <h5>Features</h5>
                  <ul className="left">
                    <li>Unlimited Talk Time</li>
                    <li>Unlimited File Sharing</li>
                    <li>Customizable Avatar</li>
                    <li>Presenter Tools</li>
                    <li>Private Chat</li>
                    <li>Team Building Games</li>
                    <li>Access to Campus Events</li>
                  </ul>
                </CardBody>
                <button
                  type="button"
                  className="cardButton bttn outline primary"
                  data-redirect-url="https://opencampus.virbela.com/register?redirect=download"
                  onClick={this.redirect}
                >
                  Try Now
                </button>
              </Card>
              <Card className="cards">
                <CardTitle className="title">
                  <h3>Team Suite</h3>
                </CardTitle>
                <CardBody>
                  <CardSubtitle className="subtitle">Starting at $100/mo</CardSubtitle>
                  <CardSubtitle className="subText">
                    Stay connected to your community while scaling up with a private suite inside our Open Campus
                  </CardSubtitle>
                  <hr />
                  <h5>Features</h5>
                  <ul className="left">
                    <li>All Open Campus Features</li>
                    <li>
                      Personal Suite for 10 people{' '}
                      <span id="AsteriskPopover">
                        <font color="blue" size="4">
                          *
                        </font>
                      </span>
                    </li>
                    <li>Branded Locations</li>
                    <li>Customized Interiors</li>
                    <li>Technical Support</li>
                    <li>Administrator Accounts</li>
                    <li>Private Meeting Rooms</li>
                    <li>Board Room</li>
                  </ul>
                </CardBody>
                <button
                  type="button"
                  id="Monthly Plan"
                  className="cardButton bttn outline primary"
                  onClick={this.selectPlan}
                >
                  Get Started
                </button>
              </Card>
              <Card className="cards">
                <CardTitle className="title">
                  <h3>Private Campus</h3>
                </CardTitle>
                <CardBody>
                  <CardSubtitle className="subtitle">Contact Us</CardSubtitle>
                  <CardSubtitle className="subText">
                    Let us help you build a virtual environment that is perfect for your business needs.
                  </CardSubtitle>
                  <br />
                  <hr />
                  <h5>Features</h5>
                  <ul className="left">
                    <li>Branded App</li>
                    <li>Fully Customizable Environments</li>
                    <li>Custom Simulations and Training Programs</li>
                    <li>24/7 Technical Support</li>
                    <li>Performance Tracking and Analysis</li>
                    <li>Organizational Development and Management Consultations</li>
                  </ul>
                </CardBody>
                <button
                  type="button"
                  className="cardButton bttn outline primary"
                  data-redirect-url="https://www.virbela.com/contact-us"
                  onClick={this.redirect}
                >
                  Contact Us
                </button>
              </Card>
            </CardDeck>
          </Container>
        </div>
        <UncontrolledPopover placement="right" target="AsteriskPopover" trigger="hover">
          <PopoverBody>
            You may choose to invite as many users as you wish to join you in your Team Suite, provided the total number
            of users inside the Team Suite does not exceed your user limit at any given time.
          </PopoverBody>
        </UncontrolledPopover>
        {openLoginModal && <LoginModal toggleLogin={this.toggleLogin} email={email} {...this.props} />}
      </section>
    );
  }
}
