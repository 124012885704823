import React from 'react';
import stripe from './../actions/stripe';
import { Row, Col, Collapse, Card, CardBody, ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, UncontrolledPopover, PopoverBody } from 'reactstrap';
import CheckoutForm from './../component/checkoutForm';
import { Elements, StripeProvider } from 'react-stripe-elements';
import * as AppConfig from './../constants/AppConfig';
import Notifications from './../actions/notifications';
import { IoIosCheckmarkCircle, IoIosHelpCircleOutline } from 'react-icons/io';
import Loading from './loading';
import './paymentMethods.css';
import Error from './error';

export default class PaymentMethods extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stripe: null,
            isLoading: true,
            paymentMethods: null,
            defaultSource: null,
            error: null,
            openCardModal: false,
            formData: {},
            collapse: {},
            openRemoveModal: false,
            selected: null,
        }
    }

    componentDidMount() {
        this._isMounted = true;

        if (window.Stripe) {
            this.setState({stripe: window.Stripe(AppConfig.default.StripeKey)});
        } else {
            document.querySelector('#stripe-js').addEventListener('load', () => {
            // Create Stripe instance once Stripe.js loads
            this.setState({stripe: window.Stripe(AppConfig.default.StripeKey)});
            });
        }

        stripe.getPaymentMethods((customer, error) => {
            if(this._isMounted) {
                this.setState({isLoading: false, paymentMethods: customer? customer.sources : null, defaultSource: customer? customer.default_source : null, error: error});
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggle = (index) => {
        let collapse = this.state.collapse;
        collapse[index] = !(collapse[index])
        this.setState({ collapse: collapse });
    }

    fetchPaymentMethods = () => {
        this.setState({isLoading: true, paymentMethods: null, error: null, defaultSource: null});
        stripe.getPaymentMethods((customer, error) => {
            if(this._isMounted) {
                this.setState({isLoading: false, paymentMethods: customer? customer.sources: null, defaultSource: customer? customer.default_source : null, error: error, collapse: {}});
            }
        });
    }

    toggleCardModal = () => {
        this.setState({openCardModal: !this.state.openCardModal});
    }

    setFormData = (data) => {
        this.setState({formData: data})
    }

    renderPaymentMethods = () => {
        if(!!this.state.error) {
            return (
                <Error color='danger' message={this.state.error} />
            );
        } else if(!this.state.paymentMethods.data.length) {
            return (
                <Error color='warning' message='No payment methods added' />
            );
        } else {
            return (
                <Row>
                    <Col sm={{size: 12}} lg={{size: 10}}>
                        <ListGroup>
                            <ListGroupItem>
                                <Row>
                                    <Col>
                                        <strong>Payment Methods </strong>
                                        <span id='dCardPopover'>
                                            <IoIosHelpCircleOutline size='1em' />
                                        </span>
                                        <UncontrolledPopover placement='right' target='dCardPopover' trigger='hover'>
                                            <PopoverBody>Click check mark to set default card</PopoverBody>
                                        </UncontrolledPopover>
                                    </Col>
                                    <Col className='center'>
                                        <strong>Expires</strong>
                                    </Col>
                                    <Col />
                                </Row>
                            </ListGroupItem>
                            {this.displayPaymentMethods(this.state.paymentMethods.data)}
                        </ListGroup>
                    </Col>
                </Row>
            );
        }
    }

    displayPaymentMethods = (paymentMethods) => {
        return paymentMethods.map( (paymentMethod_, index) => {
            let paymentMethod = paymentMethod_;
            if(!!paymentMethod.card) paymentMethod = paymentMethod.card;
            let isACH = paymentMethod.type === 'ach_credit_transfer';
            return (
                <div key={'paymentMethods_' + index}>
                    <ListGroupItem>
                        <Row>
                            <Col>
                                {(isACH)? `ACH Credit Transfer` : `${paymentMethod.brand} ending in ${paymentMethod.last4}`}
                                {(this.state.defaultSource === paymentMethod_.id)? (
                                    <IoIosCheckmarkCircle id='defaultCard' color='green' className='paymentMethod' />
                                ) : (!isACH)? (
                                    <span className='paymentMethod notDefault'  onClick={ () => this.updateDefaultPaymentMethod(paymentMethod_.id)}>
                                    <IoIosCheckmarkCircle />
                                    </span>
                                ) : null}
                                {!!this.state.paymentMethods.data.length && (
                                    <UncontrolledTooltip placement="right" isOpen={!!this.state.tooltipOpen} target="defaultCard" toggle={() => {this.setState({tooltipOpen: !this.state.tooltipOpen})}}>
                                        default card
                                    </UncontrolledTooltip>
                                )}
                            </Col>
                            {(!isACH)? <Col className='center'>
                                {paymentMethod.exp_month + '/' + paymentMethod.exp_year}
                            </Col> : <Col />}
                            <Col className='center'>
                                <button className='bttn' onClick={() => this.toggle(index)}>details</button>
                            </Col>
                        </Row>
                    </ListGroupItem>
                    <Collapse isOpen={!!this.state.collapse[index]}>
                        <Card>
                            <CardBody>
                                {(!isACH)? 
                                    <>
                                        <div><strong>Billing Address</strong></div>
                                        <div>{paymentMethod.name || ''}</div>
                                        <div>{paymentMethod.address_line1 || ''}</div>
                                        {(!!paymentMethod.address_line2) && (<div>{paymentMethod.address_line2}</div>)}
                                        <div>
                                            <Row>
                                                <Col>{((paymentMethod.address_city || '') + (!!paymentMethod.address_state? (', ' + paymentMethod.address_state) : '') + (!!paymentMethod.address_zip? (' ' + paymentMethod.address_zip) : '')) || 'Information not provided'}</Col>
                                                <Col />
                                                <Col className='center'>
                                                    <button className='bttn outline danger' onClick={() => this.toggleRemoveModal(paymentMethod_)}>remove</button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </> : <Row>
                                        <Col xs={8}>
                                            <div><font size='4'>Transfer funds to the following account:</font></div> 
                                            <div><b>Bank Name</b>: {paymentMethod.ach_credit_transfer.bank_name}</div>
                                            <div><b>Routing number</b>: {paymentMethod.ach_credit_transfer.routing_number}</div>
                                            <div><b>Account number</b>: {paymentMethod.ach_credit_transfer.account_number}</div>
                                            <div><b>SWIFT code</b>: {paymentMethod.ach_credit_transfer.swift_code}</div>
                                        </Col>
                                        <Col className='center'>
                                            <button className='bttn outline danger' onClick={() => this.toggleRemoveModal(paymentMethod_)}>remove</button>
                                        </Col>
                                    </Row>
                                }
                            </CardBody>
                        </Card>
                    </Collapse>
                </div>
            );
        });
    }

    updateDefaultPaymentMethod = (paymentMethodId, successMessage ='Successfully updated!') => {
        this.setState({isLoading: true});
        stripe.updateCustomer({default_source: paymentMethodId}, (customer) => {
            if(this._isMounted) {
                this.setState({isLoading: false, paymentMethods: customer? customer.sources : this.state.paymentMethods, defaultSource: customer? customer.default_source : this.state.defaultSource, collapse: {}});
            }
        }, successMessage);
    }

    toggleRemoveModal = (paymentMethod = null) => {
        this.setState({openRemoveModal: !this.state.openRemoveModal, selected: paymentMethod});
    }

    removeModal = () => {
        if(this.state.selected) {
            return (
                <Modal isOpen={this.state.openRemoveModal} toggle={this.toggleRemoveModal} className={this.props.className}>
                    <ModalHeader>Delete Payment Method</ModalHeader>
                    <ModalBody>
                        {'Are you sure you want to remove ' + this.state.selected.brand + ' ending with ' + this.state.selected.last4 + '?'}
                    </ModalBody>
                    <ModalFooter>
                        <button className='bttn' onClick={this.toggleRemoveModal}>Cancel</button>
                        <button className='bttn danger' disabled={this.state.isLoading} onClick={() => this.removePaymentMethod(this.state.selected.id)}>Remove</button>
                    </ModalFooter>
                </Modal>
            );
        }
    }

    removePaymentMethod = (paymentMethodId) => {
        this.setState({isLoading: true});
        stripe.removePaymentMethod(paymentMethodId, () => {
            if(this._isMounted) {
                this.toggleRemoveModal();
                this.fetchPaymentMethods();
            }
        });
    }

    addedCard = (params) => {
        this.updateDefaultPaymentMethod(params.card.id, 'Successfully added payment method! If you have multiple cards, set the default by clicking on the check mark next to the card on the payments page.');
        this.fetchPaymentMethods();
    }

    render() {
        if(this.state.isLoading) {
            return (
                <div className='left'>
                    <div className='containerBg'>
                    <Row>
                        <Col>
                            <h1>Payment Methods</h1>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col className='center'>
                            <Loading />
                        </Col>
                    </Row>
                    </div>
                </div>
            );
        } else {
            return (
                <div className='left'>
                    <div className='containerBg'>
                        <Row>
                            <Col>
                                <h1>Payment Methods</h1>
                            </Col>
                            <Col sm={{size: 3}} className='right'>
                                <button className='bttn primary' disabled={!!this.state.error} onClick={() => { 
                                    if(this.state.paymentMethods.data.length > 9) {
                                        Notifications.addNotification('Error', 'You have reached the max amount of payment methods', 'error');
                                    } else {
                                        this.toggleCardModal();
                                    }}}>Add card</button>
                            </Col>
                        </Row>
                        <br />
                        {this.renderPaymentMethods()}
                    </div>
                    { this.state.openRemoveModal && (this.removeModal())}
                    { this.state.openCardModal && (
                        <StripeProvider stripe={this.state.stripe}>
                          <Elements>
                              <CheckoutForm toggleCardModal={this.toggleCardModal} saveForm={this.setFormData} formData={this.state.formData} addCardSuccess={this.addedCard}/>
                          </Elements>
                      </StripeProvider>
                    )}
                </div>
            );
        }
    }

}