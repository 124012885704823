import React, { useState, useEffect, useReducer } from 'react';
import stripe, {getSortedPlans} from '../../actions/stripe';
import {
  Alert,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';
import Loading from './../loading';

const editPlan = (props) => {
  const [state, dispatch] = useReducer(editPlanReducer, {
    isLoading: true,
    currentPlan: null,
    filteredPlans: null,
    selectedPlan: null,
    proratedCost: 0,
    prorationDate: 0,
    prorations: [],
    subscription: props.subscription,
    tax: 0,
    taxRate: 0,
    error: '',
  });
  const {
    isLoading,
    currentPlan,
    filteredPlans,
    selectedPlan,
    proratedCost,
    prorationDate,
    prorations,
    subscription,
    tax,
    taxRate,
    error,
  } = state;

  function editPlanReducer(state, action) {
    switch (action.type) {
      case 'loadPlans':
        return {
          ...state,
          isLoading: action.payload.loading,
          currentPlan: action.payload.currentPlan,
          filteredPlans: action.payload.filteredPlans,
        };
      case 'selectPlan':
        return {
          ...state,
          selectedPlan: action.payload.selectedPlan,
          proratedCost: action.payload.proratedCost,
          prorationDate: action.payload.prorationDate,
          prorations: action.payload.prorations,
          error: action.payload.error,
          tax: action.payload.tax,
          taxRate: action.payload.taxRate
        };
      case 'changePlan':
        return {
          ...state,
          isLoading: action.payload.isLoading,
          currentPlan: action.payload.currentPlan,
          subscription: action.payload.subscription,
          error: action.payload.error,
        };
      case 'loading':
        return {
          ...state,
          isLoading: action.payload.isLoading,
        };
      case 'error':
        return {
          ...state,
          isLoading: action.payload.isLoading,
          error: action.payload.error,
        };
      default:
        return state;
    }
  }

  useEffect(() => {
    async function loadPlans() {
      try {
        const currentPlan = subscription.plan;
        const currentPlanNickname = currentPlan.nickname;
        const planData = await getSortedPlans({ limit: 100, active: true, product: currentPlan.product});
        // Only display plans of the same type as the current plan
        const filteredPlans = planData.plans.filter((plan) => {
          const standardTsRegex = /(^Team Suite \(TS\d+[MYD]\))|(Team Suite \d+ \(Annual\))/;
          const auditoriumTsRegex = /^Auditorium \(T/;
          const conferenceHallTsRegex = /(^Conference Hall \(TS\d+[MY]\))|(^Conference Hall \(Team Suite)/;
          const auditoriumEventRegex = /^Auditorium \(\d+ Users( \+ Support)?\)/;
          const conferenceHallEventRegex = /^Conference Hall \(\d+ Users( \+ Support)?\)/;
          const { interval } = (!!plan && plan.recurring) || plan;
          //temporarily disallow switching from one interval to another
          if(currentPlan.interval !== interval) {
            return false;
          } else if (
            currentPlanNickname.startsWith('Team Suite') ||
            currentPlanNickname === 'Annual Plan' ||
            currentPlanNickname === 'Monthly Plan' ||
            currentPlanNickname === 'Daily Plan' // Only available on test
          ) {
            return standardTsRegex.test(plan.nickname);
          } else if (auditoriumTsRegex.test(currentPlanNickname)) {
            return auditoriumTsRegex.test(plan.nickname);
          } else if (conferenceHallTsRegex.test(currentPlanNickname)) {
            // Additional check will no longer be necessary once the three plans below are archived on the live dashboard
            const LIVE_CONFERENCEHALL_TS100M_DUPLICATE = 'plan_H1zWa440ZW6YiB';
            const LIVE_CONFERENCEHALL_TS60M = 'plan_GvGZ7DaMCi8ljK';
            const LIVE_CONFERENCEHALL_TSM = 'plan_Ge5Xekkstpnmtn';
            const excludedPlans = [
              LIVE_CONFERENCEHALL_TS100M_DUPLICATE,
              LIVE_CONFERENCEHALL_TS60M,
              LIVE_CONFERENCEHALL_TSM,
            ];
            if (excludedPlans.includes(plan.nickname)) return false;
            return conferenceHallTsRegex.test(plan.nickname);
          } else if (auditoriumEventRegex.test(currentPlanNickname)) {
            return auditoriumEventRegex.test(plan.nickname);
          } else if (conferenceHallEventRegex.test(currentPlanNickname)) {
            return conferenceHallEventRegex.test(plan.nickname);
          } else if (currentPlanNickname.startsWith('eXp Team Suite')) {
            return plan.nickname.startsWith('eXp Team Suite');
          } else if (
            currentPlanNickname.startsWith('Stanford Medical Team Suite')
          ) {
            return plan.nickname.startsWith('Stanford Medical Team Suite');
          } else {
            return false;
          }
        });
        dispatch({
          type: 'loadPlans',
          payload: {
            isLoading: false,
            currentPlan,
            filteredPlans,
          },
        });
      } catch (err) {
        dispatch({
          type: 'error',
          payload: {
            isLoading: false,
            error: err.messge,
          },
        });
      }
    }
    loadPlans();
  }, [props.plan]);

  function selectedCurrentPlan(selectedPlan, currentPlan) {
    const currPlanNickname = currentPlan.nickname;
    const selectedPlanNickname = selectedPlan.nickname;

    const monthlyPlanEquivalent =
      currPlanNickname === 'Monthly Plan' &&
      selectedPlanNickname === 'Team Suite 20 (Monthly)';

    const tsPlusEquivalent =
      currPlanNickname === 'Team Suite Plus (Monthly)' &&
      selectedPlanNickname === 'Team Suite 50 (Monthly)';

    const tsMiniEquivalent =
      currPlanNickname === 'Team Suite Mini (Monthly)' &&
      selectedPlanNickname === 'Team Suite 10 (Monthly)';

    const ts200Equivalent =
      currPlanNickname === 'Team Suite 200 (Monthly)' &&
      selectedPlanNickname === 'Team Suite (TS200M)';

    const ts100Equivalent =
      currPlanNickname === 'Team Suite 100 (Monthly)' &&
      selectedPlanNickname === 'Team Suite (TS100M)';

    const ts80Equivalent =
      currPlanNickname === 'Team Suite 80 (Monthly)' &&
      selectedPlanNickname === 'Team Suite (TS80M)';

    const ts30Equivalent =
      currPlanNickname === 'Team Suite 30 (Monthly)' &&
      selectedPlanNickname === 'Team Suite (TS30M)';

    const conferenceTs100Equivalent =
      currPlanNickname === 'Conference Hall (TS100M)' &&
      currentPlan.id === 'plan_H1zWa440ZW6YiB' &&
      selectedPlanNickname === 'Conference Hall (TS100M)';

    return (
      selectedPlan.nickname === currentPlan.nickname ||
      monthlyPlanEquivalent ||
      tsPlusEquivalent ||
      tsMiniEquivalent ||
      ts200Equivalent ||
      ts100Equivalent ||
      ts80Equivalent ||
      ts30Equivalent ||
      conferenceTs100Equivalent
    );
  }

  async function selectPlan(e) {
    const stringifiedPlan = e.target.value;
    if (!stringifiedPlan) {
      return dispatch({
        type: 'selectPlan',
        payload: {
          selectedPlan: null,
        },
      });
    }

    const plan = JSON.parse(stringifiedPlan);
    dispatch({
      type: 'selectPlan',
      payload: {
        selectedPlan: plan,
        error: '',
      },
    });

    const prorationData = await stripe.previewProration(
      subscription.id,
      currentPlan.id,
      plan.id
    );
    if (prorationData) {
      dispatch({
        type: 'selectPlan',
        payload: {
          selectedPlan: plan,
          proratedCost: prorationData.cost,
          prorationDate: prorationData.proration_date,
          prorations: prorationData.current_prorations,
          tax: prorationData.taxAmount,
          taxRate: prorationData.taxRate
        },
      });
    }
  }

  async function changePlan() {
    const currentSubscriptionId = props.subscription.id;
    try {
      const planResponse = await stripe.updatePlan(
        currentSubscriptionId,
        selectedPlan.id,
        prorationDate
      );

      if(planResponse.error) return;

      dispatch({
        type: 'changePlan',
        payload: {
          isLoading: false,
          currentPlan: planResponse.subscription.plan,
          subscription: planResponse.subscription,
          error: '',
        },
      });
      props.updateParentState({subscription: planResponse.subscription});
      return planResponse;
    } catch (error) {
      throw error;
    }
  }

  function isValidPlanToChange() {
    if (!selectedPlan) {
      dispatch({
        type: 'error',
        payload: {
          error: 'No plan selected.',
        },
      });
      return false;
    }

    if (selectedCurrentPlan(selectedPlan, currentPlan)) {
      dispatch({
        type: 'error',
        payload: {
          isLoading: false,
          error: 'You are already subscribed to this plan.',
        },
      });
      return false;
    }

    return true;
  }

  if (isLoading) {
    return <Loading />;
  } else {
    const currentPlanTitle = currentPlan
      ? `${currentPlan.nickname} — $${(
          currentPlan.amount / 100
        ).toLocaleString()}/${currentPlan.interval}`
      : '';

    let alertMessage = '';
    if (error) {
      alertMessage = error;
    }

    return (
      <Container className='center'>
        <Form>
          {alertMessage && (
            <FormGroup row>
              <Col>
                <Alert className='center' color={error ? 'danger' : 'success'}>
                  {alertMessage}
                </Alert>
              </Col>
            </FormGroup>
          )}
          <FormGroup row>
            <Label for='orgName' sm={3} className='left'>
              <b>Current Plan:</b>
            </Label>
            <Col sm={9} style={{ textAlign: 'left' }}>
              <Input type='input' value={currentPlanTitle} disabled />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='desc' sm={3} className='left'>
              <b>Change Plan:</b>
            </Label>
            <Col sm={9}>
              <Input
                type='select'
                id='select'
                name='select'
                defaultValue=''
                onChange={selectPlan}
              >
                <option value=''>Select a plan</option>
                {filteredPlans &&
                  filteredPlans.map((plan) => {
                    let { interval } = (!!plan && plan.recurring) || plan;
                    return (
                      <option
                        key={plan.id}
                        id={plan.id}
                        value={JSON.stringify(plan)}
                      >
                        {plan.nickname} — $
                        {((plan.unit_amount || plan.amount) / 100).toLocaleString()}/{interval}
                      </option>
                    );
                  })}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={{ size: 9, offset: 3 }} className='right'>
              <EditPlanButton
                changePlan={changePlan}
                isValidPlanToChange={isValidPlanToChange}
                currentPlan={currentPlan}
                selectedPlan={selectedPlan}
                proratedCost={proratedCost}
                prorations={prorations}
                subscription={subscription}
                tax={tax}
                taxRate={taxRate}
              />
            </Col>
          </FormGroup>
        </Form>
      </Container>
    );
  }
};

const EditPlanButton = ({
  changePlan,
  isValidPlanToChange,
  currentPlan,
  selectedPlan,
  proratedCost,
  prorations,
  subscription,
  tax,
  taxRate
}) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <div>
      <button
        className='bttn block outline primary'
        onClick={(e) => {
          e.preventDefault();
          if (!isValidPlanToChange()) return;
          toggle();
        }}
      >
        Preview
      </button>

      <ConfirmPlanModal
        modal={modal}
        setModal={setModal}
        changePlan={changePlan}
        currentPlan={currentPlan}
        selectedPlan={selectedPlan}
        proratedCost={proratedCost}
        prorations={prorations}
        subscription={subscription}
        tax={tax}
        taxRate={taxRate}
      />
    </div>
  );
};

const ConfirmPlanModal = ({
  modal,
  setModal,
  changePlan,
  currentPlan,
  selectedPlan,
  proratedCost,
  prorations,
  subscription,
  tax,
  taxRate
}) => {
  const [changing, setChanging] = useState(false);
  const toggle = () => setModal(!modal);

  if (!currentPlan || !selectedPlan) return null;
  let { interval } = (!!selectedPlan && selectedPlan.recurring) || selectedPlan;
  let amount = selectedPlan.unit_amount || selectedPlan.amount;

  function formatAndStringifyPrice(price) {
    if (typeof price !== 'number') return '';
    const formattedPrice = (price / 100).toFixed(2).toLocaleString();
    const formattedPriceString =
      price >= 0
        ? `$${formattedPrice}`
        : `-$${(formattedPrice * -1).toFixed(2).toLocaleString()}`;
    return formattedPriceString;
  }

  let endPeriod = subscription ? subscription.current_period_end : 0;
  if(currentPlan.interval !== interval){
    endPeriod = Math.floor(Date.now() / 1000);
    if(interval === 'month'){
      endPeriod += 2629743; //30.44 days
    }
    else if(interval === 'year'){
      endPeriod += 31556926; //365.24 days
    }
  }
  const localizedEndPeriod = endPeriod
    ? new Date(endPeriod * 1000).toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    : '';

  let formattedProratedCost = formatAndStringifyPrice(proratedCost+tax*100);
  const discount = subscription ? subscription.discount : null;
  const coupon = discount ? discount.coupon : null;
  const percentOff =
    discount && discount.coupon ? discount.coupon.percent_off : 0;
  const amountOff =
    discount && discount.coupon ? discount.coupon.amount_off : 0;
  if(currentPlan.interval !== interval){
    let newAmount = amount;
    if(!!amountOff && amountOff < amount){
      newAmount = newAmount-amountOff;
    } 
    else if(!!percentOff && percentOff <= 100){
      newAmount =newAmount * ((100 - percentOff) / 100);
    }
    proratedCost= proratedCost +newAmount;
    formattedProratedCost = formatAndStringifyPrice(proratedCost+tax*100);
  }
  const couponDuration =
    discount && discount.coupon ? discount.coupon.duration : 0;
  const couponDurationInMonths = 
    couponDuration && couponDuration === 'repeating' ? discount.coupon.duration_in_months : 0;
  let discountedPriceString =
    '$' +
    (((amount / 100) * (100 - percentOff)) / 100)
      .toFixed(2)
      .toLocaleString();
  let basePriceString =
    '$' + (amount / 100).toFixed(2).toLocaleString();
  if(taxRate !== 0){
    let discountedAmount = (((amount / 100) * (100 - percentOff)) / 100);
    discountedPriceString =
    '$' +
    (discountedAmount * (1 + taxRate))
      .toFixed(2)
      .toLocaleString();
    basePriceString =
    '$' + ((amount / 100) *(1+taxRate)).toFixed(2).toLocaleString();
  }
  const couponEndDate =
    discount && discount.end
      ? new Date(discount.end * 1000).toLocaleDateString(undefined, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      : '';

  // const prorationInfo = (
  //   <section>
  //     {prorations &&
  //       prorations.map((proration) => {
  //         return (
  //           <div key={proration.id} style={{ marginBottom: '10px' }}>
  //             <div style={{ fontWeight: 'bold' }}>
  //               {proration.plan.nickname}: {proration.description}
  //             </div>
  //             <div>{formatAndStringifyPrice(proration.amount)}</div>
  //           </div>
  //         );
  //       })}
  //     <div>
  //       <div style={{ fontWeight: 'bold' }}>
  //         Estimated Prorated Price for First Month
  //       </div>
  //       <div>{formattedProratedCost}</div>
  //     </div>
  //   </section>
  // );
  let oldProrated = null;
  let newProrated = null;

  for(let index in prorations){
    if(prorations[index].description.includes('Unused time')){
      oldProrated -= prorations[index].amount;
    }
    else if(prorations[index].description.includes('Remaining time')){
      newProrated = prorations[index].amount;
    }
  }
  const alertMessage = (
    <div style={{ textAlign: 'left' }}>
      <hr />
      <section>
        <div style={{ fontWeight: 'bold' }}>
          Current Plan: {currentPlan.nickname}
        </div>

        {currentPlan.metadata && currentPlan.metadata.size ? (
          <div>{currentPlan.metadata.size} concurrent users</div>
        ) : null}
        {currentPlan.metadata && currentPlan.metadata.layout ? (
          <div>{currentPlan.metadata.layout}</div>
        ) : null}

        <div>
          {formatAndStringifyPrice(currentPlan.amount)}/{currentPlan.interval}
          {percentOff ? (
            <b>
              {' '}
              + {percentOff}% discount →{' '}
              {percentOff === 100
                ? '$0'
                : formatAndStringifyPrice(
                    currentPlan.amount * ((100 - percentOff) / 100)
                  ) + `/${currentPlan.interval}`}
            </b>
          ) : null}
          {amountOff ? (
            <b>
              {' '}
              + ${amountOff/100} discount →{' '}
              {amountOff >= currentPlan.amount
                ? '$0'
                : formatAndStringifyPrice(
                    currentPlan.amount -amountOff
                  ) + `/${currentPlan.interval}`}
            </b>
          ) : null}
        </div>
        <div>
          {oldProrated !== null ? <b>({'Remaining value: ' + formatAndStringifyPrice(oldProrated)})</b>:null}
        </div>
      </section>

      <hr />

      <section>
        <div style={{ fontWeight: 'bold' }}>
          New Plan: {selectedPlan.nickname}
        </div>

        {selectedPlan.metadata && selectedPlan.metadata.size ? (
          <div>{selectedPlan.metadata.size} concurrent users</div>
        ) : null}
        {selectedPlan.metadata && selectedPlan.metadata.layout ? (
          <div>{selectedPlan.metadata.layout}</div>
        ) : null}

        <div>
          {formatAndStringifyPrice(amount)}/{interval}
          {percentOff ? (
            <b>
              {' '}
              + {percentOff}% discount →{' '}
              {percentOff === 100
                ? '$0'
                : formatAndStringifyPrice(
                    amount * ((100 - percentOff) / 100)
                  ) + `/${interval}`}
            </b>
          ) : null}
          {amountOff ? (
            <b>
              {' '}
              + ${amountOff/100} discount →{' '}
              {amountOff >= amount
                ? '$0'
                : formatAndStringifyPrice(
                    amount -amountOff
                  ) + `/${interval}`}
            </b>
          ) : null}
        </div>
        <div>
          {newProrated !==null ? <b>({'Remaining cost: ' + formatAndStringifyPrice(newProrated)})</b>:null}
        </div>
      </section>

      {/* <hr />
      {prorationInfo} */}
      <hr />

      {!formattedProratedCost ? (
        <Loading />
      ) : (
        <section>
          {(couponDuration === 'once' || couponDurationInMonths===1) ? (
            <span>Your one-time coupon is still in use. </span>
          ) : null}
          {proratedCost >= 0 ? (
            <span>
              {' '}
              {tax !== 0 ? 'Including estimated tax, you ' : 'You '} 
              will be charged <b>{formattedProratedCost}</b> for your
              current subscription period and pay{' '}
              <b>
                {!coupon || (couponDuration === 'once'|| couponDurationInMonths===1) 
                  ? basePriceString
                  : discountedPriceString}
                /{interval}
              </b>{' '}
              {localizedEndPeriod ? (
                <span>
                  starting <b>{localizedEndPeriod}</b>.
                </span>
              ) : (
                'thereafter.'
              )}
            </span>
          ) : (
            <span>
              {' '}
              {tax !== 0 ? 'Including estimated tax, you ' : 'You '} 
              will be credited <b>{formattedProratedCost.slice(1)}</b> for
              your current subscription period and pay{' '}
              <b>
                {!coupon || (couponDuration === 'once'|| couponDurationInMonths===1)
                  ? basePriceString
                  : discountedPriceString}
                /{interval}
              </b>{' '}
              {localizedEndPeriod ? (
                <span>
                  starting <b>{localizedEndPeriod}</b>.
                </span>
              ) : (
                'thereafter.'
              )}
            </span>
          )}

          {couponEndDate && (couponEndDate !== localizedEndPeriod) && (currentPlan.interval !=='year' && interval!=='year')? (
            <span>
              {' After your coupon expires on '}
              <b>{couponEndDate}</b>
              {', your new plan will cost '}
              <b>
                {basePriceString}/{interval}
              </b>
              .
            </span>
          ) : null}
        </section>
      )}
    </div>
  );

  async function updatePlan() {
    try {
      setChanging(true);
      await changePlan();
      setChanging(false);
      toggle();
    } catch (error) {
      setChanging(false);
    }
  }

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalBody style={{ paddingBottom: 0 }}>
        <Alert color='info' className='center'>
          <h4>Confirm Plan Change</h4>
          {alertMessage}
        </Alert>
      </ModalBody>
      <ModalFooter>
        <button className='bttn' onClick={toggle}>
          Cancel
        </button>
        {changing ? (
          <Spinner color='primary' />
        ) : (
          <button
            className='bttn primary'
            disabled={changing}
            onClick={updatePlan}
          >
            Change Plan
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default editPlan;
