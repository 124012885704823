import React, { Component } from 'react';
import './App.css';
import { Route, Switch } from 'react-router-dom';
import * as pages from './pages/index';
import NavBar from './component/navbar';
import Footer from './component/footer';
import { ProtectedRoute, SemiProtectedRoute, UnprotectedRoute } from './component/customRoutes';
import { Security } from '@okta/okta-react';
import OktaImplicitCallback from './component/oktaImplicitCallback';
import * as AppConfig from './constants/AppConfig';
import SessionLogin from './component/sessionLogin';

const config = {
  issuer: AppConfig.default.OktaIssuer,
  redirect_uri: window.location.origin + '/implicit/callback',
  client_id: AppConfig.default.OktaClientId
}

class App extends Component {
  render() {
    return (
      <div className='App'>
        <Security issuer={config.issuer}
                  client_id={config.client_id}
                  redirect_uri={config.redirect_uri}
        >
          <Route component={NavBar} />
          <Switch>
            <Route exact path='/' component={pages.Homepage} />
            <UnprotectedRoute path='/login' component={pages.Homepage} />
            <ProtectedRoute path='/profile' component={pages.Profile} />
            {/* <ProtectedRoute exact path='/referrals' component={pages.Referrals} /> */}
            <ProtectedRoute path='/referrals/oauth/' component={pages.StripeOAuth} />
            <UnprotectedRoute exact path='/register' component={pages.Register} />
            <SemiProtectedRoute exact path='/verify' component={pages.Verify} />
            <Route path='/verify/:value' component={pages.Verification} />
            <Route path='/reset_password/:value' component={pages.PasswordReset} />
            <ProtectedRoute path='/settings/room/:id' component={pages.RoomSettings} />
            <UnprotectedRoute path='/oktaAuth' exact component={pages.OktaAuth}/>
            <UnprotectedRoute path='/implicit/callback' component={OktaImplicitCallback}/>
            <UnprotectedRoute path='/passport' exact component={pages.OktaLogin} />
            <Route path='/products' component={pages.Products} />
            <Route path='/sessionLogin/:sessionToken' component={SessionLogin} />
            <Route path='/logout' component={pages.Logout} />
            <Route path='/:client' component={pages.ClientTeamSuites} /> {/*Put new routes above this*/}
            <Route path='*' component={pages.NotFound} />
          </Switch>
          <Route component={Footer}/>
        </Security>
        <div id="consent_blackbar"></div>
        <div id="teconsent"></div>
      </div>
    );
  }
}

export default  () => (
    <App />
);
