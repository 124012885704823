import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { IoLogoFacebook, IoLogoTwitter, IoLogoYoutube, IoIosMail } from 'react-icons/io';
import './footer.css';

export default class Footer extends React.Component {
    getCookiePolicy = () => {
        const consent = document.getElementById('teconsent');
        const cookieButton = consent && consent.querySelector('img');
        if (cookieButton) cookieButton.click();
    }
    render() {
        return (
            <div className='footer'>
                <Container style={{padding: '20px'}}>
                    <Row className='center'>
                        <Col md={5}>
                            <div className='justify icon'>
                                <img src='https://s3-us-west-2.amazonaws.com/opencampus.virbela.com/images/logo.png' alt='virbela-logo' height='70' onClick={() => {window.location.href='/'}}/>
                            </div>
                            <div style={{marginTop: '10px'}}>
                                <p><font color='white' size={2}>We specialize in collaboration, training, and custom development using Virtual Reality and 3D immersive technology.</font></p>
                            </div>
                        </Col>
                        <Col md={{size: 5, offset: 2}}>
                            <div className='footerElements'>
                                <span className='icon twitter'><IoLogoTwitter size='1.5em' onClick={() => window.open('https://twitter.com/VirbelaHQ', '_blank')} /></span>
                                <span className='icon facebook'><IoLogoFacebook size='1.5em' onClick={() => window.open('https://www.facebook.com/Virbela/', '_blank')} /></span>
                                <span className='icon youtube'><IoLogoYoutube size='1.5em' onClick={() => window.open('https://www.youtube.com/channel/UCDHaxbT5WkBMS--mZ5jSYJw', '_blank')} /></span>
                                <span className='icon' onClick={() => window.open('mailto:info@virbela.com?subject=inquiry', '_self')}><IoIosMail size='1.7em' /></span>
                                <p><font size={2} color='white'>See our <a href='https://assets.virbela.com/legal/VirBELA_TOU_EULA.pdf' target='_blank' rel='noopener noreferrer'>Terms of Use</a>, <a href='https://assets.virbela.com/legal/VirBELA_Privacy_Policy.pdf' target='_blank' rel='noopener noreferrer'>Privacy Policy</a> or <span className='cookie' onClick={this.getCookiePolicy}>Cookie Policy</span>.</font></p>
                            </div>
                            <br />
                            <br />
                            <br />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
    
}