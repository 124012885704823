import React from 'react';
import InvoiceSettings from './invoiceSettings';
import EditPlan from './editPlan';
import DeleteSubscription from './deleteSubscription';
import { MdInfoOutline } from "react-icons/md";
import { Alert, UncontrolledPopover, PopoverBody } from 'reactstrap';

export default class PaymentSettings extends React.Component {

    render() {
        return(
            <div>
                <Alert color='info' className='center'>Need Virbela support for your events? <a rel='noopener noreferrer' target="_blank" href={`${window.location.origin}/support`}>More details here</a>.</Alert>
                <div className='containerBg'>
                    <div style={{margin: '10px'}}>
                        <h3>Invoice Settings</h3>
                    </div>
                    <InvoiceSettings {...this.props} />
                </div>
                <hr />
                <div className='containerBg'>
                    <div style={{margin: '10px'}}>
                        <h3><MdInfoOutline id='plan-upgrade-info' size='1em' className='shift-icon'/> Change Plan</h3>
                    </div>
                    <EditPlan {...this.props} />
                </div>
                <hr />
                <div className='containerBg'>
                    <div style={{margin: '10px'}}>
                        <h3>Cancel Subscription</h3>
                    </div>
                    <div>
                        <DeleteSubscription {...this.props} />
                    </div>
                </div>
                <UncontrolledPopover trigger='hover click' placement='top' target='plan-upgrade-info' className='left'>
                    <PopoverBody>
                        <div>May take up to a day for size to change even if Team Suite name changes.</div>
                        <div><b>Credit</b>: There are no refunds. When downgrading plans, you may receive credit which will be applied to future payments.</div>
                        <div><b>Questions</b>: If you need assistance reach out to <a href='onboarding@virbela.com'>onboarding@virbela.com</a></div>
                    </PopoverBody>
                </UncontrolledPopover>
            </div>
        );
    }
}