import React from 'react';
import Loading from './../component/loading';
import Error from './../component/error';
import { Button, Row, Col, Jumbotron, UncontrolledTooltip } from 'reactstrap';
import Stripe from './../actions/stripe';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Notifications from './../actions/notifications';

export default class Coupon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            error: null,
            coupon: null
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if(!this.props.stripeData) {
            this.setState({isLoading: false, error: 'Missing stripeData'});
        }else if(!!this.props.stripeData.get('couponId')) {
            this.getCoupon();
        } else {
            this.setState({isLoading: false});
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getCoupon = async () => {
        try {
            let coupon = await Stripe.getStripeCoupon(this.props.stripeData.get('couponId'));
            if(this._isMounted) {
                this.setState({isLoading: false, coupon: coupon});
            }
        } catch(error) {
            if(this._isMounted) {
                this.setState({isLoading: false, error: error.message});
            }
        }
    }

    generateCoupon = async () => {
        this.setState({isLoading: true});
        try {
            let coupon = await Stripe.createStripeCoupon();
            if(this._isMounted) {
                this.setState({isLoading: false, coupon: coupon});
            }
        } catch(error) {
            if(this._isMounted) {
                this.setState({isLoading: false, error: error.message});
            }
        }
    }

    renderCoupon = () => {
        if(!!this.state.coupon) {
            return (
                <div>
                    <Jumbotron className='left' style={{color:'white', padding: '15px', backgroundImage: 'url(https://image.freepik.com/free-photo/geometric-abstract-creative-blue-paperboard-background-flat-lay_24972-370.jpg)', backgroundSize: 'cover', backgroundPostion: 'center'}}>
                        <Row style={{padding: '15px', paddingBottom: '0px'}}>
                            <Col xs={12} lg={8}>
                                <h3 className='display-5'>Discount Coupon</h3>
                                <h2 className="display-4">{ '1 Month Free'}</h2>
                                <p className="lead">Team Suites</p>
                            </Col>
                            <Col xs={12} lg={4} className='center' style={{ verticalAlign: 'middle'}}>
                                <Button color='link' disabled />
                                <Button color='link' disabled />
                                <div style={{position: 'absolute', top: '50%', width: '100%', transform: 'translateY(-50%)'}}>
                                    <CopyToClipboard text={this.state.coupon.id} onCopy={() => Notifications.addNotification('Success', 'Successfully copied!', 'success', {timer: 1150})}>
                                        <span id='code' className='hover'><h2>{this.state.coupon.id}</h2></span>
                                    </CopyToClipboard>
                                </div>
                            </Col>
                        </Row>
                        <div style={{verticalAlign: 'bottom', paddingLeft: '15px', paddingTop: '10px'}}>
                            <Row>
                                <Col >
                                    <font size={1}>Send this coupon code to a customer for 25% back to you each subscription cycle</font>
                                </Col>
                            </Row>
                        </div>
                    </Jumbotron>
                    <UncontrolledTooltip placement='top' target='code'>
                        Copy
                    </UncontrolledTooltip>
                </div>
            );
        } else {
            return (
                <button className='bttn outline primary' onClick={this.generateCoupon}>Create Coupon</button>
            );
        }
    }

    render() {
        if(this.state.isLoading) {
            return (
                <Loading />
            );
        } else if(this.state.error) {
            return (
                <Error color='danger' message={this.state.error} />
            );
        } else {
            return (
                <div>
                    {this.renderCoupon()}
                </div>
            );
        }  
    }
}