/** @format */

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Row, Col, FormGroup, Label, Input, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import Room from '../../actions/room';

export default function WebBoardPermissions({ roomSecurity, updateRoomSecurities }) {
  const [permLvl, updatePermLvl] = useState(
    (roomSecurity && roomSecurity.get('permissions') ? roomSecurity.get('permissions').web : 0) || 0
  );
  const [isSaving, updateSaving] = useState(false);

  const controller = useMemo(() => new AbortController(), []);
  useEffect(() => () => controller.abort(), [controller]);

  const onSelect = useCallback((e) => {
    updatePermLvl(parseFloat(e.target.value));
  }, []);

  const updateWebBoardPerm = useCallback(async () => {
    try {
      if (controller.signal.aborted) return;
      updateSaving(true);
      const savedRs = await Room.updateTSPermissions(roomSecurity, 'web', permLvl);
      if (controller.signal.aborted) return;
      updateRoomSecurities(savedRs);
      updateSaving(false);
    } catch (error) {
      if (controller.signal.aborted) return;
      updatePermLvl((roomSecurity && roomSecurity.get('permissions') ? roomSecurity.get('permissions').web : 0) || 0);
      updateSaving(false);
    }
  }, [controller.signal.aborted, permLvl, roomSecurity, updateRoomSecurities]);

  return (
    <div>
      <Row>
        <Col xs="7">
          <h3>Web Board Access</h3>
        </Col>
        <Col className="right">
          {isSaving ? (
            <Spinner color="primary" />
          ) : (
            <button className="bttn primary outline" onClick={updateWebBoardPerm} type="button">
              Save
            </button>
          )}
        </Col>
      </Row>
      <Container>
        <FormGroup check>
          <Label check>
            <Input type="radio" name="radio1" value={0} checked={permLvl === 0} onChange={onSelect} /> <b>Level 1</b>:
            Everyone has full access
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="radio" name="radio1" value={0.5} checked={permLvl === 0.5} onChange={onSelect} />{' '}
            <b>Level 2</b>: Members have limited access
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="radio" name="radio1" value={4} checked={permLvl === 4} onChange={onSelect} /> <b>Level 3</b>:
            Members have no access
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="radio" name="radio1" value={4.5} checked={permLvl === 4.5} onChange={onSelect} />{' '}
            <b>Level 4</b>: Moderators and below have limited access
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="radio" name="radio1" value={8} checked={permLvl === 8} onChange={onSelect} /> <b>Level 5</b>:
            Moderators and below have no access
          </Label>
        </FormGroup>
        <p>
          <font size="2">
            * Full Access: Users can change the url and interact with the page
            <br />
            ** Limited Access: Users can only interact with the page
          </font>
        </p>
      </Container>
    </div>
  );
}

WebBoardPermissions.propTypes = {
  roomSecurity: PropTypes.shape({
    get: PropTypes.func.isRequired,
  }).isRequired,
  updateRoomSecurities: PropTypes.func.isRequired,
};
