import React from 'react';
import AppConfig from './../constants/AppConfig';
import Error from './../component/error';
import Auth from './../actions/auth';
import { Alert, Modal, ModalBody, ModalFooter, Spinner, CustomInput, Input, Label, Table } from 'reactstrap';
import { Elements, StripeProvider } from 'react-stripe-elements';
import CheckoutForm from './../component/checkoutForm';
import ApplyCoupon from './applyCoupon';
import { Link, withRouter } from 'react-router-dom';
import Stripe, { calculatePrice, getAddonFees, getTaxAmount } from './../actions/stripe';
import swal from 'sweetalert';
import { IoIosArrowBack } from "react-icons/io";
//props: dCard, plan, toggle, addCardSuccess

class PurchaseModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: '',
            stripe: null,
            dCard: null,
            paymentMethods: null,
            plan: null,
            LA: false,
            coupon: null,
            resellerCoupon: null,
            selectedCard: undefined,
            addons: [],
            addonsLoading: true,
            tax: 0, 
            totalPrice: 0
        }
    }

    async componentDidMount() {
        try {
            this._isMounted = true;

            if (window.Stripe) {
                this.setState({stripe: window.Stripe(AppConfig.StripeKey)});
            } else {
                document.querySelector('#stripe-js').addEventListener('load', () => {
                // Create Stripe instance once Stripe.js loads
                this.setState({stripe: window.Stripe(AppConfig.StripeKey)});
                });
            }
            let { dCard, plan, paymentMethods } = this.props
            this.setState({dCard, plan, paymentMethods});
            let addons = await getAddonFees(plan);
            if(!!paymentMethods && !!paymentMethods.data && (paymentMethods.data.length === 1) && !!dCard) {
                let {totalPrice} = this.getPrices(plan, addons, this.state.coupon);
                const tax = await getTaxAmount({plan, cardId: dCard.id, coupon: this.state.coupon, quantity: this.props.quantity});
                this.setState({addons, addonsLoading: false, tax, totalPrice, error:''});
            } else {
                this.setState({addons, addonsLoading: false, error:''});
            }
        } catch(error) {
            this.setState({error: error, addonsLoading: false});
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.dCard !== this.props.dCard) {
            this.setState({dCard: this.props.dCard});
        }
        if(prevProps.plan !== this.props.plan) {
            this.setState({plan: this.props.plan});
        }
        if(prevProps.paymentMethods !== this.props.paymentMethods) {
            this.setState({paymentMethods: this.props.paymentMethods});
        }
        if(prevState.coupon !== this.state.coupon){
            let {totalPrice} = this.getPrices(this.props.plan,this.state.addons,this.state.coupon);
            try{
                const { paymentMethods, dCard, selectedCard: selectedCardIdx, coupon } = this.state;
                const selectedCard = paymentMethods && paymentMethods.data[selectedCardIdx||0];
                const cardId = (selectedCard && (selectedCard.type !== 'ach_credit_transfer') && selectedCard.id) || dCard.id;
                const tax = await getTaxAmount({plan: this.props.plan, cardId, coupon: coupon, quantity: this.props.quantity});
                this.setState({tax, totalPrice, error:''});
            }
            catch(error){
                this.setState({error: error});
            }
        }
        //only refetch tax here if user had no card and added a card.
        if(((prevState.dCard || ((prevProps.paymentMethods.data.length === 0) && this.state.dCard)) && prevState.dCard !== this.state.dCard)) {
            let {totalPrice} = this.getPrices(this.props.plan,this.state.addons,this.state.coupon);
            try{
                const tax = await getTaxAmount({plan: this.props.plan, cardId: this.state.dCard.id, coupon: this.state.coupon, quantity: this.props.quantity});
                this.setState({tax, totalPrice, error:''});
            }
            catch(error){
                this.setState({error: error});
            }
        }
    }

    toggle = () => {
        this.props.toggle();
    }

    updateLA = (e) => {
        this.setState({[e.target.id]: e.target.checked});
    }

    verifyLA = () => {
        let user = Auth.getCurrentUser();
        if((user.get('email_verified') !== true) && (user.get('email_verified') !== false) && !this.state.LA) {
            this.setState({error: "Please accept Virbela's Terms of Use, Terms of Service, and Privacy Policy"});
        } else if(!this.state.LA) {
            this.setState({error: "Please accept Virbela's Terms of Service"});
        } else {
            this.setState({error: ''});
            let { paymentMethods, plan, dCard, selectedCard, resellerCoupon, coupon } = this.state;
            let cardId = (!!paymentMethods && paymentMethods.data[selectedCard||0] && (paymentMethods.data[selectedCard||0].type !== 'ach_credit_transfer') && paymentMethods.data[selectedCard||0].id) || dCard.id;
            this.purchasePlan(plan, plan.metadata.accountId, coupon, resellerCoupon, cardId, this.props.quantity, this.props.data);
        }
    }

    selectCard = async (e) =>{
        let selected = e.target.value;
        if(e && selected){
            try{
                let {totalPrice}= this.getPrices(this.props.plan,this.state.addons,null);
                const { paymentMethods, dCard, coupon } = this.state;
                const selectedCard = paymentMethods && paymentMethods.data[selected||0];
                const cardId = (selectedCard && (selectedCard.type !== 'ach_credit_transfer') && selectedCard.id) || dCard.id;
                const tax = await getTaxAmount({plan: this.props.plan, cardId: cardId, coupon: coupon, quantity: this.props.quantity});
                this.setState({tax,selectedCard: selected, totalPrice, error:''});
            }
            catch(error){
                this.setState({error: error});
            }
        }
        else{
            this.setState({selectedCard: undefined, coupon: null});
        }

    }


    purchasePlan = async (plan, accountId, coupon = null, resellerCoupon = null, card = null, quantity, data) => {
        this.setState({isLoading: true});
        try {
            if(plan.type === 'one_time') {
                await Stripe.createAndPurchaseInvoice({price: plan, coupon, card, quantity, data});
                if(!this._isMounted) return;
                this.toggle();
                this.props.onPurchaseSuccess();
                if(this.props.redirectOnSuccess) this.props.history.push('/profile/subscriptions');
                swal('Success', 'Thank you for your purchase. You will receive a confirmation email shortly.', 'success');
            } else {
                const subscription = await Stripe.purchasePlan(plan, accountId, coupon, resellerCoupon, card, quantity);
                if(!this._isMounted) return;
                this.toggle();
                this.props.onPurchaseSuccess();
                if(this.props.redirectOnSuccess) this.props.history.push('/profile/subscriptions');
                if(subscription.status === 'active') {
                    let message = (subscription.plan.nickname.includes('Private Campus'))? 'Thank you for your purchase. A Virbela representative will contact you shortly with more information.' : 'Check your subscription details for instructions.';
                    swal('Success', message, 'success', {
                        buttons: {
                            details: true,
                            ok: true
                        }
                    }).then(value => {
                        if(value === 'details') {
                            this.props.history.push({pathname: '/profile/subscriptions', state: {subId: subscription.id}});
                        }
                    });
                } else {
                    swal('Error', 'Your card was declined. You have 23hrs to pay the invoice.', 'error', {
                        buttons: {
                            details: true,
                            ok: true
                        }
                    }).then(value => {
                        if(value === 'details') {
                            this.props.history.push({pathname: '/profile/subscriptions', state: {subId: subscription.id}});
                        }
                    });
                }
            }
        } catch(error) {
            if(!this._isMounted) return;
            this.setState({isLoading: false, error: error.message});
        }
    }

    getPrices = (plan, addons, coupon) => {
        let price = calculatePrice(plan, null, this.props.quantity);
        let amountOff = 0; 
        let totalPrice = 0;
        if(!!coupon && !!coupon.amount_off){
            amountOff = coupon.amount_off/100;
        }
        if(!!addons) {
            addons = addons.map((addon,index) => {
                let addonPrice = 0;
                if(addon.billing_scheme ==='tiered'){
                    addonPrice = calculatePrice(addon, null, 1);
                }
                else{
                    addonPrice =  (addon.unit_amount/100);
                }
                totalPrice += addonPrice;
                return {price: addonPrice, product: addon.product, discountable: addon.discountable}
            });
        }
        if(price < 0) price = 0;
        totalPrice += price;
        if(!!coupon && !!coupon.percent_off) {
            amountOff = totalPrice*((coupon.percent_off)/100);
        }
        totalPrice -= amountOff;
        if(totalPrice < 0) totalPrice = 0;
        return {price, addons, amountOff, totalPrice}
    }

    renderPurchaseModal = () => {
            let user = Auth.getCurrentUser();
            let {plan, coupon, dCard, paymentMethods, selectedCard, addons, addonsLoading, tax, totalPrice} = this.state;
            let isSingleUse = !!plan.metadata.event_size || (plan.metadata.nonRecurring === 'true');
            let { interval, interval_count } = (!!plan && plan.recurring) || plan;
            if(!!dCard.card) dCard = dCard.card;
            if(!!selectedCard && paymentMethods.data) dCard = paymentMethods.data[selectedCard];
            totalPrice = totalPrice + tax;
            return (
                <Modal isOpen={true} toggle={this.toggle}>
                    <ModalBody>
                        {(!!this.state.error) && (<Error message={this.state.error} color='danger' />)}
                        {(!user)? <Error color='danger' message='Cannot find user data. Please try logging in again' /> : (
                            paymentMethods && paymentMethods.data.length > 1 && !selectedCard) ? (
                                <Alert color='info'>
                                <div className='center'><h5>Choose Payment Method</h5></div>
                                <div>
                                    <Label for='Cards'>Cards: *</Label>
                                    <Input type='select' name='select_card' id='card' value={this.state.selectedCard} onChange={this.selectCard}>
                                    <option hidden value={''}>Select card..</option>
                                    {paymentMethods.data.map((paymentMethod, index) => {
                                        let paymentSource = (paymentMethod.object === 'source') ? paymentMethod.card : paymentMethod;
                                        let isACH = (paymentMethod.type === 'ach_credit_transfer');
                                        let text = (isACH)? `ACH Credit Transfer` : `Charge to ${!!paymentSource && paymentSource.brand} ending in ${!!paymentSource && paymentSource.last4}`
                                        return (
                                            <option key={paymentMethod.id} value={index} disabled={isACH}>{text}</option>
                                        );
                                    })}
                                    </Input>

                                    <hr />
                                    <div className='center'><font size={2}>If you would like to change your default card or add a new card, please do so on the <Link to='/profile/paymentMethods'>payment methods</Link> page.</font></div>
                                </div>
                                </Alert>                               
                            ): ( 
                            <>
                                <Alert color='info' className='center'>
                                    {selectedCard && <div className='hover' style={{position: 'absolute', top: '10px', left: '10px'}} onClick={this.selectCard}><IoIosArrowBack size='1.25em' /></div>}
                                    <div className='center'><h5>Confirm Purchase</h5></div>
                                    <div>
                                        <p style={{marginBottom: 0}}>Charge to <b>{dCard.brand || (!!dCard.card && dCard.card.brand)}</b> ending in <b>{dCard.last4 || (!!dCard.card && dCard.card.last4)}</b></p>
                                        <hr />
                                        <div className='center'><font size={2}>If you would like to change your default card or add a new card, please do so on the <Link to='/profile/paymentMethods'>payment methods</Link> page.</font></div>
                                    </div>
                                </Alert>
                                <ApplyCoupon updateState={(newState) => this.setState(newState)} plan={plan} hideText/>
                                <Table striped>
                                    <thead className='center'>
                                        <tr>
                                            <th className='left' style={{width: '100%'}}>Description</th>
                                            <th>QTY</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='left' style={{width: '100%'}}>{plan.nickname}</td>
                                            <td className='center'>{this.props.quantity || 1}</td>
                                            <td>{'$' + (calculatePrice(plan, null, this.props.quantity)).toFixed(2).toLocaleString() + ((isSingleUse)? '' : ('/' + ((interval_count < 2)? '' : interval_count) + interval + '(s)'))}</td>
                                        </tr>
                                        {addons.map(price => {
                                            return <tr key={price.id}>
                                                <td className='left' style={{width: '100%'}}>{price.nickname}</td>
                                                <td className='center'></td>
                                                <td>{'$' + (price.unit_amount/100).toFixed(2).toLocaleString()}</td>
                                            </tr>
                                        })}
                                        {(!!coupon) && <tr>
                                            <td className='left' style={{width: '100%'}}>Coupon {(!!coupon.duration_in_months && (coupon.duration_in_months > 1))? `(${coupon.duration_in_months} months)` : ''}</td>
                                            <td className='center'></td>
                                            <td>-{(!!coupon.amount_off)? `$${(coupon.amount_off/100).toFixed(2).toLocaleString()}` : `${coupon.percent_off}%`}</td>
                                        </tr>}
                                        { <tr>
                                            <td className='left' style={{width: '100%'}}>Estimated Tax</td>
                                            <td className='center'></td>
                                            <td>{(addonsLoading)? <Spinner /> : `$${(tax).toFixed(2).toLocaleString()}`}</td>
                                        </tr> }
                                    </tbody>
                                </Table>
                                <div className='right'><b>Total:</b> {(addonsLoading)? <Spinner color='primary' /> : `$${totalPrice.toFixed(2).toLocaleString()}`}</div>
                            </>
                        )}
                        {(user.get('email_verified') !== true) && (user.get('email_verified') !== false) ? (
                            <div className='center'>
                                <CustomInput type='checkbox' id='LA' onChange={this.updateLA} className='LA'><span onClick={() => {this.setState({LA: !this.state.LA}); document.getElementById('LA').checked=!this.state.LA}}>{"I agree to Virbela's "}</span><a href='https://assets.virbela.com/legal/VirBELA_TOU_EULA.pdf' target='_blank' rel='noopener noreferrer'>Terms of Use</a>, <a href='https://assets.virbela.com/legal/VirBELA_TOS_TS.pdf' target='_blank' rel='noopener noreferrer'>Terms of Service</a>, and <a href='https://assets.virbela.com/legal/VirBELA_Privacy_Policy.pdf' target='_blank' rel='noopener noreferrer'>Privacy Policy</a></CustomInput>
                            </div>
                        ): (
                            <div className='center'>
                                <CustomInput type='checkbox' id='LA' onChange={this.updateLA} className='LA'><span onClick={() => {this.setState({LA: !this.state.LA}); document.getElementById('LA').checked=!this.state.LA}}>{"I agree to Virbela's "}</span><a href='https://assets.virbela.com/legal/VirBELA_TOS_TS.pdf' target='_blank' rel='noopener noreferrer'>Terms of Service</a></CustomInput>
                            </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <button className='bttn' onClick={this.toggle}>Cancel</button>
                        {(this.state.isLoading || this.state.addonsLoading)? <Spinner color='primary' /> : (
                            <button className='bttn primary' disabled={paymentMethods && paymentMethods.data.length > 1 && !selectedCard} onClick={this.verifyLA}>Confirm</button>
                        )}
                    </ModalFooter>
                </Modal>
            );
    }

    renderCardModal = () => {
        let props = {toggleCardModal: this.toggle, addCardSuccess: this.props.addCardSuccess, saveForm: this.props.saveForm, formData: this.props.formData};
        return (
            <StripeProvider stripe={this.state.stripe}>
                <Elements>
                    <CheckoutForm {...props} />
                </Elements>
            </StripeProvider>
        );
    }

    renderModal = () => {
        let { dCard, paymentMethods } = this.state;
        let hasValidSource = (!!dCard && (dCard.type !== 'ach_credit_transfer')) || (!!paymentMethods && paymentMethods.data.some((source) => source.type !== 'ach_credit_transfer'));
        if(hasValidSource) {
            return this.renderPurchaseModal();
        } else {
            return this.renderCardModal();
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderModal()}
            </React.Fragment>
        );
    }
}

export default withRouter(PurchaseModal);

PurchaseModal.defaultProps = {
    onPurchaseSuccess: () => {
        return;
    },
    saveForm: () => {
        return;
    },
    formData: {},
    redirectOnSuccess: false
}