/**
 * WIP. Client product page. The products shown are set in the Parse Server configVars. It is a dictionary
 * where the key is the URL for this page and the value is an array of Stripe product IDs that should be shown.
 * When there's only one product, it will redirect to that specific products page. For now page won't be used
 * much. Regular Virbela site will display products and link to product pages. Doubles as 404 page
 */
import React from 'react';
import { Button, Row, Col, Container } from 'reactstrap';
import Loading from './../component/loading';
import Error from './../component/error';
import Stripe from './../actions/stripe';
import './clientTeamSuites.css';
import { Parallax } from 'react-parallax';
import { Link } from 'react-router-dom'
import { IoMdText, IoMdBulb, IoMdBusiness } from "react-icons/io";

export default class ClientTeamSuite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            error: '',
            products: [],
            hasMore: false,
            selectedIdx: 0
        }
    }

    async componentDidMount() {
        try {
            this.setState({isLoading: true});
            let res = await Stripe.getClientProducts(this.props.match.params.client.toLowerCase());
            if(res.data.length < 1) {
                this.setState({isLoading: false, error: 'No products found'});
            } else if(res.data.length === 1) {
                this.props.history.push('/products/' + res.data[0].id); //if only one product, redirect to product page
            } else {
                this.setState({ isLoading: false, products: res.data, hasMore: res.has_more,  selected: res.data[0]});
            }
        } catch(error) {
            this.setState({isLoading: false, error: error.message});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.state !== prevState) {
            if(this.state.selectedIdx !== prevState.selectedIdx) {
                this.forceUpdate();
            }
            if(this.state.isOpen !== prevState.isOpen) {
                this.forceUpdate();
            }
        }
        if(this.props !== prevProps) {
            if(this.props.match.params.client.toLowerCase() !== prevProps.match.params.client.toLowerCase()) {
                this.componentDidMount();
            }
        }
    }

    displayProducts = () => {
        let { isLoading, error, products, selectedIdx } = this.state;
        if(isLoading) {
            return <Container className='pageCenter'><Loading /></Container>
        } else if(!!error) {
            return <Container className='pageCenter'><Error message={error} /></Container>
        } else {
            return (
                <React.Fragment>
                    <div>{this.displayProduct(products[selectedIdx], selectedIdx)}</div>
                    <Row className='feature' style={{margin: 0}}>
                        <Col xs={{size: 12, order: 4}} lg={{size: 6, order: 3}} className='text'>
                            <Row>
                                <Col className='info' xs='12' md={{size: 8, offset: 2}}>
                                <div><h3>Communication <IoMdText size='1.25em'/></h3></div>
                                <div className='left'>
                                    <ul>
                                        <li>Interact with avatars, gestures, and dances in a shared 3D space</li>
                                        <li>Talk with others through a Spatialized Voice chat</li>
                                        <li>No mic? No problem. Talk through the text chat fully equipped with emojis</li>
                                        <li>Need privacy? Use Private Volumes and chats where only those inside can get your message</li>
                                    </ul>
                                </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={{size: 12, order: 3}} lg={{size: 6, order: 4}} className='image'><img src={'https://s3.amazonaws.com/assets.virbela.com/opencampus/communication.jpg'} alt='communications'/></Col>
                    </Row>
                    <Row className='feature' style={{margin: 0}}>
                        <Col xs={{size: 12, order: 5}} lg={{size: 6, order: 5}} className='image'><img src={'https://s3.amazonaws.com/assets.virbela.com/opencampus/learningtools.jpg'} alt='learning-tools'/></Col>
                        <Col xs={{size: 12, order: 6}} lg={{size: 6, order: 6}} className='text'>
                            <Row>
                                <Col className='info' xs='12' md={{size: 8, offset: 2}}>
                                    <div><h3>Learning Tools <IoMdBulb size='1.25em'/></h3></div>
                                    <div className='left'>
                                        <ul>
                                            <li>Display content such as documents, power points, web pages, etc on a shared screen</li>
                                            <li>Upload and share files</li>
                                            <li>Laser pointers </li>
                                            <li>Team Building Activities</li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{margin: 0}} className='getStarted'>
                        <Col lg={{size: 4, offset: 4}}>
                            <h1>{products[selectedIdx].metadata.name}</h1>
                            <button className='bttn primary' onClick={() => {this.props.history.push('/products/' + products[selectedIdx].id)}}>Get Started</button>
                        </Col>
                    </Row>
                    <Row className='feature' style={{margin: 0}}>
                        <Col xs='12' lg={{size: 4, offset: 1}} className='left'>
                            <div><h3>Not the Right Fit?</h3></div>
                            <div><p>Check out our other Team Suite offerings</p></div>
                            <div>{this.displayNav(products, selectedIdx)}</div>
                        </Col>
                    </Row>
                </React.Fragment>
            );
        }
    }

    displayNav = (products, selectedIdx = -1) => {
        return (
            <React.Fragment>
                <div className='left'>
                    {products.map((product, idx) => {
                        if(selectedIdx === idx) {
                            return <React.Fragment key={'empty_' + idx}/>
                        }
                        return <Button key={product.id + '_' + idx} color='link' style={{textDecoration: 'none'}} onClick={() => {this.setState({selectedIdx: idx}); window.scroll({top: 0, left: 0, behavior: 'smooth' });}}>{product.metadata.name}</Button>
                    })}
                </div>
            </React.Fragment>
        );
    }

    displayProduct = (product) => {
        return (
            <React.Fragment>
                <Parallax bgImage={'https://assets.virbela.com/opencampus/Tech_Room_Expansion_banner.png'} strength={400} bgImageAlt={'Team Suite Image'}>
                    <div className='parallax'>
                        <div className='productNav'>{this.displayNav(this.state.products)}</div>
                        <div className='insideStyling'>
                            <Row ><Col xs='12' lg={{size: 6, offset: 3}}><div><h1>{product.metadata.name}</h1></div></Col></Row>
                            <Row><Col xs='12' lg={{size: 4, offset: 4}}>
                                <div><p>{product.metadata.description}</p></div>
                            </Col></Row>
                        </div>
                    </div>
                </Parallax>
                <Row className='feature' style={{margin: 0}}>
                    <Col xs={{size: 12, order: 1}} lg={{size: 6, order: 1}} className='image'><img src={product.metadata.image} alt={product.metadata.name}/></Col>
                    <Col xs={{size: 12, order: 2}} lg={{size: 6, order: 2}} className='text'>
                        <Row>
                            <Col className='info' xs='12' md={{size: 8, offset: 2}}>
                                <div><h3>Specifications <IoMdBusiness size='1.25em'/></h3></div>
                                <div className='left'><ul>{product.metadata.details.split(';').map((detail, idx) => {
                                    return <li key={product.id + '_details_' + idx}>{detail}</li>
                                })}</ul><Link to={'/products/' + product.id}>Learn More</Link></div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    render() {
        return (
            <section className='minHeightNoHeader productsBg'>              
                {this.displayProducts()}
            </section>
        );
    }
}