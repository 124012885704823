/**
 *
 * @format
 */
/* eslint-disable */

import React from 'react';
import Loading from './../loading';
import Error from './../error';
import ParseQueries from './../../actions/parseQueries';
import { Alert, Table, Input, Spinner } from 'reactstrap';
import AddPermissionModal from './addPermissionModal';
import ParseUser from './../../actions/parseUser';
import * as AppConfig from './../../constants/AppConfig';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import './permissionList.css';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import Auth from './../../actions/auth';
import Notifications from './../../actions/notifications';

const EN = {
  // Options.jsx
  items_per_page: '/ page',
  jump_to: 'Go to ',
  page: '',

  // Pagination.jsx
  prev_page: 'Previous Page',
  next_page: 'Next Page',
  prev_5: 'Previous 5 Pages',
  next_5: 'Next 5 Pages',
  prev_3: 'Previous 3 Pages',
  next_3: 'Next 3 Pages',
};

// member is excluded because there is no functional benefit to the user
const PERMISSIONS = ['admin', 'moderator', 'suspended', 'remove'];

export default class PermissionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      loading: [],
      error: '',
      memberships: null,
      membershipsCount: 0,
      page: 1,
      ascending: true,
      sortField: 'permission',
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    let roomSecurity = this.props.roomSecurity;
    if (!roomSecurity) {
      this.setState({ isLoading: false, error: 'Missing room security object' });
    } else if (!roomSecurity.get('account')) {
      this.setState({ isLoading: false, error: `To use this feature please complete the form in the Account tab` });
    } else {
      this.getMembershipsAndCount();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.roomSecurity !== this.props.roomSecurity ||
      prevState.page !== this.state.page ||
      prevState.sortField !== this.state.sortField ||
      prevState.ascending !== this.state.ascending
    ) {
      this.getMembershipsAndCount();
    }
  }

  getMembershipsAndCount = async () => {
    try {
      this.setState({ isLoading: true });
      let roomSecurity = this.props.roomSecurity;
      const { page, ascending, sortField } = this.state;
      const sort = sortField ? [ascending ? 1 : -1, sortField] : [];
      // member is excluded from the query because there is no way for TS admins+ to add a user as a member
      const { members, count } = await ParseQueries.getMembers({
        accountId: roomSecurity.get('account')?.id,
        activePage: page,
        sort,
        filter: {
          roles: ['owner', 'admin', 'moderator', 'suspended'],
        },
      });
      if (this._isMounted) {
        this.setState({ isLoading: false, memberships: members, membershipsCount: count });
      }
    } catch (error) {
      if (this._isMounted) {
        this.setState({ isLoading: false, error: error.message });
      }
    }
  };

  updateSelect = (membershipId, updatedPermission) => {
    this.setState((prevState) => {
      const memberships = [...prevState.memberships];
      const membership = memberships.find((mem) => mem.id === membershipId);
      membership.permission = updatedPermission;
      return { memberships };
    });
  };

  updateMembership = async (e) => {
    const membershipId = e.target.dataset.membershipid;
    const permission = e.target.value;
    try {
      if (!PERMISSIONS.includes(permission) || !membershipId) return;

      this.setState((prevState) => ({ loading: [...prevState.loading, membershipId] }));
      if (permission === 'remove') {
        await ParseUser.removeMembershipById(membershipId);
        this.getMembershipsAndCount();
      } else {
        await ParseUser.updateMembershipPermissionById(membershipId, permission);
        this.updateSelect(membershipId, permission);
      }
    } catch (error) {
      Notifications.addNotification('Error', error.message, 'error');
    } finally {
      if (this._isMounted) {
        this.setState((prevState) => {
          const loading = [...prevState.loading];
          loading.splice(loading.indexOf(membershipId), 1);
          return { loading };
        });
      }
    }
  };

  renderMembers = (memberships) => {
    return memberships.map((membership, idx) => {
      const { user: membershipUser, id: membershipId, permission, userId } = membership;
      const { email = 'N/A', displayname = 'N/A' } = membershipUser ?? {};
      return (
        <tr key={'member' + idx}>
          <td>{email}</td>
          <td className="center">{displayname}</td>
          <td className="center">
            {this.state.loading.includes(membershipId) ? (
              <Spinner size="sm" color="primary" />
            ) : (
              <Input
                type="select"
                id="permissionSelect"
                value={permission}
                data-membershipid={membershipId}
                disabled={userId && (userId === this.props.ownerId || userId === Auth.getCurrentUser().id)}
                onChange={this.updateMembership}
              >
                <option>{permission}</option>
                {PERMISSIONS.filter((perm) => perm !== permission).map((perm, idx) => {
                  if (!userId && perm !== 'remove') return null;
                  return <option key={'permissions_' + idx}>{perm}</option>;
                })}
              </Input>
            )}
          </td>
        </tr>
      );
    });
  };

  renderTable = (memberships) => {
    if (!memberships.length) {
      return (
        <Alert color="warning" className="center">
          No users found
        </Alert>
      );
    } else {
      return (
        <Table striped hover responsive>
          <thead>
            <tr>
              <th className="hover">
                <span id="email" onClick={this.updateSorting}>
                  {this.tableHeaderText('Email')}
                </span>
              </th>
              <th className="center hover">
                <span id="display_name" onClick={this.updateSorting}>
                  {this.tableHeaderText('Displayname')}
                </span>
              </th>
              <th className="center hover">
                <span id="permission" onClick={this.updateSorting}>
                  {this.tableHeaderText('Permission')}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>{this.renderMembers(memberships)}</tbody>
        </Table>
      );
    }
  };

  tableHeaderText(fieldName) {
    let caret = null;
    const dict = { Permission: 'permission', Displayname: 'display_name', Email: 'email' };
    if (this.state.sortField === dict[fieldName]) {
      if (!this.state.ascending) {
        caret = <IoIosArrowUp />;
      } else {
        caret = <IoIosArrowDown />;
      }
    }
    return (
      <React.Fragment>
        {fieldName}
        {caret}
      </React.Fragment>
    );
  }

  updateSorting = (e) => {
    if (e.target.id === this.state.sortField) {
      this.setState({ ascending: !this.state.ascending });
    } else {
      this.setState({ sortField: e.target.id });
    }
  };

  paginationChange = (pageNumber) => {
    this.setState({ page: pageNumber });
  };

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    } else if (!!this.state.error) {
      return <Error color="warning" message={this.state.error} />;
    } else {
      let memberships = this.state.memberships;
      return (
        <React.Fragment>
          {this.renderTable(memberships)}
          <div className="center">
            <Pagination
              simple
              locale={EN}
              showQuickJumper={{ goButton: <button>Go</button> }}
              defaultCurrent={this.state.page}
              defaultPageSize={AppConfig.default.Pagination}
              total={this.state.membershipsCount}
              onChange={this.paginationChange}
            />
          </div>
          {this.props.isOpen && (
            <AddPermissionModal
              isOpen={this.props.isOpen}
              toggle={this.props.toggleModal}
              roomSecurity={this.props.roomSecurity}
              update={this.getMembershipsAndCount}
            />
          )}
        </React.Fragment>
      );
    }
  }
}
