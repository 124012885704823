import React from 'react';
import Loading from './../component/loading';
import auth from './../actions/auth';
import Notifications from './../actions/notifications';
import { Form, FormGroup, Input, InputGroup, InputGroupAddon, Button, Row, Col, Alert, Jumbotron, Container } from 'reactstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import parseUser from '../actions/parseUser';
import { FORM_REQUIREMENT_REGEX, PASSWORD_SPECIAL_CHARS } from '../constants/FormRequirements';
import { ValidationError } from 'yup';
import './register.css'

const Cryptr = require('cryptr');
const cryptr = new Cryptr('EkpPLa6yMjX8');

const yup = require('yup');
const schema = yup.object().shape({
    password: yup.string().trim().required('Password is required')
        .min(8, 'Password needs to be a minimum of 8 characters')
        .matches(FORM_REQUIREMENT_REGEX.ONE_LOWERCASE_LETTER, 'Password must contain at least one lowercase letter')
        .matches(FORM_REQUIREMENT_REGEX.ONE_UPPERCASE_LETTER, 'Password must contain at least one uppercase letter')
        .matches(FORM_REQUIREMENT_REGEX.ONE_NUMBER, 'Password must contain at least one number')
        .matches(FORM_REQUIREMENT_REGEX.ONE_SPECIAL_CHARACTER, `Password must contain at least one special character in ${PASSWORD_SPECIAL_CHARS}`)
        .matches(FORM_REQUIREMENT_REGEX.NO_SPACES, 'Password must not contain spaces')
});

export default class PasswordReset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            validationErrors: null,
            isLoading: true,
            email: '',
            isPasswordVisible: false,
            newPassword1: '',
            newPassword2: ''
        }
    }

    async componentDidMount() {
        this._isMounted = true;

        if(auth.isAuthenticated()) {
            try {
                await auth.logout();
                if(!this._isMounted) return;
                window.location.reload();
            } catch(error) {
                this.props.history.push('/');
            }
        } else {
            try {
                let decryptedStringArr = cryptr.decrypt(this.props.match.params.value).split('_');
                let date = decryptedStringArr.pop();
                let email = decryptedStringArr.join('_');
                if((Date.now() - parseInt(date)) < 86400000) { //1 day
                    if(!!email) {
                        this.setState({email: email, isLoading: false});
                    } else {
                        Notifications.addNotification('Error', 'Invalid user id. Please try again.', 'error');
                        this.props.history.push('/');
                    }
                } else {
                    Notifications.addNotification('Error', 'The password reset request link has expired.', 'error');
                    this.props.history.push('/');
                }
            } catch(error) {
                console.error(error.message);
                Notifications.addNotification('Error', 'Invalid password reset URL.', 'error');
                this.props.history.push('/');
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    updateInput = (e) => {
        this.setState({[e.target.id]: e.target.value});
    }

    handleKeyDown = (e) => {
        if(e.key === 'Enter') {
            this.submitForm();
        }
    }

    submitForm = async (e) => {
        if (this.state.isLoading) return;
        if (!e) return;
        e.preventDefault();
        const { email, newPassword1, newPassword2 } = this.state;

        this.setState({ validationErrors: null, error: null });
        await this.validateForm(newPassword1, newPassword2);
        const { validationErrors, error } = this.state;
        if (validationErrors || error) return;

        this.setState({isLoading: true});
        if (!this._isMounted) return;
        parseUser.updatePassword(email, newPassword1, window.location.href, (error) => {
            if (!this._isMounted) return;
            if (!error) {
                this.props.history.push({pathname: '/', state: {email: email}})
            } else {
                this.setState({isLoading: false, newPassword1: '', newPassword2: ''});
            }
        });
    }

    validateForm = async (password1, password2) => {
        const validationOptions = { abortEarly: false };
        try {
            await schema.validate({ password: password1 }, validationOptions);
            if (!this._isMounted) return;
            if (password1 !== password2) {
                throw new Error('Passwords do not match');
            }
        } catch (error) {
            if (!this._isMounted) return;
            if (error instanceof ValidationError) {
                const validationErrors = {};
                error.inner.forEach(ve => {
                    validationErrors[ve.path] = ve;
                });
    
                this.setState({ validationErrors });
            } else {
                this.setState({error: error.message});
            }
        }
    }

    togglePasswordVisibility = () => {
        this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
    }

    getRequirementStyle = (password, regex) => {
        const requirementIsMet = password.match(regex);
        return requirementIsMet ? 'password-req-valid' : 'password-req-invalid';
    }

    render() {
        if(this.state.isLoading) {
            return (
                <div className='minHeightNoHeader'>
                    <br />
                    <Loading />
                </div>
            );
        } else {
            return (
                <div className='registerBackground minHeightNoHeader' style={{position: 'relative'}}>
                    <div style={{position: 'absolute', top: '5%', marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0}}>
                        <Container>
                            <Row>
                                <Col sm={{ size: 12}} lg={{size: 6, offset: 3}}>
                                    <Jumbotron>
                                        <h3>Choose a new password</h3>
                                        <br />
                                        <Row>
                                            <Col xs={{ size: 10, offset: 1}} sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3}} lg={{ size: 8, offset: 2 }}>
                                                <Form>
                                                    {!!this.state.error && (
                                                        <Alert color='danger'>{this.state.error}</Alert>
                                                    )}
                                                    <FormGroup className='left'>
                                                        <InputGroup>
                                                            <Input 
                                                                id='newPassword1' 
                                                                type={this.state.isPasswordVisible ? 'text' : 'password'}
                                                                className={(this.state.validationErrors || {}).password ? 'input-invalid' : ''}
                                                                placeholder='New password*'
                                                                value={this.state.newPassword1} 
                                                                onChange={this.updateInput} 
                                                                onKeyDown={this.handleKeyDown}
                                                                autoFocus  
                                                            />
                                                            <InputGroupAddon addonType="append">
                                                                <Button onClick={this.togglePasswordVisibility}>{this.state.isPasswordVisible ? (<FaEyeSlash />) : (<FaEye />)}</Button>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                        <ul id='password-reqs-list' className={(this.state.validationErrors || {}).password ? 'password-invalid' : ''}>
                                                            <li className={this.getRequirementStyle(this.state.newPassword1, FORM_REQUIREMENT_REGEX.MIN_LENGTH_OF_8)}>8 characters minimum</li>
                                                            <li className={this.getRequirementStyle(this.state.newPassword1, FORM_REQUIREMENT_REGEX.ONE_UPPERCASE_LETTER)}>One uppercase letter</li>
                                                            <li className={this.getRequirementStyle(this.state.newPassword1, FORM_REQUIREMENT_REGEX.ONE_LOWERCASE_LETTER)}>One lowercase letter</li>
                                                            <li className={this.getRequirementStyle(this.state.newPassword1, FORM_REQUIREMENT_REGEX.ONE_NUMBER)}>One number</li>
                                                            <li className={this.getRequirementStyle(this.state.newPassword1, FORM_REQUIREMENT_REGEX.ONE_SPECIAL_CHARACTER)}>One special character in {PASSWORD_SPECIAL_CHARS}</li>
                                                            <li className={this.getRequirementStyle(this.state.newPassword1, FORM_REQUIREMENT_REGEX.NO_SPACES)}>No spaces</li>
                                                        </ul>
                                                        <InputGroup style={{marginBottom: '16px'}}>
                                                            <Input 
                                                                id='newPassword2' 
                                                                placeholder='Retype new password*' 
                                                                type={this.state.isPasswordVisible ? 'text' : 'password'} 
                                                                value={this.state.newPassword2} 
                                                                onChange={this.updateInput}
                                                                onKeyDown={this.handleKeyDown}
                                                                />
                                                            <InputGroupAddon addonType="append">
                                                                <Button onClick={this.togglePasswordVisibility}>{this.state.isPasswordVisible ? (<FaEyeSlash />) : (<FaEye />)}</Button>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                        <button className='bttn block primary' onClick={this.submitForm} disabled={this.state.isLoading}>Submit</button>
                                                    </FormGroup>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Jumbotron>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            );
        }
    }
}