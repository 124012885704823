import React from 'react';
import * as AppConfig from './../constants/AppConfig';
import { OauthSender } from 'react-oauth-flow';
import ParseQueries from './../actions/parseQueries';
import Error from './../component/error';
import Coupon from './../component/coupon';
import Header from './../component/header';
import { Container, Jumbotron, Row, Col, Form, FormGroup, Label, Collapse, UncontrolledPopover, PopoverBody, Spinner } from 'reactstrap';
import Auth from './../actions/auth';

export default class Referrals extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            error: null,
            stripeData: null,
            showCollapse: true
        }
    }

    async componentDidMount() {
        this._isMounted = true;
        let userEmail = Auth.getCurrentUser().get('email');
        if(!((userEmail.includes('@exprealty.com')) || (userEmail.includes('oxbridge.com.au')) || (userEmail.includes('virbela') && !userEmail.includes('+2')))) {
            if(!(await Auth.isReseller())) {
                this.props.history.push(this.props.location.prevPage || '/');
                return;
            }
        }
        this.fetchStripeData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    fetchStripeData = async () => {
        try {
            let stripeData = await ParseQueries.getStripeData();
            if(this._isMounted) {
                this.setState({isLoading: false, stripeData: stripeData});
            }
        } catch(error) {
            if(this._isMounted) {
                this.setState({isLoading: false, error: error.message});
            }
        }
    }

    toggle = (e) => {
        e.preventDefault();
        this.setState({showCollapse: !this.state.showCollapse});
    }

    renderStripeAccount = () => {
        if(this.state.error) {
            return (
                <div className='minHeightHeader'>
                    <Header />
                    <div style={{paddingTop: '15px'}}>
                        <Error color='danger' message={this.state.error} />
                    </div>
                </div>
            );
        } else if(this.state.isLoading || !this.state.stripeData) {
            return (
                <React.Fragment>
                    <div style={{position: 'relative', height: '95vh'}}>
                        <div style={{backgroundImage: 'url(https://assets.virbela.com/opencampus/Tech_Room_Expansion_banner.png)', backgroundSize: 'cover', backgroundPosition: 'center', minHeight: '95vh', position: 'absolute', width: '100%'}}></div>
                        <div style={{position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0, 0.6)'}} />
                        <div style={{position: 'absolute', width: '96%', top: '25%', color: 'white', paddingLeft: '2%'}}>
                            <Container>
                                <Jumbotron style={{backgroundColor: 'rgba(0,0,0,0)', transform: 'translateY(-20%)'}}>
                                    <h1 className="display-3">Referral Program</h1>
                                    <p className="lead">Would you like the opportunity to sell Team Suites and make 25% back for each sale?</p>
                                    <hr className="my-2" />
                                    <p>You can try it now by connecting to Stripe.</p>
                                    <OauthSender
                                        authorizeUrl="https://connect.stripe.com/oauth/authorize"
                                        clientId={AppConfig.default.ClientId}
                                        redirectUri={AppConfig.default.RedirectURI}
                                        args={{
                                            response_type: 'code',
                                            scope: 'read_write'
                                        }}
                                        state={{ from: '/referrals' }}
                                        render={({ url }) => (this.state.isLoading)? <Spinner color='info' /> : <button className='bttn primary' disabled={this.state.isLoading} onClick={() => window.location.href=url}>Try Now</button>}
                                    />                          
                                </Jumbotron>
                            </Container>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <div className='minHeightNoHeader'>
                    <Header />
                    <Container className='left' style={{paddingTop: '15px'}}>
                        <h1>Stripe Information</h1>
                        <hr />
                        <Container>
                            <Form>
                                <FormGroup row>
                                    <Label sm={3} className='left'><p><b>Stripe Dashboard:</b></p></Label>
                                    <Col>
                                        <a href='https://dashboard.stripe.com/login' target='_blank' rel='noopener noreferrer'>view your sales and transactions</a>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3} className='left'><p><b>Account ID:</b></p></Label>
                                    <Col>
                                        <span id='accountId'>{this.state.stripeData.get('accountId')}</span>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3} className='left'><p><b>Coupon:</b></p></Label>
                                    <Col>
                                        <button className='bttn primary' style={{ marginBottom: '1rem' }} onClick={this.toggle}>{(this.state.showCollapse? 'Hide' : 'Show')}</button>
                                        <Collapse isOpen={this.state.showCollapse}>
                                            <Coupon stripeData={this.state.stripeData} />
                                        </Collapse>
                                    </Col>
                                </FormGroup>
                            </Form>
                            <Row>
                                <Col md={{size: 8, offset: 2}}>
                                    
                                </Col>
                            </Row>
                        </Container>
                        <h1>Referral Program</h1>
                        <hr />
                        <Container style={{minHeight: '40vh'}}>
                            <p>
                                The Referral Program is a unique opportunity Virbela is offering that gives you the ability to sell Team Suites and get 25% back. The program works as follows:
                            </p>
                            <ul>
                                <li>Share your unique coupon code with customers</li>
                                <li>Customers will get 1 month free!</li>
                                <ul>
                                    <li><b>Monthly Plan</b>: 100% off the first payment</li>
                                    <li><b>Annual Plan</b>: 8.34% or 1/12 off the first payment</li>
                                </ul>
                                <li>When Virbela receives a payment, we will send 25% to you via Stripe. For example, if a customer signs up for the monthly plan, your first payout will occur on the second month of their subscription. This is because their first month is free.</li>
                            </ul>
                            <p>
                                For more information, check out our <a href='https://exprealty.workplace.com/groups/269497247337129/' target='_blank' rel='noopener noreferrer'>Sales Affliates group</a> on Workplace. Feel free to 
                                ask questions, share ideas, and collaborate with other sales affiliates and the Virbela team.
                            </p>
                        </Container>
                    </Container>
                    <UncontrolledPopover trigger='hover' placement="right" target='accountId'>
                        <PopoverBody>This is not your Stripe login. This is needed for Virbela support.</PopoverBody>
                    </UncontrolledPopover>
                </div>
            );
        }
    }

    render() {
        return (
            <div>
                {this.renderStripeAccount()}
            </div>
        );
    }
}