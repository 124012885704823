import React from 'react';
import { Collapse, Navbar, NavbarToggler, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container } from 'reactstrap';
import auth from './../actions/auth';
import OktaLoginButton from './oktaLoginButton';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

class NavBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openLoginModal: false,
            error: null
        };
    }

    componentDidMount() {
        auth.checkLogin(() => {
            this.forceUpdate();
        });
        this.chooseTitle(this.props.location.pathname);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname && !(!!this.props.location.state && this.props.location.state.noScroll)) {
            window.scrollTo(0, 0);
        }

        if(this.props.location.pathname !== prevProps.location.pathname) {
            this.chooseTitle(this.props.location.pathname);
        }
    }

    //hands page document 
    chooseTitle = (pathname) => {
        if(pathname.includes('product')) return;
        let location = pathname.split('/');
        location = location[location.length - 1];
        switch(location) {
            case 'subscriptions':
                document.title = 'Virbela Subscriptions';
                break;
            case 'paymentMethods':
                document.title = 'Virbela Payment Methods';
                break;
            case 'paymentHistory':
                document.title = 'Virbela Payment History';
                break;
            case 'referrals':
                document.title = 'Virbela Referrals';
                break;
            case '':
                document.title = 'Virbela Open Campus';
                break;
            default:
                document.title = 'Virbela Open Campus';
                break;
        }
    }

    toggleLogin = () => {
        let params = qs.parse(this.props.location.search);
        let urlParams = '';
        Object.keys(params).forEach((key,idx) => {
            if(!idx) urlParams = `?${key}=${params[key]}`;
            else urlParams = urlParams + `&${key}=${params[key]}`;
        });
        if(!!urlParams) {
            window.location.href = '/login' + urlParams;
        }
        this.setState({ isOpen: false });
        this.props.history.push({pathname: '/login', prevPage: (!window.location.pathname.includes('reset_password') && !window.location.pathname.includes('verify'))? window.location.pathname : ''});
    }

    handlePlanOnClick = () => {
        if(window.location.pathname === '/') {
            window.scrollBy({
                top: window.innerHeight,
                left: 0,
                behavior: 'smooth'
              });
        } else {
            window.open('https://www.virbela.com/solutions/team-suites#teamsuite-plans', '_blank')
        }
        this.setState({isOpen: false});
    }

    render() {
        return (
            <div>
                <Navbar style={{background: 'linear-gradient(180deg, #2781FF, #5A98E2)'}} dark expand='lg'>
                    <Container>
                        <Nav navbar><NavItem style={{ padding: 0 }}><NavLink href='/'><img src='https://s3-us-west-2.amazonaws.com/opencampus.virbela.com/images/logo.png' alt='virbela-logo' height='50' /></NavLink></NavItem></Nav>
                        <NavbarToggler onClick={() => { this.setState({isOpen: !this.state.isOpen})}} />
                        <Collapse isOpen={!!this.state.isOpen} navbar>
                            <Nav className='ml-auto' navbar style={{fontSize: '17px'}}>
                                <NavItem>
                                    <NavLink href='#' onClick={() => {window.open('https://www.virbela.com', '_blank')}} style={{color: 'white'}}>Back to Virbela.com</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href='#' onClick={() => {window.location = 'https://www.virbela.com/solutions/team-suites#teamsuite-plans'}} style={{color: 'white'}}>Purchase Private Spaces</NavLink>
                                </NavItem>
                                {!auth.isAuthenticated() ? (
                                    <NavItem>
                                        <NavLink href='#' onClick={this.toggleLogin} style={{color: 'white'}}>Manage Subscriptions</NavLink>
                                    </NavItem>
                                ) : (
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret style={{color: 'white'}}>
                                            {auth.getCurrentUser().get('firstname') + ' ' + auth.getCurrentUser().get('lastname')}
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem onClick={() => {this.props.history.push('/profile/subscriptions'); this.setState({isOpen: false});}}>
                                                Profile
                                            </DropdownItem>
                                            {/* <DropdownItem onClick={() => {this.props.history.push({pathname: '/referrals', prevPage: window.location.pathname }); this.setState({isOpen: false});}}>
                                                Referrals
                                            </DropdownItem> */}
                                            <DropdownItem divider />
                                            {!auth.isSessionLogin() && <OktaLoginButton dropdown toggle={() => {this.setState({isOpen: false})}}/>}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                )}
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
            </div>
        );
    }
}

export default withRouter(NavBar);