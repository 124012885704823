import React from 'react';
import Stripe, { getSortedPlans } from './../actions/stripe';
import { Container } from 'reactstrap';
import Loading from './loading';
import Error from './error';
import ProductDetails from './productComponents/productDetails';
import Features from './productComponents/features';
import qs from 'query-string';


export default class Product extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            error: '',
            product: null,
            planData: null,
            customer: undefined,
            dCard: null,
        }
    }

    async componentDidMount() {
        this._isMounted = true;

        let id = (this.props.id || this.props.match.params.id);
        let params = qs.parse(this.props.location.search)
        let couponCode = params.coupon;
        if(couponCode){
            sessionStorage.setItem('Coupon_'+id, couponCode);
        }
        try {
            let [product, planData] = await Promise.all([Stripe.getProducts({ids: [id]}), getSortedPlans({product: id})]);
            if(!this._isMounted) return;
            this.setState({isLoading: false, product: product.data[0], planData, customer: this.props.customer, dCard: this.props.dCard});
            document.title = product.data[0].metadata.name;
        } catch(error) {
            if(!this._isMounted) return;
            this.setState({isLoading: false, error: error.message});
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!!this.myRef && !!this.myRef.current && (prevState.height !== this.myRef.current.clientHeight) && !!this.myRef.current.clientHeight) {
            this.setState({height: this.myRef.current.clientHeight});
        }
        if(prevProps.customer !== this.props.customer) {
            this.setState({customer: this.props.customer});
        }
        if(prevProps.dCard !== this.props.dCard) {
            this.setState({dCard: this.props.dCard});
        }
    }

    renderProduct = () => {
        if(this.state.isLoading) {
            return <Loading />
        } else if(!!this.state.error) {
            return <Error message={this.state.error} />
        } else if(this.state.planData.plans.length <= 0) {
            return <Error message='No plans found' />
        } else {
            return (
                <React.Fragment>
                    <ProductDetails customer={this.state.customer} dCard={this.state.dCard} product={this.state.product} planData={this.state.planData} addCardSuccess={this.props.addCardSuccess}/>
                    <Features product={this.state.product} />
                </React.Fragment>
            );
        }
    }

    render() {
        return (
            <Container style={{paddingBottom: '25px', paddingTop: '45px'}}>
                {this.renderProduct()}
            </Container>
        );
    }
}