import Notifications from './notifications';
import Parse, { Cloud } from 'parse';

const Account = Parse.Object.extend('Account');

class Room {

    async updateRoomPasscode(roomSecurity, newPasscode, idx) {
        return new Promise( async (resolve, reject) => {
            try {
                if(!roomSecurity || !newPasscode || (!idx && idx !== 0)) {
                    reject(new Error('Missing params'));
                    return;
                }
                
                let res = await Cloud.run('UpdateTSPasscodes', {
                    roomSecurityId: roomSecurity.id,
                    newPasscode, idx
                });
                Notifications.addNotification('Success', 'Successfully updated password', 'success');
                resolve(res);
            } catch(error) {
                console.error(error.message);
                reject(error);
            }
        });
    }

    createTeamRoomAccount(orgName, desc, roomSecurityArr) {
        return new Promise( async (resolve, reject) => {
            try {
                if(!orgName) {
                    throw new Error('Organization name is required');
                }
                if(!roomSecurityArr || roomSecurityArr.length < 1) {
                    throw new Error('RoomSecurity missing');
                }
                let savedArr = [], accounts = [];
                for(const roomSecurity of roomSecurityArr) {
                    let account = new Account();
                    account = await account.save({organizationName: orgName, organizationDescription: desc, owner: Parse.User.current(), type: 'teamRoom'});
                    accounts.push(account);
                    let rs = await roomSecurity.save({account: account});
                    savedArr.push(rs);
                }
                Notifications.addNotification('Success', 'Successfully updated subscription information', 'success');
                resolve({accounts, roomSecurityArr: savedArr});
            } catch(error) {
                console.error(error.message);
                reject(error);
            }
        });
    }

    updateTeamRoomAccount(orgName, desc, roomSecurityArr) {
        return new Promise( async (resolve, reject) => {
            try {
                if(!orgName) {
                    throw new Error('Organization name is required');
                }
                if(!roomSecurityArr || roomSecurityArr.length < 1) {
                    throw new Error('RoomSecurity missing');
                }
                let savedArr = [];
                for(const roomSecurity of roomSecurityArr) {
                    let account = await roomSecurity.get('account').save({organizationName: orgName, organizationDescription: desc});
                    roomSecurity.set('account', account);
                    savedArr.push(roomSecurity);
                }
                Notifications.addNotification('Success', 'Successfully updated subscription information', 'success');
                resolve(roomSecurityArr);
            } catch(error) {
                console.error(error.message);
                reject(error);
            }
        });
    }

    updateTSPermissions(roomSecurity, field, permLvl) {
        return new Promise(async (resolve, reject) => {
            try {
                if(!roomSecurity || !field || (!permLvl && permLvl !== 0)) throw new Error('Missing Parameters');
                let permissions = roomSecurity.get('permissions') || {'web': 0};
                permissions[field] = permLvl;
                let savedRs = await roomSecurity.save({permissions});
                Notifications.addNotification('Success', 'Successfully updated web board access! \n Permissions will apply when users rejoin the Team Suite.', 'success');
                resolve(savedRs);
            } catch(error) {
                Notifications.addNotification('Error', error.message, 'error');
                console.log(error.message);
                reject(error);
            }
        });
    }
}

export default new Room();