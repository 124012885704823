import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Media, Input } from 'reactstrap';
import Loading from './../loading';
import Error from './../error';
import PurchaseModal from './../purchaseModal';
import './product.css';
import { calculatePrice } from './../../actions/stripe'
import { SupportFormModalBtn } from './../supportForm';

class ProductDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            error: '',
            customer: undefined,    //when passing props for customer make sure default is undefined. undefined == not done checking if logged in, null == not logged in
            dCard: null,
            paymentMethods: null,
            product: null,
            plans: null,
            planIntervals: [],
            filteredPlans: [],
            selectedIndex: 0,
            intervalIdx: 0,
            showPurchaseModal: false,
            quantity: 1,
            data: {},
        }
    }

    componentDidMount() {
        this._isMounted = true;
        let { planData, product, customer, dCard, location } = this.props;
        let intervalIdx = (!!location.data && !!location.data.intervalIdx)? location.data.intervalIdx : 0;
        let filteredPlans = planData.plans.filter(plan => {
            let { planIntervals } = planData;
            let { interval, interval_count } = plan.recurring || plan;
            interval_count = interval_count || 0;
            interval = interval || plan.type;
            return ((interval === planIntervals[intervalIdx][1]) && (`${interval_count}` === planIntervals[intervalIdx][0]))
        });
        let selectedIndex = ((!!location.data && !!location.data.selectedIndex)? location.data.selectedIndex : 0);
        let plan = filteredPlans[selectedIndex];
        let quantity = ((!!location.data && !!location.data.quantity)? location.data.quantity : (!!plan && (plan.metadata.type === 'support'))? 1 : (['graduated', 'volume'].includes(plan.tiers_mode))? 150 : 1);
        this.setState({
            isLoading: false, 
            product: product, 
            plans: planData.plans, 
            planIntervals: planData.planIntervals, 
            filteredPlans, 
            selectedIndex,
            intervalIdx, 
            customer: customer, 
            dCard: dCard, 
            paymentMethods: !!customer && customer.sources, 
            showPurchaseModal: (!!location.data && !!location.data.purchaseModal),
            quantity,
        });
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.customer !== this.props.customer) {
            this.setState({customer: this.props.customer, paymentMethods: !!this.props.customer && this.props.customer.sources});
        }
        if(prevProps.dCard !== this.props.dCard) {
            this.setState({dCard: this.props.dCard});
        }
        if(prevProps.product !== this.props.product) {
            this.setState({product: this.props.product});
        }
        if(prevProps.plans !== this.props.plans) {
            this.setState({plans: this.props.plans});
        }
    }

    planSelectChange = (e) => {
        this.setState({selectedIndex: e.target.value});
    }

    intervalSelectChange = (e) => {
        let intervalIdx = e.target.value;
        let { plans, planIntervals, selectedIndex: selectedIndexStr } = this.state;
        let filteredPlans =  plans.filter(plan => {
            let { interval, interval_count } = plan.recurring || plan;
            interval_count = interval_count || 0;
            interval = interval || plan.type;
            return ((interval === planIntervals[intervalIdx][1]) && (`${interval_count}` === planIntervals[intervalIdx][0]))
        });
        let selectedIndex = (filteredPlans.length > parseInt(selectedIndexStr))? parseInt(selectedIndexStr) : 0;
        this.setState({intervalIdx, filteredPlans, selectedIndex});
    }

    quantitySelectChange = (e) => {
        this.setState({quantity: parseInt(e.target.value)});
    }

    togglePurchaseModal = () => {
        this.setState({showPurchaseModal: !this.state.showPurchaseModal});
    }

    addcardSuccess = (card) => {
        this.props.addCardSuccess(card);
        this.togglePurchaseModal();
    }

    getIntervalName = (interval, intervalCount) => {
        let intervalName = `${intervalCount} ${interval}(s)`;
        switch(interval) {
            case 'day':
                if(intervalCount === '1') intervalName = 'Daily';
                break;
            case 'month':
                if(intervalCount === '1') intervalName = 'Monthly';
                else if(intervalCount === '3') intervalName = 'Quarterly';
                break;
            case 'year':
                if(intervalCount === '1') intervalName = 'Yearly';
                break;
            default:
                intervalName = `${intervalCount} ${interval}(s)`;
        }
        return `${intervalName} Payment Plan`;
    }

    formSuccess = (data) => {
        this.togglePurchaseModal();
        this.setState({data});
    }

    renderProductDetails = () => {
        if(this.state.isLoading) {
            return <Loading />
        } else if(!!this.state.error) {
            return <Error message={this.state.error} />
        } else {
            let {product, selectedIndex, planIntervals, intervalIdx, filteredPlans, quantity, data} = this.state;
            let plan = filteredPlans[selectedIndex];
            let { interval, interval_count, tiers_mode } = plan.recurring || plan;
            let isSingleUse = !!plan && (!!plan.metadata.event_size || (plan.metadata.nonRecurring === 'true'));
            let showSupportQuantity = !!plan && (plan.metadata.type === 'support');
            return (
                <React.Fragment>
                    <Row>
                        <Col xs='12' md='6' className='product-image' >
                            <div className='hover' onClick={() => {window.open(product.metadata.image, '_blank')}}>
                                <Media className='image' object src={product.metadata.image} alt={product.metadata.name + ' image'} width='100%' height='auto'/>        
                            </div>
                        </Col>
                        <Col xs='12' md='6' className='left'>
                            <div>
                            <div>
                                <h1>{product.metadata.name}</h1>
                            </div>
                            {(planIntervals.length > 1) && <div style={{margin: '4px 0px 2px 0px'}}>
                                <Input type='select' name='planInterval' id='planInterval' value={intervalIdx} onChange={this.intervalSelectChange}>
                                    {planIntervals.map(([intervalCount, interval],idx) => {
                                        return <option key={`planInterval_${idx}`} value={idx}>{this.getIntervalName(interval, intervalCount)}</option>
                                    })}
                                </Input>
                            </div>}
                            {(filteredPlans.length > 1) && <div>
                                <Input type='select' name='planType' id='planType' value={selectedIndex} onChange={this.planSelectChange}>
                                    {filteredPlans.map( (plan,idx) => {
                                        return <option key={`planType_${plan.id}`} value={idx}>{plan.metadata.name}</option>
                                    })}
                                </Input>
                            </div>}
                            {((tiers_mode === 'volume') || (tiers_mode === 'graduated')) && <div style={{margin: '4px 0px 2px 0px'}}>
                                <Input type='select' name='quantityTierSelect' id='quantityTierSelect' onChange={this.quantitySelectChange} value={quantity}>
                                    <option value={150}>150 Users</option>
                                    <option value={300}>300 Users</option>
                                    <option value={750}>750 Users</option>
                                    <option value={1500}>1500 Users</option>
                                </Input>
                            </div>}
                            {(showSupportQuantity) && <div style={{margin: '4px 0px 2px 0px'}}>
                                <Input type='select' name='supportQuantity' id='supportQuantitySelect' onChange={this.quantitySelectChange} value={quantity}>
                                    <option value={1}>1 hour of event or private campus support / training</option>
                                    <option value={2}>2 hours of event or private campus support / training</option>
                                    <option value={4}>4 hours of event or private campus support / training</option>
                                    <option value={8}>8 hours of event or private campus support / training</option>
                                    <option value={12}>12 hours of event or private campus support / training</option>
                                    <option value={16}>16 hours of event or private campus support / training</option>
                                </Input>
                            </div>}
                            <hr />
                            {(!!product.metadata.warning) && <Error color={((product.metadata.name || '').includes('eXp'))? 'danger' : 'warning'} assist={false}><font size={2}>{product.metadata.warning}</font></Error>}
                            <div className='price'>Price: <font size={3} color='red'>{'$' + calculatePrice(plan, null, quantity).toLocaleString()}<font size='2'> USD </font>{((isSingleUse)? '' : ('/' + ((interval_count < 2)? '' : ` ${interval_count}`) + ` ${interval}(s)`))}</font><sup><font size={2}><b>{' ' + (plan.metadata.discount || '')}</b></font></sup></div>
                            {(!!product.metadata.tagline) && <div><b>{product.metadata.tagline}</b></div>}
                            <div>{product.metadata.description}</div>
                            {(!!product.metadata.details) && (
                                <ul style={{whiteSpace: 'pre-wrap'}}>
                                    {product.metadata.details.split(';').map( (detail,idx) => {
                                        return <li key={'details_' + idx} className='details'><font size={2}>{detail.replace(/{{size}}/, plan.metadata.size).replace(/{{layout}}/, plan.metadata.layout)}</font></li>
                                    })}
                                </ul>)}
                            {(!!this.state.customer)? (
                                (showSupportQuantity)? <SupportFormModalBtn className='bttn block primary' onSubmit={this.formSuccess} /> :
                                <div className='right'><button className='bttn block primary' onClick={this.togglePurchaseModal}>Get Started</button></div>
                            ) : (
                                <div className='right'><button className='bttn block secondary' disabled={this.state.customer === undefined} onClick={() => {this.props.history.push({pathname: '/login', prevPage: window.location.pathname, data: {purchaseModal: !showSupportQuantity, selectedIndex, intervalIdx, quantity: quantity}})}}>Login to Purchase</button></div>
                            )}
                            </div>
                        </Col>
                    </Row>
                    {(this.state.showPurchaseModal) && (<PurchaseModal plan={plan} toggle={this.togglePurchaseModal} dCard={this.state.dCard} paymentMethods={this.state.paymentMethods} addCardSuccess={this.addcardSuccess} redirectOnSuccess={true} quantity={quantity} data={data}/>)}
                </React.Fragment>
            );
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className='product'>{this.renderProductDetails()}</div><hr/>
            </React.Fragment>
        )
    }
}

export default withRouter(ProductDetails);