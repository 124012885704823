import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import parseUser from './../actions/parseUser';
import verifyLetter from '../images/verify-letter.svg';
import './register.css';

export default class Verify extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isResending: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    resendEmail = () => {
        if (this.state.isResending) return;
        this.setState({isResending: true});
        parseUser.resendVerificationEmail((error) => {
            if(this._isMounted) {
                this.setState({isResending: false});
            }
        });
    }

    render() {
        return (
            <section className='registerBackground' style={{position: 'relative'}}>
                <Container className='left minHeightNoHeader spacing flex-center' style={{ padding: '15px 0' }}>
                    <Row className='info-box'>
                        <Col xs={12} md={{size: 6 }} className='left-col'>
                            <div className='left-col-center'>
                                <div style={{margin: '10px 0'}} className='center'><img src='https://s3-us-west-2.amazonaws.com/opencampus.virbela.com/images/logo.png' alt='virbela-logo' height='80' /></div>
                                <div className='center'><p> Designed to enable companies to grow and scale in the cloud. Try now for free!</p></div>
                            </div>
                        </Col>
                        <Col xs={12} md={{ size: 6, offset: 0 }}>
                            <section className='customForm verifyForm center'>
                                <h3>Verify Email</h3>
                                <img id='verify-image' alt='Verify email' src={verifyLetter} />
                                <p>Check your email to receive the verification link.</p>
                                <hr />
                                <div className='download-buttons'>
                                    <button id='mac-download' className='bttn danger' onClick={() => window.open('https://assets.virbela.com/installer/Virbela.dmg', '_blank')}>Download Mac</button>
                                    <button id='pc-download' className='bttn danger' onClick={() => window.open('https://assets.virbela.com/installer/VirbelaSetup.exe', '_blank')}>Download PC</button>
                                </div>
                                <p>Note that without verification you won't have access to Virbela.</p>
                                <div className='link' onClick={this.resendEmail}>{this.state.isResending ? 'Sending verification email...' : 'Resend verification email?'}</div>
                            </section>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}