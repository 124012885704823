import React from 'react';
import { Alert } from 'reactstrap';

export default class Error extends React.Component {
    render() {
        let showAssist = (this.props.assist === false)? false : true;
        return (
            <Alert className='center' color={this.props.color || 'danger'} >
                <font size='4'>{(!!this.props.children)? (this.props.children) : (this.props.message || 'error')}</font>
                {showAssist && (
                    <React.Fragment>
                        <hr />
                        <font size='2'>For assistance, please submit an inquiry to <a href='https://support.virbela.com/s/' target='_blank' rel='noopener noreferrer'>Virbela support.</a></font>
                    </React.Fragment>
                )}
            </Alert>
        );
    }
}