import React from 'react';
import { injectStripe, CardElement } from 'react-stripe-elements';
import { Row, Col, Alert, Input, Form, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import stripe from './../actions/stripe';
import Loading from './loading';
import Countries from './../constants/Countries';

const yup = require('yup');

const schemaUSA = yup.object().shape({
  zip: yup.string().trim().required('Zip/Postal Code required'),
  state: yup.string().trim().required('State required'),
  city: yup.string().trim().required('City required'),
  address2: yup.string(),
  address1: yup.string().trim().required('Address required'),
  lastname: yup.string().trim().required('Last name required'),
  firstname: yup.string().trim().required('First name required'),
  country: yup.string().trim().required('Country required')
});

const schemaWorld = yup.object().shape({
  zip: yup.string().trim(),
  region: yup.string().trim(),
  city: yup.string().trim().required('City required'),
  address2: yup.string().trim(),
  address1: yup.string().trim().required('Address required'),
  lastname: yup.string().trim().required('Last name required'),
  firstname: yup.string().trim().required('First name required'),
  country: yup.string().trim().required('Country required')
});

const states = ['AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'];

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        error: '',
        isLoading: false,
        addingCard: false,
        isUSA: true,
        showZip: true,
        zip: '',

        firstname: '',
        lastname: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        region: '',
        country: '',
    }
  }

  componentDidMount() {
    this._isMounted = true;
    if(this.props.formData['country'] !== 'US' && this.props.formData['country'] !== undefined) {
      this.setState({isUSA: false});
    }
    this.setState({...this.props.formData, country: this.props.formData['country'] || 'US'});
  }

  componentWillUnmount() {
    this._isMounted = false;
    let { firstname, lastname, address1, address2, city, state, region, country } = this.state;
    this.props.saveForm({ firstname, lastname, address1, address2, city, state, region, country });
  }

  submit = (e) => {
    if(e.key === 'Enter') {
      e.preventDefault();
      if(!this.state.addingCard) {
        this.handleSubmit();
      }
    }
  }

  validateSchema = (toValidate, isUSA) => {
    return new Promise(async (resolve, reject) => {
      try {
        let res = null;
        if(isUSA) {
          res = await schemaUSA.validate(toValidate);
        } else {
          res = await schemaWorld.validate(toValidate);
        }
        resolve(res);
      } catch(error) {
        reject(error);
      }
    });
  }

  handleSubmit = async () => {
    try {
      this.setState({addingCard: true});
      let { firstname, lastname, address1, address2, city, state, region, country, zip, isUSA } = this.state;
      let toValidate = (isUSA)? { firstname, lastname, address1, address2, city, state, zip, country } : { firstname, lastname, address1, address2, city, region, zip, country };
      await this.validateSchema(toValidate, isUSA);
      if(!this._isMounted) return;
      
      let {token, error} = await this.props.stripe.createToken({ name: (firstname + ' ' + lastname), address_line1: address1, address_line2: address2, address_city: city, address_state: (isUSA)? state : region, address_zip: zip, address_country: country });
      if(!this._isMounted) return;
      if(!!error) throw new Error(error.message);
      if(!token) throw new Error('Cannot generate token for card');
      this.props.saveForm({ firstname, lastname, address1, address2, city, state, region, country });
      this.setState({ error: '', isLoading: true});
      let card = await stripe.addPaymentMethod(token.id);

      if(!this._isMounted) return;
      this.setState({isLoading: false, error: '', addingCard: false});
      this.toggle();
      this.props.saveForm({});
      this.props.addCardSuccess({card});
    } catch(error) {
      this.setState({isLoading: false, error: error.message, addingCard: false });
    }
  }

  countrySelect = (event) => {
    if(event.target.value !== 'US') {
      this.setState({isUSA: false, country: event.target.value});
    } else {
      this.setState({isUSA: true, country: event.target.value});
    }
  }

  handleCardChange = (e) => {
    this.setState({zip: e.value['postalCode']});
    if(!e.value['postalCode'] || e.empty) {
      this.setState({showZip: true});
    } else {
      this.setState({showZip: false});
    }
  }

  render() {
    if(this.state.isLoading) {
      return (
        <div>
          <Modal isOpen={true} >
            <ModalHeader>Add Card</ModalHeader>
            <ModalBody>
              <div className='center'>
                <Loading />
              </div>
              <br />
            </ModalBody>
          </Modal>
        </div>
      );
    } else {
      return (
        <div>
          <Modal isOpen={true} toggle={this.toggle} className={this.props.className}>
            <ModalHeader toggle={this.toggle}>Add Card</ModalHeader>
            <ModalBody>
              {(!!this.state.error) && (
                  <Alert color='danger'>
                      {this.state.error}
                  </Alert> 
              )}
              <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='country'>Country: *</Label>
                        <Input type='select' name='select_country' id='country' value={this.state.country} onKeyDown={this.submit} onChange={this.countrySelect}>
                          <option hidden value={''}>Select country..</option>
                          {Countries.map((countryObj) => {
                            return (
                              <option key={countryObj['name']} value={countryObj['code']}>{countryObj['name']}</option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {this.fieldGroup('firstname', 'First Name: *', 'Enter first name..')}
                    </Col>
                    <Col>
                      {this.fieldGroup('lastname', 'Last Name: *', 'Enter last name..')}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label for='cardElement'>Card: *</Label>
                      <CardElement id='cardElement' style={{base: {fontSize: '18px'}}} onChange={this.handleCardChange}/>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      {this.fieldGroup('address1', 'Address Line 1: *', 'Enter address..')}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {this.fieldGroup('address2', 'Address Line 2:', 'Enter apt # etc..')}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {this.fieldGroup('city', 'City: *', 'Enter city..')}
                    </Col>
                    <Col style={{paddingLeft: 0}}>
                      {(this.state.isUSA)? (
                        <FormGroup>
                          <Label for='state'>State: *</Label>
                          <Input type='select' name='select' id='state' value={this.state.state} onChange={this.updateInput} onKeyDown={this.submit}>
                            <option hidden value={''}>Select state..</option>
                            {states.map((state) => {
                              return (
                                <option key={state} value={state}>{state}</option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      ) : (
                        <FormGroup>
                          <Label for='region'>Province/Region:</Label>
                          <Input type='text' name='select' id='region' value={this.state.region} placeholder={'Enter if needed..'} onChange={this.updateInput} onKeyDown={this.submit}/>
                        </FormGroup>
                      )}
                    </Col>
                    {(this.state.showZip) && (
                      <Col style={{paddingLeft: 0}}>
                        {this.fieldGroup('zip', 'Zip/Postal Code:', 'Enter zip or postal code..')}
                      </Col>
                    )}
                  </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                <button className='bttn' onClick={this.toggle} type={'submit'}>Cancel</button>
                {(this.state.addingCard)? <Spinner color='primary'/> : <button className='bttn primary' disabled={this.state.addingCard} onClick={this.handleSubmit}>Add</button>}
              </ModalFooter>
          </Modal>
        </div>
      )
    }
  }

  toggle = () => {
    this.props.toggleCardModal();
  }

  fieldGroup = ( id, label, placeholder ) => {
    return (
        <FormGroup>
            <Label for={id}>{label}</Label>
            <Input id={id} placeholder={placeholder} value={this.state[id]} onChange={this.updateInput} onKeyDown={(e) => this.submit(e)}/>
        </FormGroup>
    );
  }

  updateInput = (e) => {
    this.setState({[e.target.id]: e.target.value});
  }
}

export default injectStripe(CheckoutForm);

CheckoutForm.defaultProps = {
  saveForm: (form) => {
      return;
  },
  formData: {}
}